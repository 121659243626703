import { takeEvery } from 'redux-saga/effects';
import { put } from 'typed-redux-saga';
import { clearSearch, search } from '../actions';

function* handler(action: ReturnType<typeof clearSearch>) {
  if (action.payload.executeSearch) {
    yield put(search({ searchParams: null }));
  }
}

export function* clearSearchEffects() {
  yield takeEvery(clearSearch, handler);
}
