import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { IconAdd } from '../icons/icon-add';

import { COMMON_DROP_ZONE_NAMESPACE, DRAG_FILES_KEY } from './i18n';

export interface BFDropZoneProps {
  className?: string;
  onDrop: (acceptedFiles: File[]) => void;
  style?: HTMLAttributes<any>['style'];
}

export function BFDropZone({ className, onDrop, style }: BFDropZoneProps) {
  const { t } = useTranslation(COMMON_DROP_ZONE_NAMESPACE);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDrop });

  return (
    <div
      className={classNames(
        'border rounded-md w-full flex flex-col items-center justify-center p-sm text-center',
        { 'border-dashed': !isDragActive },
        { 'border-bf-primary border-solid': isDragActive },
        className,
      )}
      {...getRootProps()}
      style={{ minHeight: 100, ...style }}
    >
      <input {...getInputProps()} />
      <IconAdd />
      <span className="mt-xs">{t(DRAG_FILES_KEY)}</span>
    </div>
  );
}
