import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import React from 'react';

export const SPINNER_COMPONENT_KEY = 'SPINNER';

function DefaultSpinner() {
  return null;
}

export function BFSpinner() {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const SpinnerComponent = componentResolver.getComponent(SPINNER_COMPONENT_KEY) || DefaultSpinner;
  return <SpinnerComponent />;
}
