import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { Attachment, AttachmentVariant } from '@integration-frontends/integration/core/model';
import { createAction } from '@reduxjs/toolkit';

export const initiatePlaceAttachment = createAction(
  'PLACE_ATTACHMENT_INIT',
  withPayloadType<{ attachment: Attachment | AttachmentVariant }>(),
);

export const placeAttachment = createAction(
  'PLACE_ATTACHMENT',
  withPayloadType<{ attachment: Attachment | AttachmentVariant }>(),
);
