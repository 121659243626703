import { ContainerTag } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const containerTagAdapter = createEntityAdapter<ContainerTag>();

export const containerTagSlice = createSlice({
  initialState: containerTagAdapter.getInitialState(),
  name: 'containerTags',
  reducers: {
    containerTagsReceived: containerTagAdapter.upsertMany,
  },
});
