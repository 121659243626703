import { createSelector } from '@reduxjs/toolkit';
import { NotificationsRootState, NotificationsState } from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';

const selectNotificationsState: StateSelector<NotificationsState> = (state: NotificationsRootState) => state.notifications;
export const selectNewNotifcations = createSelector(selectNotificationsState, (state) => {
  return state.notifications.filter((notification) => {
    return notification.closed === false;
  })
});
export const selectAllNotificaitons = createSelector(selectNotificationsState, (state) => state.notifications);
