import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  getResponseListDataOrDefault,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Container,
  ISearchFilterRepo,
  ResourceType,
  SearchFilter,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { GET_API_KEY_TOKEN, IGetApiKey, mapSearchFilter } from './model';

@injectable()
export class SearchFilterRepo implements ISearchFilterRepo {
  private brandfolderApi: BrandfolderApi;
  private getApiKey: IGetApiKey;

  constructor() {
    this.brandfolderApi = DI_CONTAINER.get(BRANDFOLDER_API_TOKEN);
    this.getApiKey = DI_CONTAINER.get(GET_API_KEY_TOKEN);
  }

  listContainerSearchFilters = async (container: Container): Promise<SearchFilter[]> => {
    if (!container) return [];

    const results = await (container.type === ResourceType.BRANDFOLDER
      ? this.brandfolderApi.listBrandfolderSearchFilters(await this.getApiKey(), container.id)
      : this.brandfolderApi.listCollectionSearchFilters(await this.getApiKey(), container.id));

    return getResponseListDataOrDefault(results).map(mapSearchFilter(container.id));
  };
}
