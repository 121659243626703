import {
  Container,
  ISearchFilterRepo,
  SearchFilter,
  searchFiltersMockData,
} from '@integration-frontends/integration/core/model';

export class SearchFilterRepoMock implements ISearchFilterRepo {
  listContainerSearchFilters(container: Container): Promise<SearchFilter[]> {
    return Promise.resolve(
      searchFiltersMockData.map((filter) => ({ ...filter, containerId: container.id })),
    );
  }
}
