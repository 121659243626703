import {
  AttachmentFieldParameter,
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  FieldParameter,
  getResponseDataOrDefault,
  getResponseListDataOrDefault,
  Relationship,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import { IAttachmentRepo } from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { map } from 'ramda';
import { AttachmentWithDto, GET_API_KEY_TOKEN, IGetApiKey, mapAttachmentDto } from './model';

@injectable()
export class AttachmentRepo implements IAttachmentRepo {
  private brandfolderApi: BrandfolderApi;
  private getApiKey: IGetApiKey;

  constructor(private extraParameters: AttachmentFieldParameter[] = []) {
    this.brandfolderApi = DI_CONTAINER.get(BRANDFOLDER_API_TOKEN);
    this.getApiKey = DI_CONTAINER.get(GET_API_KEY_TOKEN);
  }

  getAttachment = async (attachmentId: string): Promise<AttachmentWithDto> => {
    return this.brandfolderApi
      .fetchAttachment(await this.getApiKey(), attachmentId, {
        include: [Relationship.ASSET],
        fields: this.getFieldParameters(),
      })
      .then(getResponseDataOrDefault)
      .then(mapAttachmentDto);
  };

  listAssetAttachments = async (assetId: string): Promise<AttachmentWithDto[]> => {
    return this.brandfolderApi
      .listAssetAttachments(await this.getApiKey(), assetId, {
        include: [Relationship.ASSET],
        fields: this.getFieldParameters(),
      })
      .then(getResponseListDataOrDefault)
      .then(map(mapAttachmentDto));
  };

  private getFieldParameters(): AttachmentFieldParameter[] {
    return [
      FieldParameter.CdnUrl,
      FieldParameter.CreatedAt,
      FieldParameter.UpdatedAt,
      ...this.extraParameters,
    ];
  }
}
