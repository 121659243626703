import { equals, flatten, intersection } from 'ramda';
import {
  ALL_FILE_TYPES,
  FILE_TYPE_EXTENSIONS,
  FILE_TYPE_MIME_TYPES,
  FileType,
  getFileTypeFromMimeType,
} from './file';
import {
  ALL_IMAGE_TYPES,
  getImageTypeFromMimeType,
  IMAGE_TYPE_EXTENSIONS,
  IMAGE_TYPE_MIME_TYPES,
  ImageType,
} from './image';
import {
  ALL_VIDEO_TYPES,
  getVideoTypeFromMimeType,
  VIDEO_TYPE_EXTENSIONS,
  VIDEO_TYPE_MIME_TYPES,
  VideoType,
} from './video';

export const SUPPORTED_MEDIA_TYPES_TOKEN = 'SUPPORTED_MEDIA_TYPES';
export const OPENABLE_MEDIA_TYPES_TOKEN = 'OPENABLE_MEDIA_TYPES';
export type Extension = string;
export type MediaType = FileType | ImageType | VideoType;
export const ALL_MEDIA_TYPES: MediaType[] = [
  ...ALL_FILE_TYPES,
  ...ALL_IMAGE_TYPES,
  ...ALL_VIDEO_TYPES,
];
export const ALL_MEDIA_EXTENSIONS = {
  ...FILE_TYPE_EXTENSIONS,
  ...IMAGE_TYPE_EXTENSIONS,
  ...VIDEO_TYPE_EXTENSIONS,
};

export function getMediaTypesExtensions(mediaTypes: MediaType[]): Extension[] {
  return flatten(
    mediaTypes.map(
      (mediaType) =>
        FILE_TYPE_EXTENSIONS[mediaType] ||
        IMAGE_TYPE_EXTENSIONS[mediaType] ||
        VIDEO_TYPE_EXTENSIONS[mediaType],
    ),
  );
}

export function getExtensionMediaType(extension: Extension): MediaType {
  return Object.entries(ALL_MEDIA_EXTENSIONS).find(([_, extensions]) =>
    extensions.includes(extension.toLocaleLowerCase()),
  )?.[0] as MediaType;
}

export function getMediaTypesMimeTypes(mediaTypes: MediaType[]): Extension[] {
  return flatten(
    mediaTypes.map(
      (mediaType) =>
        FILE_TYPE_MIME_TYPES[mediaType] ||
        IMAGE_TYPE_MIME_TYPES[mediaType] ||
        VIDEO_TYPE_MIME_TYPES[mediaType],
    ),
  );
}

export function getImageMediaTypes(mediaTypes: MediaType[]): ImageType[] {
  return intersection(mediaTypes, ALL_IMAGE_TYPES) as ImageType[];
}

export function isImageType(mediaType: MediaType): boolean {
  return ALL_IMAGE_TYPES.includes(mediaType as ImageType);
}

export function isVideoType(mediaType: MediaType): boolean {
  return ALL_VIDEO_TYPES.includes(mediaType as VideoType);
}

export function getVideoMediaTypes(mediaTypes: MediaType[]): VideoType[] {
  return intersection(mediaTypes, ALL_VIDEO_TYPES) as VideoType[];
}

export function getMimeTypeMediaType(mimeType: string): MediaType {
  return (
    getFileTypeFromMimeType(mimeType) ||
    getImageTypeFromMimeType(mimeType) ||
    getVideoTypeFromMimeType(mimeType)
  );
}

export function isImage(mimeType: string): boolean {
  return !!getMediaTypesMimeTypes(ALL_IMAGE_TYPES).find(equals(mimeType));
}
