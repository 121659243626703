import React, { HTMLAttributes, ReactElement } from 'react';
import './thumbnail.scss';
import classNames from 'classnames';

export interface ThumbnailProps {
  children: ReactElement | ReactElement[];
  className?: string;
  selected?: boolean;
}

export function Thumbnail({
  children,
  className,
  selected,
  ...props
}: ThumbnailProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames('bf-thumbnail', { selected: selected }, className)} {...props}>
      <div className="thumbnail-content w-full h-full flex justify-center items-center bg-pattern">
        {children}
      </div>
    </div>
  );
}
