import {
  BFButton,
  ButtonSize,
  ButtonType,
  IconArrowLeft,
  IconArrowLeftWhite,
  IconArrowRight,
  IconArrowRightWhite,
  IconSize,
  Loader,
} from '@integration-frontends/common/ui';
import {
  AssetsListPagination,
  getPageCeil,
  getPageCount,
  getPageFloor,
  hasNextPage,
  hasPreviousPage,
} from '@integration-frontends/integration/core/application/common/assets-list/assets-list-state/assets-list-pagination';
import { Asset } from '@integration-frontends/integration/core/model';
import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AttachmentSelectorFeature } from '../../../features';
import { NEXT_KEY, OF_KEY, PREVIOUS_KEY } from '../../i18n';
import { AssetThumbnails } from '../asset-thumbnails';
import { ThumbnailList } from '../thumbnail-list/thumbnail-list';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import { useFeatures } from '../use-features';
import { FlexSpacer } from '@integration-fontends/integration/ui/attachment-selector/common/layout/flex-spacer';
import classNames from 'classnames';
import './assets-list.scss';

export interface AssetListProps {
  assets: Asset[];
  containerId: string;
  className: string;
  title?: string;
  pagination: AssetsListPagination;
  showAttachments: boolean;
  onNextPageClick: () => void;
  onPreviousPageClick: () => void;
}

export function AssetsList({
  assets,
  containerId,
  className,
  title,
  pagination,
  showAttachments,
  onNextPageClick,
  onPreviousPageClick,
}: AssetListProps & HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const [scrollToTop, setScrollToTop] = useState(false);
  const ref = useRef(null);
  const { hasFeature } = useFeatures();

  useEffect(() => {
    scrollToTop && ref.current?.scrollIntoView() && setScrollToTop(false);
  }, [assets]);

  function nextPageClicked() {
    setScrollToTop(true);
    onNextPageClick();
  }

  function previousPageClicked() {
    setScrollToTop(true);
    onPreviousPageClick();
  }

  const currentPageDisplayComponent = (
    <span className="font-medium" data-testid="current-page-display">
      {getPageFloor(pagination)}-{getPageCeil(pagination)} {t(OF_KEY)}{' '}
      {pagination.totalCount.toLocaleString()}
    </span>
  );

  return assets.length ? (
    <div
      data-testid="section-assets-list"
      className={classNames('assets-list', className)}
      ref={ref}
      data-cy={`section-${title}`}
    >
      <div className="assets-list-title flex justify-between">
        <h3 className="text-primary">{title}</h3>
        {currentPageDisplayComponent}
      </div>

      {pagination.loading ? (
        <Loader />
      ) : (
        <ThumbnailList responsive={hasFeature(AttachmentSelectorFeature.Responsive)}>
          {assets.map((asset) => (
            <AssetThumbnails
              key={asset.id}
              asset={asset}
              containerId={containerId}
              responsive={hasFeature(AttachmentSelectorFeature.Responsive)}
              showAttachments={showAttachments}
            />
          ))}
        </ThumbnailList>
      )}

      {getPageCount(pagination) > 1 && (
        <div className="pagination-controls flex justify-between items-center">
          <BFButton
            data-testid={'previous-page'}
            disabled={!hasPreviousPage(pagination)}
            className="flex gap-xs items-center justify-center"
            buttonType={!hasPreviousPage(pagination) ? ButtonType.Disabled : ButtonType.Primary}
            size={ButtonSize.Small}
            onClick={previousPageClicked}
          >
            <FlexSpacer size={'xs'} className="items-center">
              {!hasPreviousPage(pagination) ? (
                <IconArrowLeft iconSize={IconSize.Small} />
              ) : (
                <IconArrowLeftWhite iconSize={IconSize.Small} />
              )}
              <span aria-label={`${title} ${t(PREVIOUS_KEY)} page`}>{t(PREVIOUS_KEY)}</span>
            </FlexSpacer>
          </BFButton>

          {currentPageDisplayComponent}

          <BFButton
            data-testid="next-page"
            disabled={!hasNextPage(pagination)}
            className="flex gap-xs items-center justify-center"
            buttonType={!hasNextPage(pagination) ? ButtonType.Disabled : ButtonType.Primary}
            size={ButtonSize.Small}
            onClick={nextPageClicked}
          >
            <FlexSpacer size={'xs'} className="items-center">
              <span aria-label={`${title} ${t(NEXT_KEY)} page`}>{t(NEXT_KEY)}</span>
              {!hasNextPage(pagination) ? (
                <IconArrowRight iconSize={IconSize.Small} />
              ) : (
                <IconArrowRightWhite iconSize={IconSize.Small} />
              )}
            </FlexSpacer>
          </BFButton>
        </div>
      )}
    </div>
  ) : null;
}
