import {
  SectionContent,
  SectionHeader,
} from '@integration-fontends/integration/ui/attachment-selector/components/advanced-filters/common';
import {
  CUSTOM_FIELDS_KEY,
  KEY_KEY,
  SELECT_KEY_KEY,
  SELECT_VALUE_KEY,
  VALUE_KEY,
} from '@integration-fontends/integration/ui/attachment-selector/i18n';
import { BFLabel, BFSelect, IconClose } from '@integration-frontends/common/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import {
  ContainerCustomField,
  CustomFieldFilter,
} from '@integration-frontends/integration/core/model';

export interface CustomFieldsFiltersComponentProps {
  customFieldFilters: CustomFieldFilter[];
  customFields: ContainerCustomField[];
  onFilterRemove: (id: string) => void;
  onKeySelect: (fieldId: string) => void;
  onValueSelect: (fieldId: string) => void;
  selectedFieldId: string;
}

export function CustomFieldsFiltersComponent({
  customFieldFilters,
  customFields,
  onFilterRemove,
  onKeySelect,
  onValueSelect,
  selectedFieldId,
}: CustomFieldsFiltersComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <div>
      <SectionHeader>
        <span>{t(CUSTOM_FIELDS_KEY)}</span>
      </SectionHeader>

      <SectionContent className="pb-lg">
        {customFieldFilters.length > 0 && (
          <div className="mb-md">
            {customFieldFilters.map((filter) => (
              <div key={filter.customField.id} className="flex justify-between mb-sm">
                <span>{filter.customField.name}</span>
                <span>{filter.value}</span>
                <IconClose
                  className="cursor-pointer"
                  onClick={() => onFilterRemove(filter.customField.id)}
                />
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-col mb-sm">
          <BFLabel>{t(KEY_KEY)}</BFLabel>
          <BFSelect
            required
            placeholder={t(SELECT_KEY_KEY)}
            options={customFields.map((field) => ({ label: field.name, value: field.id }))}
            value={selectedFieldId}
            onOptionChange={(option) => onKeySelect(option?.value as string)}
            className="text-base"
          />
        </div>

        {selectedFieldId && (
          <div className="flex flex-col">
            <BFLabel>{t(VALUE_KEY)}</BFLabel>
            <BFSelect
              placeholder={t(SELECT_VALUE_KEY)}
              options={customFields
                .find((field) => field.id === selectedFieldId)
                .allowedValues.map((value) => ({ label: value, value }))}
              onOptionChange={(option) => onValueSelect(option?.value as string)}
              className="text-base"
            />
          </div>
        )}
      </SectionContent>
    </div>
  );
}
