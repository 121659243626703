import { filterBy } from '@integration-frontends/common/utils/collection';
import { ResourceBase, ResourceType } from './resource-base';

export interface Brandfolder extends ResourceBase {
  type: ResourceType.BRANDFOLDER;
  organizationId: string;
  assetCount?: number;
  name: string;
  hasAccess: boolean;
}

export interface BrandfolderCustomField extends ResourceBase {
  type: ResourceType.CUSTOM_FIELD;
  brandfolderId: string;
  allowedValues: string[];
  prioritized: boolean;
  searchable: boolean;
}

export interface BrandfolderTag extends ResourceBase {
  type: ResourceType.TAG;
  brandfolderId: string;
  count: number;
  searchable: boolean;
}

export interface BrandfolderFileTypeAggregates {
  brandfolderId: string;
  name: string;
  count: number;
}

export const filterByOrganization = filterBy('organizationId');
export const BRANDFOLDER_REPO_TOKEN = Symbol.for('BRANDFOLDER_REPO');

export interface IBrandfolderRepo {
  getBrandfolder: (
    brandfolderId: string,
  ) => Promise<Brandfolder>;
  listBrandfolders: () => Promise<Brandfolder[]>;
  getBrandfolderCustomFields: (brandfolderId: string) => Promise<BrandfolderCustomField[]>;
  getBrandfolderTags: (brandfolderId: string) => Promise<BrandfolderTag[]>;
  getBrandfolderFileTypeAggregates: (
    brandfolderId: string,
  ) => Promise<BrandfolderFileTypeAggregates[]>;
}
