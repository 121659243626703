import classNames from 'classnames';
import { noop } from 'lodash';
import React, {DetailedHTMLProps, ImgHTMLAttributes, useCallback, useEffect, useState} from 'react';
import './styles/image.scss';

export interface BFImageProps {
  fallbackUrl?: string;
  responsive?: boolean;
}

export function BFImage({
  fallbackUrl,
  src,
  onError = noop,
  className,
  responsive,
  ...props
}: BFImageProps & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
  const [errored, setErrored] = useState(false);
  const [localSrc, setLocalSrc] = useState(src);

  useEffect(() => {
    setErrored(false);
    setLocalSrc(src);
  }, [src]);

  const localOnError = useCallback((e) => {
    if (!errored) {
      setErrored(true);
      setLocalSrc(fallbackUrl);
    }

    onError(e);
  }, []);

  return responsive ? (
    <img
      className="image-responsive"
      src={localSrc}
      onError={localOnError}
      {...props}
    />
  ) : (
    <img
      className={classNames('object-contain', className)}
      src={localSrc}
      onError={localOnError}
      {...props}
    />
  );
}
