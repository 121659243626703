import { Asset, Attachment } from '@integration-frontends/integration/core/model';
import { useComponentResolver } from '@integration-frontends/core';
import {
  ASSET_DETAILS_COMPONENT_KEY,
  AssetDetailsComponent,
  AssetDetailsComponentProps,
} from './asset-details-component';
import React from 'react';

export interface AssetDetailsContainerProps {
  asset: Asset;
  attachment: Attachment;
}

export function AssetDetailsContainer(props: AssetDetailsContainerProps) {
  const Component =
    useComponentResolver<AssetDetailsComponentProps>(ASSET_DETAILS_COMPONENT_KEY) ||
    AssetDetailsComponent;

  return <Component {...props} />;
}
