import {
  Asset,
  AssetCustomFieldValue,
  AssetTag,
  Attachment,
  Container,
  SearchParameters,
  Section,
  SortDirection,
  SortOptions,
} from '@integration-frontends/integration/core/model';

export enum ResourceBaseSortableProperty {
  Name = 'name',
  Position = 'position',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Popularity = 'popularity',
}

export const ALL_RESOURCE_BASE_SORTABLE_FIELDS = Object.values(ResourceBaseSortableProperty);
export const RESOURCE_BASE_SORT_OPTIONS: SortOptions<ResourceBaseSortableProperty>[] = [
  { field: ResourceBaseSortableProperty.Position, direction: SortDirection.Asc },
  { field: ResourceBaseSortableProperty.CreatedAt, direction: SortDirection.Desc },
  { field: ResourceBaseSortableProperty.CreatedAt, direction: SortDirection.Asc },
  { field: ResourceBaseSortableProperty.UpdatedAt, direction: SortDirection.Desc },
  { field: ResourceBaseSortableProperty.UpdatedAt, direction: SortDirection.Asc },
  { field: ResourceBaseSortableProperty.Name, direction: SortDirection.Asc },
  { field: ResourceBaseSortableProperty.Name, direction: SortDirection.Desc },
  { field: ResourceBaseSortableProperty.Popularity, direction: SortDirection.Desc },
  { field: ResourceBaseSortableProperty.Popularity, direction: SortDirection.Asc },
];

export interface ListOptions {
  searchParams?: SearchParameters;
  pagination?: {
    perPage: number;
    page?: number;
  };
  sort?: SortOptions;
}

export interface AssetDetails {
  tags: AssetTag[];
  customFieldValues: AssetCustomFieldValue[];
}

export interface PagedResults<T> {
  data: T;
  totalCount: number;
  currentPage: number;
  perPage: number;
}

export interface AssetsListResultSet {
  assets: Asset[];
  attachments: Attachment[];
}

export interface IAssetRepo {
  fetchAsset: (assetId: string) => Promise<Asset>;
  getAssetDetails: (assetId: string) => Promise<AssetDetails>;
  addAssetTags: (assetIds: string[], tags: string[]) => Promise<void>;
  listContainerAssets: (
    container: Container,
    options?: ListOptions,
  ) => Promise<PagedResults<AssetsListResultSet>>;
  listCollectionSectionAssets: (
    collectionId: string,
    sectionId: string,
    options?: ListOptions,
  ) => Promise<PagedResults<AssetsListResultSet>>;
  listSectionAssets: (
    sectionId: string,
    options?: ListOptions,
  ) => Promise<PagedResults<AssetsListResultSet>>;
  listContainerSectionAssets: (
    container: Container,
    sectionId: string,
    options?: ListOptions,
  ) => Promise<PagedResults<AssetsListResultSet>>;
  listContainerSectionsAssets: (
    container: Container,
    sections: Section[],
    options?: ListOptions,
  ) => Promise<{ sectionId: string; results: PagedResults<AssetsListResultSet> }[]>;
  create: (container: Container, sectionId: string, name: string, files: File[], source: string) => Promise<void>;
}
