import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  getResponseListDataOrDefault,
  isError,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Container,
  ILabelRepo,
  Label,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { map } from 'ramda';
import { GET_API_KEY_TOKEN, IGetApiKey, mapLabel } from './model';

@injectable()
export class LabelRepo implements ILabelRepo {
  private brandfolderApi: BrandfolderApi;
  private getApiKey: IGetApiKey;

  constructor() {
    this.brandfolderApi = DI_CONTAINER.get(BRANDFOLDER_API_TOKEN);
    this.getApiKey = DI_CONTAINER.get(GET_API_KEY_TOKEN);
  }

  getContainerLabels = async (container: Container): Promise<Label[]> => {
    if (!container) return [];

    return (
      container.type === ResourceType.BRANDFOLDER
        ? this.brandfolderApi.getBrandfolderLabels(await this.getApiKey(), container.id)
        : this.brandfolderApi.getCollectionLabels(await this.getApiKey(), container.id)
    )
      .then((response) => {
        if (isError(response)) return [];
        return getResponseListDataOrDefault(response);
      })
      .then(map((labelDto) => mapLabel(container.id, labelDto)));
  };
}
