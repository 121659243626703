import { BFButton, ButtonType, IconArrowLeft } from '@integration-frontends/common/ui';
import React, { ButtonHTMLAttributes, HTMLAttributes } from 'react';

export function BFBackButton(
  props: HTMLAttributes<HTMLButtonElement> & ButtonHTMLAttributes<HTMLButtonElement>,
) {
  return (
    <BFButton
      buttonType={ButtonType.Secondary}
      className="flex justify-center items-center"
      style={{ width: 40, height: 40 }}
      {...props}
    >
      <IconArrowLeft />
    </BFButton>
  );
}
