import { Attachment, AttachmentVariant } from '@integration-frontends/integration/core/model';
import React, {
  FunctionComponent,
  MouseEvent as ReactMouseEvent,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import { IconProps } from '@integration-frontends/common/ui/icons/create-icon';
import { noop } from 'lodash';

export const ATTACHMENT_ACTION_CONTAINER_RESET_MS = 3000;
export interface AttachmentActionContainerBaseRenderProps {
  id: string;
  loading: boolean;
  success: boolean;
  label: string;
  ariaLabel: string;
  enabled: boolean;
  Icon?: FunctionComponent<IconProps>;
  onClick: (e: ReactMouseEvent<HTMLElement, MouseEvent>) => void;
}

export interface AttachmentActionContainerBaseProps {
  onLoading?: (label?: string) => void;
  onSuccess?: (label?: string) => void;
  isMultiSelect?: boolean;
  selectedAttachments: (Attachment | AttachmentVariant)[];
  render: (props: AttachmentActionContainerBaseRenderProps) => ReactElement;
  location?: string,
}

export type AttachmentActionContainer = FunctionComponent<AttachmentActionContainerBaseProps>;

export const ATTACHMENT_ACTION_PROVIDER_TOKEN = 'ATTACHMENT_ACTION_PROVIDER';

export interface IAttachmentActionProvider {
  getActionContainers: () => FunctionComponent<AttachmentActionContainerBaseProps>[];
}

export const AttachmentActionProviderContext = React.createContext<{
  actionContainers: AttachmentActionContainer[];
}>({ actionContainers: [] });

export function createBasicActionContainer(
  id: string,
  label: string,
  onClick: (attachments: AttachmentActionContainerBaseProps['selectedAttachments']) => void,
  Icon?: AttachmentActionContainerBaseRenderProps['Icon'],
) {
  return ({
    selectedAttachments,
    render,
    onSuccess = noop,
  }: AttachmentActionContainerBaseProps) => {
    const [success, setSuccess] = useState(false);

    const clicked = useCallback(() => {
      onClick(selectedAttachments);
      setSuccess(true);
      onSuccess();
    }, [selectedAttachments]);

    return render({
      label,
      loading: false,
      enabled: true,
      Icon,
      id,
      success,
      onClick: clicked,
      ariaLabel: label,
    });
  };
}
