import { createSelector } from 'reselect';
import { default as reducer } from './reducer';
import * as selectors from './selectors';

export * from './app-actions';
export { clear } from './actions';

export function getAuthRootReducer() {
  return {
    auth: reducer,
  };
}

const selectAuthState = ({ auth }) => auth;
export const selectIdentity = createSelector(selectAuthState, selectors.selectIdentity);
export const selectError = createSelector(selectAuthState, selectors.selectError);
