import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  getResponseDataOrDefault,
  getResponseIncluded,
  getResponseListDataOrDefault,
  getResponseMeta,
  isError,
  TagDto,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  IUserSettingsRepo,
  MediaType,
  UploadPreferencesResults,
  ResourceBaseSortableProperty,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { compose, map } from 'ramda';
import {
  GET_API_KEY_TOKEN,
  IGetApiKey,
} from './model';

@injectable()
export class UserSettingsRepo implements IUserSettingsRepo {
  private brandfolderApi: BrandfolderApi;
  private getApiKey: IGetApiKey;
  private supportedMediaTypes: MediaType[];

  constructor() {
    this.brandfolderApi = DI_CONTAINER.get(BRANDFOLDER_API_TOKEN);
    this.getApiKey = DI_CONTAINER.get(GET_API_KEY_TOKEN);
  }

  setAssetUploadPreference = async (
    organizationId: string,
    containerId: string,
    sectionId: string,
  ): Promise<UploadPreferencesResults> => {
    return this.brandfolderApi
      .saveUploadPreferences(await this.getApiKey(), organizationId, containerId, sectionId)
      .then(res => this._mapUploadPreferences(res));
  };

  getAssetUploadPreference = async (): Promise<UploadPreferencesResults> => {
    return this.brandfolderApi
      .getUploadPreferences(await this.getApiKey())
      .then(res => this._mapUploadPreferences(res));
  };

  private _mapUploadPreferences(
    res: any,
  ): UploadPreferencesResults {
    return {
      organizationId: res?.data?.attributes?.organization_key || '',
      containerId: res?.data?.attributes?.brandfolder_key || '',
      sectionId: res?.data?.attributes?.section_key || '',
    };
  }
}
