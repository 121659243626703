import { SetApiKey } from '@integration-frontends/common/auth/ui';
import { Loader } from '@integration-frontends/common/ui';
import { AttachmentEditor } from '@integration-frontends/integration/ui';
import { ConnectedRouter } from 'connected-react-router';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { init } from '@integration-frontends/common/app';
import { EditState } from './model';
import store, { history } from './store';
import { Header } from '../../../common/src/header';
import '../../logrocket/logrocketSetup';
import { SelectAttachment } from '../../../common/src';

export function App() {
  store.dispatch(init({ name: 'integration-sfmc' }));

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Suspense
            fallback={
              <div className="w-full h-full flex items-center justify-center">
                <Loader />
              </div>
            }
          >
            <Switch>
              <Route path="/welcome">
                <SetApiKey />
              </Route>
              <Route
                path="/select-attachment"
                render={(routeParams) => (
                  <div className="flex flex-col">
                    <SelectAttachment {...routeParams} />
                  </div>
                )}
              />
              <Route
                path="/edit-attachment/:attachmentId"
                render={({
                  match: { params },
                  location: { state },
                }: RouteComponentProps<any> & {
                  location: { state: EditState };
                }) => {
                  return (
                    <div className="flex flex-col">
                      <Header />
                      <div className="container py-6">
                        <AttachmentEditor
                          attachmentId={params.attachmentId}
                          options={state?.options}
                        />
                      </div>
                    </div>
                  );
                }}
              />
              <Redirect to="/select-attachment" />
            </Switch>
          </Suspense>
        </ConnectedRouter>
      </Provider>
    </div>
  );
}

export default App;
