import LogRocket from 'logrocket';
import { Identity } from '@integration-frontends/common/auth/core/model';
import { useStorage, StorageMethods } from '@apps-common/utils';

export const sendLogRocketIdentity = (identity: Identity) => {
  const logRocketIdObject = JSON.parse(useStorage(StorageMethods.GetItem, '_lr_id_'));
  const logRocketId = logRocketIdObject?.userID;

  LogRocket.identify(logRocketId || 'no_id', {
    brandfolderId: identity?.userId,
    name: `${identity?.firstName} ${identity?.lastName}`,
    email: identity?.email,
  });
}
