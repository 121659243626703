import { IconPin, IconSize, keyToClick, arrowKeyNavigate, addToRefs } from '@integration-frontends/common/ui';
import { searchFilterEntitySelectors } from '@integration-frontends/integration/core/application';
import { Container, SearchFilter, positionComparator } from '@integration-frontends/integration/core/model';
import React, { HTMLAttributes, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

export interface PinnedSearchesProps {
  container: Container;
  onSearchFilterSelect: (searchFilter: SearchFilter) => void;
}

export function PinnedFiltersList({
  className,
  container,
  onSearchFilterSelect,
  ...props
}: PinnedSearchesProps & HTMLAttributes<HTMLDivElement>) {
  const searchFilters = useSelector(searchFilterEntitySelectors.byContainer(container));
  const [focusedItem, setFocusedItem] = useState(0);
  const itemRefs = useRef([]);

  useEffect(() => {
    if (itemRefs.current.length) {
      itemRefs.current[focusedItem].focus();
    }
  });

  const handleOnKeyPress = (e) => {
    keyToClick(e);
    arrowKeyNavigate(e, focusedItem, itemRefs.current, setFocusedItem);
  };
  return (
    <div className={classNames('flex flex-col', className)} {...props}>
      {searchFilters.sort(positionComparator).map((searchFilter) => (
        <div
          key={searchFilter.id}
          className="pins-filters-list-item flex cursor-pointer items-center"
          onClick={() => onSearchFilterSelect(searchFilter)}
          onKeyDown={handleOnKeyPress}
          ref={(ref) => addToRefs(ref, itemRefs.current)}
          tabIndex={0}
        >
          <IconPin
            iconSize={IconSize.Large}
            className="mr-xs"
          />
          <span className="font-medium flex-grow">{searchFilter.label}</span>
        </div>
      ))}
    </div>
  );
}
