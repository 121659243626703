import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { Container } from '@integration-frontends/integration/core/model';
import { createAction } from '@reduxjs/toolkit';
import { AssetData } from './reducer';

export const selectOrganization = createAction(
  'UPLOAD_ASSETS_SELECT_ORGANIZATION',
  withPayloadType<{ id: string }>(),
);

export const selectContainer = createAction(
  'UPLOAD_ASSETS_SELECT_CONTAINER',
  withPayloadType<{container: { id: string, type: string }}>(),
);

export const selectSection = createAction(
  'UPLOAD_ASSETS_SELECT_SECTION',
  withPayloadType<{ id: string }>(),
);

export const addAssets = createAction(
  'UPLOAD_ASSETS_ADD_ASSETS',
  withPayloadType<{ assets: AssetData[] }>(),
);

export const removeAsset = createAction(
  'UPLOAD_ASSETS_REMOVE_ASSET',
  withPayloadType<{ asset: AssetData }>(),
);

export const upload = createAction(
  'UPLOAD_ASSETS_UPLOAD',
  withPayloadType<{ organizationId?: string, container: Container, sectionId: string, assets: AssetData[], source: string, savePreferences?: boolean, tags?: { value: string }[] }>(),
);
export const uploadSuccess = createAction('UPLOAD_ASSETS_UPLOAD_SUCCESS');
export const uploadMore = createAction('UPLOAD_ASSETS_UPLOAD_MORE');
export const uploadAssetsInit = createAction('UPLOAD_ASSETS_INIT');
export const uploadAssetsInitSuccess = createAction('UPLOAD_ASSETS_INIT_SUCCESS');
export const visitBrandfolder = createAction(
  'UPLOAD_ASSETS_VISIT_BRANDFOLDER',
  withPayloadType<{ container: Container }>(),
);

export const setUploadPreferences = createAction(
  'UPLOAD_ASSETS_SET_PREFERENCES',
  withPayloadType<{ organizationId: string, containerId: string, sectionId: string}>(),
);
export const backToHome = createAction('UPLOAD_ASSETS_BACK_TO_HOME');
