import {
  BFIconButton,
  enterToClick,
  IconAdd,
  IconMoreOptions,
  IconOpenInBrandfolder,
  keyToClick,
} from '@integration-frontends/common/ui';
import { DEFAULT_DATE_FORMAT, DI_CONTAINER } from '@integration-frontends/core';
import {
  attachmentEntitySelectors,
  containerSelectors,
  initiatePlaceAttachment,
  ISelectAttachmentOptions,
  searchAssetsActions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  SelectActionType,
} from '@integration-frontends/integration/core/application';
import {
  Asset,
  Attachment,
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  Container,
  DOWNLOAD_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
  IDownloadService,
  positionComparator,
} from '@integration-frontends/integration/core/model';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AttachmentThumbnailImage } from '../../../common/attachment-thumbnail-image';
import { Thumbnail } from '../../common';
import { DOWNLOAD_KEY, KB_KEY, PLACE_KEY } from '../../i18n';
import {
  INTEGRATION_COMMON_NAMESPACE,
  OPEN_IN_BRANDFOLDER_LABEL_KEY,
} from '@integration-frontends/integration/ui/common/i18n';
import './asset-attachments.scss';
import { usePopper } from 'react-popper';
import { PopoverMenu, PopoverMenuItem } from '@integration-frontends/common/ui/menu/popover-menu';
import { useAttachmentActions } from '@integration-fontends/integration/ui/attachment-selector/common/attachment-actions/use-attachment-action';
import { Popover } from '@headlessui/react';
import { IconDownload } from '@integration-frontends/common/ui/icons/icon-download';

export interface AssetAttachmentsProps {
  asset: Asset;
  containerId: string;
  onAttachmentClick: (attachment: Attachment) => void;
  selectedAttachmentId?: string;
}

export function AssetAttachments({
  asset,
  containerId,
  onAttachmentClick,
  selectedAttachmentId,
}: AssetAttachmentsProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const container = useSelector((state) => containerSelectors.selectById(state, containerId));
  const attachments = useSelector(attachmentEntitySelectors.byAssetId(asset.id)).sort(
    positionComparator,
  );

  const attachmentClicked = useCallback(
    (attachment: Attachment) => {
      onAttachmentClick(attachment);
    },
    [asset],
  );

  return (
    <div>
      {attachments.map((attachment) => (
        <div
          data-cy="attachment"
          data-testid="attachment"
          key={attachment.id}
          className="flex gap-lg mb-md justify-between"
        >
          <div
            className="flex flex-1 gap-lg cursor-pointer items-center"
            onClick={() => attachmentClicked(attachment)}
            onKeyDown={keyToClick}
            tabIndex={0}
          >
            <Thumbnail
              selected={attachment.id === selectedAttachmentId && asset.attachmentCount > 1}
              style={{ flex: '0 0 100px', height: 80 }}
            >
              <AttachmentThumbnailImage attachment={attachment} />
            </Thumbnail>

            <div className="flex items-center">
              <div className="flex flex-col justify-center">
                <span className="font-bold asset-attachment-name">{attachment.name}</span>
                <span className="text-sm text-primary leading-snug">
                  {attachment.extension.toLocaleUpperCase()} &#8901;{' '}
                  {(attachment.sizeInBytes / 1000).toFixed(1)} {t(KB_KEY)}
                </span>
                <span className="text-sm text-primary leading-snug">
                  {attachment.dimensions.width} x {attachment.dimensions.height} px &#8901;{' '}
                  {format(attachment.updatedAt, DEFAULT_DATE_FORMAT)}
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-self-end">
            <AttachmentOptions
              asset={asset}
              attachment={attachment}
              container={container}
              buttonTransparent={true}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

interface AttachmentOptionsProps {
  asset: Asset;
  attachment: Attachment;
  container: Container;
  buttonTransparent?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
}

export function AttachmentOptions({
  asset,
  attachment,
  buttonTransparent,
  container,
  onBlur,
  onFocus,
}: AttachmentOptionsProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const dispatch = useDispatch();
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );
  const downloadService: IDownloadService = DI_CONTAINER.get(DOWNLOAD_SERVICE_TOKEN);
  const selectOptions: ISelectAttachmentOptions = DI_CONTAINER.get(SELECT_ATTACHMENT_OPTIONS_TOKEN);
  const selectActionType: SelectActionType = selectOptions.actionType;
  const [moreOptionsButtonElement, setMoreOptionsButtonElement] = useState(null);
  const [optionsPopupElement, setOptionsPopupElement] = useState(null);
  const { styles, attributes } = usePopper(moreOptionsButtonElement, optionsPopupElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    strategy: 'absolute',
  });
  const { attachmentActionContainers } = useAttachmentActions();

  const downloadClicked = useCallback(() => {
    dispatch(searchAssetsActions.downloadAttachment());
    window.open(downloadService.getAttachmentDownloadLink(container, attachment));
  }, [container, attachment]);

  const placeClicked = useCallback(() => {
    dispatch(initiatePlaceAttachment({ attachment }));
  }, [attachment]);

  const visitInBrandfolderClicked = useCallback(() => {
    window.open(bfWebsiteService.getAssetPageUrl(container, asset), '_blank');
  }, [asset, container]);

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button as="div">
            <BFIconButton
              className={classNames(
                'attachment-options-button',
                { open: open },
                { 'transparent-background': buttonTransparent },
              )}
              data-testid="more-options-btn"
              iconElement={<IconMoreOptions />}
              ref={setMoreOptionsButtonElement}
              ariaLabel="more options"
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Popover.Button>

          <Popover.Panel
            data-testid="more-options-menu-panel-container"
            className="more-options-menu-panel-container"
            ref={setOptionsPopupElement}
            style={{ ...styles.popper }}
            {...attributes.popper}
          >
            {({ close }) => {
              return (
                <PopoverMenu>
                  {attachmentActionContainers.map((Container, idx) => (
                    <Container
                      key={idx}
                      selectedAttachments={[attachment]}
                      location="show-page"
                      render={({ id, enabled, Icon, label, onClick }) => {
                        return (
                          <PopoverMenuItem
                            disabled={!enabled}
                            onClick={onClick}
                            data-testid={`attachment-action-${id}`}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onKeyDown={enterToClick}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {Icon && <Icon />}
                            {label}
                          </PopoverMenuItem>
                        );
                      }}
                    />
                  ))}

                  {attachmentActionContainers.length === 0 &&
                    selectActionType === SelectActionType.Place && (
                      <PopoverMenuItem
                        data-testid="place-action"
                        onClick={() => {
                          placeClicked();
                          close();
                        }}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyDown={enterToClick}
                      >
                        {/* TODO: temporary until we get the designs */}
                        <IconAdd />
                        {t(PLACE_KEY)}
                      </PopoverMenuItem>
                    )}

                  {container && (
                    <PopoverMenuItem
                      onClick={() => {
                        visitInBrandfolderClicked();
                        close();
                      }}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      onKeyDown={enterToClick}
                    >
                      <IconOpenInBrandfolder />
                      {t(OPEN_IN_BRANDFOLDER_LABEL_KEY)}
                    </PopoverMenuItem>
                  )}

                  {container && (
                    <PopoverMenuItem
                      onClick={() => {
                        downloadClicked();
                        close();
                      }}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      onKeyDown={enterToClick}
                    >
                      <IconDownload />
                      {t(DOWNLOAD_KEY)}
                    </PopoverMenuItem>
                  )}
                </PopoverMenu>
              );
            }}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
