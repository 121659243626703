import {
  AbsoluteDimensions,
  areDimensionsEqual,
  Attachment,
  AttachmentVariant,
  getMediaTypesExtensions,
  hasOffsets,
  MediaType,
  Offsets,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { any, compose, includes, toString } from 'ramda';

const BF_CDN_V1_BASE_URL = 'https://cdn.brandfolder.io';
const BF_CDN_V2_BASE_URL = 'https://cdn.bfldr.com';
const BF_CDN_STAGING_BASE_URLS = [
  'https://cdn-staging.bfldr.com',
  'https://cdn-staging.brandfolder.io',
];

const isV1 = includes(BF_CDN_V1_BASE_URL);
const isV2 = (url) =>
  includes(BF_CDN_V2_BASE_URL, url) ||
  any(
    Boolean,
    BF_CDN_STAGING_BASE_URLS.map((cdnUrl) => includes(cdnUrl, url)),
  );
export const supportsCdnTransforms = (url: string) => isV1(url) || isV2(url);
export const supportsCropping = isV2;

export interface CdnVariantOptions {
  size?: AbsoluteDimensions;
  crop?: AbsoluteDimensions & Offsets;
  mediaType?: MediaType;
}

// Generates a Brandfolder CDN url with the specified variations on the original attachment
export function createAttachmentCdnVariant(
  attachment: Attachment,
  options: CdnVariantOptions,
): AttachmentVariant {
  const { crop, size, mediaType } = options;
  const variantUrl = new URL(attachment.url);
  const roundedToString = compose(toString, Math.round);

  if (size) {
    size.width &&
      size.width !== attachment.dimensions.width &&
      variantUrl.searchParams.set('width', roundedToString(size.width));
    size.height &&
      size.height !== attachment.dimensions.height &&
      variantUrl.searchParams.set('height', roundedToString(size.height));
  }

  if (
    crop &&
    (!areDimensionsEqual(crop, attachment.dimensions) || hasOffsets(crop)) &&
    isV2(attachment.url)
  ) {
    variantUrl.searchParams.set(
      'crop',
      `${roundedToString(crop.width)},${roundedToString(crop.height)},x${roundedToString(
        crop.offsetX,
      )},y${roundedToString(crop.offsetY)},safe`,
    );
  }

  if (mediaType) {
    variantUrl.searchParams.set(
      'format',
      getMediaTypesExtensions([mediaType])?.[0] || attachment.extension,
    );
  }

  return {
    ...attachment,
    type: ResourceType.ATTACHMENT_VARIANT,
    attachmentId: attachment.id,
    dimensions: options.size,
    url: variantUrl.toString(),
    thumbnailUrl: variantUrl.toString(),
    mediaType: mediaType || attachment.mediaType,
  };
}

export function setMediaType(url: string, mediaType: MediaType): string {
  const transformedUrl = new URL(url);
  const extension = getMediaTypesExtensions([mediaType])?.[0];

  if (!extension) return url;

  if (isV2(url)) {
    transformedUrl.searchParams.set('format', extension);
  } else {
    transformedUrl.pathname = `${removeExtension(transformedUrl.pathname)}.png`;
  }
  return transformedUrl.toString();
}

// TODO: use library?
function removeExtension(path: string): string {
  return path.replace(/\.[^/.]+$/, '');
}
