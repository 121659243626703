import { FlexSpacer } from '@integration-fontends/integration/ui/attachment-selector/common/layout/flex-spacer';
import React, { useEffect, useRef, useState } from 'react';
import {
  AttachmentOptionsComponent,
  AttachmentOptionsComponentProps,
} from '@integration-fontends/integration/ui/attachment-selector/common/attachment-transforms/attachment-options-component';
import { FormControl } from '@integration-fontends/integration/ui/attachment-selector/common/layout/form';
import { BFButton, BFInput, BFLabel, InputSize } from '@integration-frontends/common/ui';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import {
  ATTACHMENT_CDN_LINK_KEY,
  COPIED_KEY,
  COPY_CDN_EMBED_LINK,
} from '@integration-fontends/integration/ui/attachment-selector/i18n';

export type CdnEmbedOptionsProps = AttachmentOptionsComponentProps;

export function CdnEmbedOptionsComponent(props: CdnEmbedOptionsProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const [copied, setCopied] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  function copy() {
    inputRef.current?.select();
    inputRef.current?.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setCopied(true);
  }

  return (
    <FlexSpacer direction={'vertical'} data-testid="cdn-embed-options">
      <AttachmentOptionsComponent {...props} />

      <FlexSpacer size={'xs'} style={{ alignItems: 'flex-end' }}>
        <FormControl className="grow">
          <BFLabel>{t(ATTACHMENT_CDN_LINK_KEY)}</BFLabel>
          <BFInput
            ref={inputRef}
            size={InputSize.Medium}
            type="text"
            data-testid="copy-cdn-link-input"
            value={props.attachment?.url}
            readOnly
          />
        </FormControl>

        <BFButton data-testid="copy-cdn-link-btn" onClick={copy}>
          {copied ? t(COPIED_KEY) : t(COPY_CDN_EMBED_LINK)}
        </BFButton>
      </FlexSpacer>
    </FlexSpacer>
  );
}
