import {
  selectAttachmentInit,
  selectAttachmentSelectors,
} from '@integration-frontends/integration/core/application';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { IntegrationPageLoader } from '../common/page-loader/page-loader';
import { OrganizationLandingPage } from './components';
import { AssetDetailsPage } from './components/asset-details-page';
import {
  ATTACHMENT_SELECTOR_NAVIGATION_CONTEXT,
  AttachmentSelectorView,
  useAttachmentSelectorNavigation,
} from './navigation';
import './attachment-selector.scss';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { ShowPage } from './components/show-page';

export interface AttachmentSelectorProps {
  assetSelect?: (assetId: string) => void;
  containerSelect?: (containerId: string) => void;
}

function AttachmentSelectorComponent({ assetSelect, containerSelect }: AttachmentSelectorProps) {
  const dispatch = useDispatch();
  const initSuccess = useSelector(selectAttachmentSelectors.initSuccess);
  const navigation = useAttachmentSelectorNavigation();

  useEffect(() => {
    dispatch(selectAttachmentInit());
  }, []);

  if (!initSuccess) {
    return <IntegrationPageLoader />;
  }

  return (
    <>
      <Switch>
        <Route
          exact={true}
          path={navigation.getPathMatch(AttachmentSelectorView.ContainerSelector)}
          render={(routeProps) => (
            <div className="h-full overflow-y-auto">
              <OrganizationLandingPage {...routeProps} />
            </div>
          )}
        />

        <Route
          exact={true}
          path={navigation.getPathMatch(AttachmentSelectorView.AssetDetailsNoContainer)}
          render={(routeProps) => {
            return (
              <AssetDetailsPage
                assetSelect={assetSelect}
                assetId={routeProps.match.params.assetId}
              />
            );
          }}
        />

        <Route
          path={navigation.getPathMatch(AttachmentSelectorView.ShowPage)}
          render={(routeProps) => (
            <div className="h-full overflow-y-hidden relative">
              <ShowPage containerSelect={containerSelect} {...routeProps} />

              <Route
                exact={true}
                path={navigation.getPathMatch(AttachmentSelectorView.AssetDetails)}
                render={(routeProps) => {
                  return (
                    <div id="asset-details-container">
                      <AssetDetailsPage
                        assetSelect={assetSelect}
                        assetId={routeProps.match.params.assetId}
                        containerId={routeProps.match.params.containerId}
                      />
                    </div>
                  );
                }}
              />
            </div>
          )}
        />
      </Switch>
    </>
  );
}

export function AttachmentSelector({
  match,
  ...props
}: AttachmentSelectorProps & RouteComponentProps) {
  return (
    <ATTACHMENT_SELECTOR_NAVIGATION_CONTEXT.Provider value={{ navigationRootMatch: match }}>
      <AttachmentSelectorComponent {...props} />
    </ATTACHMENT_SELECTOR_NAVIGATION_CONTEXT.Provider>
  );
}
