import React from 'react';
import { createIcon } from './create-icon';

// https://heroicons.com/
// menu-alt-3
const icon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
  </svg>
);

export const IconAlignRight = createIcon(icon);
