import { Asset, Attachment } from '@integration-frontends/integration/core/model';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { VIEW_DETAILS_KEY } from '../../i18n';
import { useInjector } from '@integration-frontends/core';
import {
  attachmentDeselected,
  attachmentSelected,
  containerSelectors,
  initiatePlaceAttachment,
  ISelectAttachmentOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  selectAttachmentSelectors,
  SelectionType,
} from '@integration-frontends/integration/core/application';
import { BadgeSize, BadgeType, BFCheckbox, PillBadge, enterToClick } from '@integration-frontends/common/ui';
import { Thumbnail } from '../thumbnail';
import { AttachmentThumbnailImage } from '@integration-frontends/integration/ui/common/attachment-thumbnail-image';
import { ThumbnailOverlay } from '../thumbnail-overlay';
import React, { useState } from 'react';
import { ThumbnailContainer, ThumbnailLabelContainer } from './common';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import { AttachmentOptions } from '../../components/asset-details-page';
import { useAttachmentSelectorNavigation } from '../../navigation';
import './attachment-thumbnail.scss';

interface AttachmentThumbnailProps {
  asset: Asset;
  attachment: Attachment;
  containerId: string;
  responsive?: boolean;
  useAssetName?: boolean;
  index?: number;
}

export function AttachmentThumbnail({
  asset,
  attachment,
  containerId,
  responsive,
  useAssetName,
  index,
}: AttachmentThumbnailProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const selectionOptions: ISelectAttachmentOptions = useInjector(SELECT_ATTACHMENT_OPTIONS_TOKEN);
  const selectionType: SelectionType = selectionOptions.selectionType;
  const selectedAttachmentIds = useSelector(selectAttachmentSelectors.selectedAttachmentIds);
  const container = useSelector((state) => containerSelectors.selectById(state, containerId));
  const navigation = useAttachmentSelectorNavigation();
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const [menuFocus, setMenuFocus] = useState(false);

  const selectionForSingle = () => {
    dispatch(initiatePlaceAttachment({ attachment }));
  };

  const selectionForMulti = () => {
    selectedAttachmentIds.includes(attachment.id)
      ? dispatch(attachmentDeselected({ attachment: attachment }))
      : dispatch(attachmentSelected({ attachment: attachment }));
  };

  const onSelection = () => {
    selectionType === SelectionType.Multi ? selectionForMulti() : selectionForSingle();
  };

  const action = (
    <a
      data-testid="attachment-view-details-btn"
      className="attachment-adjust-margin w-max button-neutral"
      onClick={() => navigation.goToAssetDetails(asset.id, selectedContainer.id, attachment.id)}
      tabIndex={0}
      onFocus={() => setMenuFocus(true)}
      onBlur={() => setMenuFocus(false)}
      onKeyDown={enterToClick}
    >
      {t(VIEW_DETAILS_KEY)}
    </a>
  );

  if (!asset || !attachment) return null;

  return (
    <ThumbnailContainer className="attachment-thumbnail" data-cy="attachment-thumbnail">
      <Thumbnail >
        <div
          data-testid="attachment-thumbnail"
          className="asset-thumbnail-content w-full flex items-center justify-center"
          style={{ height: 150, position: 'relative' }}
        >
          <AttachmentThumbnailImage attachment={attachment} responsive={responsive} />
          <ThumbnailOverlay
            className={`asset-overlay ${menuFocus && 'show'}`}
            centerElement={action}
            topElement={
              <AttachmentOptions
                asset={asset}
                attachment={attachment}
                container={container}
                onFocus={() => setMenuFocus(true)}
                onBlur={() => setMenuFocus(false)}
              />
            }
          />

          {asset?.attachmentCount > 1 && (
            <PillBadge
              badgeType={BadgeType.Primary}
              badgeSize={BadgeSize.Small}
              style={{
                position: 'absolute',
                top: '4px',
                right: '4px',
                zIndex: 50,
              }}
            >
              <span className="attachment-text-md attachment-adjust-margin">
                {index + 1}/{asset.attachmentCount}
              </span>
            </PillBadge>
          )}
        </div>
      </Thumbnail>

      {attachment && (
        <ThumbnailLabelContainer>
          <span className="flex flex-row items-center justify-between truncate">
            <div className="flex flex-row truncate p-xxs">
              {selectionType === SelectionType.Multi && (
                <BFCheckbox
                  data-testid="select-attachment-checkbox"
                  className="attachment-checkbox sq-20"
                  checked={selectedAttachmentIds.includes(attachment?.id)}
                  onChange={onSelection}
                  labelClassName="attachment-name font-medium truncate"
                  label={useAssetName ? asset.name : attachment.name}
                />
              )}
              {selectionType === SelectionType.Single && (
                <p>{useAssetName ? asset.name : attachment.name}</p>
              )}
            </div>
            <PillBadge badgeType={BadgeType.Secondary} badgeSize={BadgeSize.Medium}>
              {attachment.extension?.toLocaleUpperCase()}
            </PillBadge>
          </span>
        </ThumbnailLabelContainer>
      )}
    </ThumbnailContainer>
  );
}
