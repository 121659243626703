import { BFImage } from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Attachment,
  AttachmentVariant,
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

export interface AttachmentImageProps {
  attachment: Attachment | AttachmentVariant;
  useFullSize?: boolean;
  responsive?: boolean;
}

export function AttachmentThumbnailImage({
  attachment,
  responsive,
  useFullSize = false,
  ...props
}: AttachmentImageProps & HTMLAttributes<HTMLImageElement>) {
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );

  return (
    <BFImage
      src={useFullSize ? attachment?.url : attachment?.thumbnailUrl}
      fallbackUrl={bfWebsiteService.getFileThumbnailUrl(attachment?.extension)}
      className={classNames('w-full h-full')}
      responsive={responsive}
      {...props}
    />
  );
}
