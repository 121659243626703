import { isNil } from 'ramda';

export enum ImageType {
  Ai = 'AI',
  Gif = 'GIF',
  Jpg = 'JPG',
  Png = 'PNG',
  Psd = 'PSD',
  svg = 'svg',
}

export const ALL_IMAGE_TYPES = [
  ImageType.Ai,
  ImageType.Gif,
  ImageType.Jpg,
  ImageType.Png,
  ImageType.Psd,
  ImageType.svg,
];

export const IMAGE_TYPE_EXTENSIONS = {
  [ImageType.Ai]: ['ai'],
  [ImageType.Gif]: ['gif'],
  [ImageType.Jpg]: ['jpg', 'jpeg'],
  [ImageType.Png]: ['png'],
  [ImageType.Psd]: ['psd'],
  [ImageType.svg]: ['svg'],
};

export const IMAGE_TYPE_MIME_TYPES = {
  [ImageType.Ai]: ['application/postscript'],
  [ImageType.Gif]: ['image/gif'],
  [ImageType.Jpg]: ['image/jpg', 'image/jpeg'],
  [ImageType.Png]: ['image/png'],
  [ImageType.Psd]: ['image/vnd.adobe.photoshop', 'application/vnd.adobe.photoshop'],
  [ImageType.svg]: ['image/svg+xml'],
};

export function getImageTypeMimeTypes(imageType: ImageType): string[] {
  return IMAGE_TYPE_MIME_TYPES[imageType] || null;
}

export function getImageTypeFromMimeType(mimeType: string): ImageType {
  let record: [string, string[]];
  if (mimeType !== null) {
    record = Object.entries(IMAGE_TYPE_MIME_TYPES).find(([, mimeTypes]) =>
      mimeTypes.includes(mimeType.toLocaleLowerCase()),
    );
  }

  return (record?.[0] as ImageType) || null;
}

export interface Offsets {
  offsetX: number;
  offsetY: number;
}

export interface RelativeOffsets {
  offsetX: number;
  offsetY: number;
}

export const NULL_OFFSETS: Offsets = { offsetX: null, offsetY: null };

export enum DimensionType {
  Absolute,
  Relative,
}

export interface AbsoluteDimensions {
  type: DimensionType.Absolute;
  width: number;
  height: number;
}

export interface RelativeDimensions {
  type: DimensionType.Relative;
  width: number;
  height: number;
}

export type Dimensions = AbsoluteDimensions | RelativeDimensions;

export const NULL_ABSOLUTE_DIMENSIONS: AbsoluteDimensions = {
  width: null,
  height: null,
  type: DimensionType.Absolute,
};

export const NULL_RELATIVE_DIMENSIONS: RelativeDimensions = {
  width: null,
  height: null,
  type: DimensionType.Relative,
};

export function getAspectRatio(dimensions: Dimensions): number {
  return dimensions?.width / dimensions?.height || null;
}

export function areDimensionsEqual(d1: Dimensions, d2: Dimensions): boolean {
  return d1.type === d2.type && d1.width === d2.width && d1.height === d2.height;
}

function isNilOrZero(number: number): boolean {
  return isNil(number) || number === 0;
}

export function hasOffsets(offset: Offsets): boolean {
  return !(isNilOrZero(offset.offsetX) && isNilOrZero(offset.offsetY));
}
