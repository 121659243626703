import { SearchFilter } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const searchFilterAdapter = createEntityAdapter<SearchFilter>();

export const searchFilterSlice = createSlice({
  name: 'searchFilter',
  initialState: searchFilterAdapter.getInitialState(),
  reducers: {
    searchFiltersReceived: searchFilterAdapter.upsertMany,
  },
});
