import { Asset } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const assetAdapter = createEntityAdapter<Asset>();

export const assetSlice = createSlice({
  name: 'asset',
  initialState: assetAdapter.getInitialState(),
  reducers: {
    assetsReceived: assetAdapter.upsertMany,
  },
});
