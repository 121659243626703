import { BFHR } from '@integration-frontends/common/ui';
import {
  containerSelectors,
  IntegrationRootState,
  searchAssetsActions,
  SearchAssetsGroupBy,
  searchAssetsSelectors,
} from '@integration-frontends/integration/core/application';
import { Container, Section } from '@integration-frontends/integration/core/model';
import { prop, sortBy } from 'ramda';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AssetsList } from '../../../common';
import { SEARCH_NO_RESULTS_KEY } from '../../../i18n';
import './assets-list.scss';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';

interface SectionWithAssetsProps {
  container: Container;
  showAttachments: boolean;
  section: Section;
  last?: boolean;
}

function SectionWithAssets({
  className,
  last = false,
  ...props
}: SectionWithAssetsProps & HTMLAttributes<HTMLDivElement>) {
  const dispatch = useDispatch();
  const { container, showAttachments, section } = props;
  const pagination = useSelector(
    searchAssetsSelectors.listBySectionSelectors.sectionPagination(section.id),
  );
  const assets = useSelector(searchAssetsSelectors.pagedSectionSearchResults(section.id));

  function nextPageClicked() {
    dispatch(searchAssetsActions.nextSectionPage({ container: container, sectionId: section.id }));
  }

  function previousPageClicked() {
    dispatch(
      searchAssetsActions.previousSectionPage({ container: container, sectionId: section.id }),
    );
  }

  return assets.length > 0 ? (
    <div className="assets-list-by-section">
      <AssetsList
        className={className}
        assets={assets}
        containerId={container.id}
        title={section.name}
        pagination={pagination}
        showAttachments={showAttachments}
        onNextPageClick={nextPageClicked}
        onPreviousPageClick={previousPageClicked}
      />
      {!last && <BFHR />}
    </div>
  ) : null;
}

interface AssetsListBySectionProps {
  selectedContainer: Container;
  showAttachments: boolean;
  selectedSection?: Section;
}

function AssetsListBySection({ ...props }: AssetsListBySectionProps) {
  const { selectedContainer, showAttachments, selectedSection } = props;
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  const sections: Section[] = useSelector(containerSelectors.sections(selectedContainer));
  const filteredSections = sortBy(prop('position'), sections).filter(
    (section) => !selectedSection || section.id === selectedSection.id,
  );

  return (
    <>
      {filteredSections.map((section, idx) => (
        <SectionWithAssets
          key={section.id}
          container={selectedContainer}
          section={section}
          showAttachments={showAttachments}
          last={idx === filteredSections.length - 1}
        />
      ))}
    </>
  );
}

function AssetsListByContainer({ ...props }) {
  const dispatch = useDispatch();
  const { container, section, showAttachments } = props;
  const pagination = useSelector(searchAssetsSelectors.listSelectors.pagination);
  const assets = useSelector(searchAssetsSelectors.pagedSearchResults());
  function nextPageClicked() {
    dispatch(searchAssetsActions.nextPage({ container }));
  }

  function previousPageClicked() {
    dispatch(searchAssetsActions.previousPage({ container }));
  }
  return assets.length > 0 ? (
    <>
      <AssetsList
        className="pb-xxl"
        assets={assets}
        containerId={container.id}
        pagination={pagination}
        showAttachments={showAttachments}
        onNextPageClick={nextPageClicked}
        onPreviousPageClick={previousPageClicked}
      />
      <BFHR className="mb-md" />
    </>
  ) : null;
}

export interface AssetsListProps {
  containerId: string;
  selectedSection?: Section;
  showAttachments: boolean;
}

export function AssetsListContainer({
  containerId,
  selectedSection,
  showAttachments,
}: AssetsListProps) {
  // selector for group by (by section vs by container)
  const selectedContainer = useSelector<IntegrationRootState, Container>((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const hasResults = useSelector(searchAssetsSelectors.hasResults);
  const groupBy = useSelector(searchAssetsSelectors.groupBy);
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  let assetList;
  if (groupBy === SearchAssetsGroupBy.Section) {
    assetList = (
      <AssetsListBySection
        selectedSection={selectedSection}
        selectedContainer={selectedContainer}
        showAttachments={showAttachments}
      />
    );
  } else {
    assetList = (
      <AssetsListByContainer container={selectedContainer} showAttachments={showAttachments} />
    );
  }

  return (
    <div data-testid="assets-list">
      {hasResults ? (
        assetList
      ) : (
        <div className="h-full w-full flex justify-center items-center pl-xxs">
          <p>{t(SEARCH_NO_RESULTS_KEY)}</p>
        </div>
      )}
    </div>
  );
}
