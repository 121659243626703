import { all, fork } from 'redux-saga/effects';
import { containerSelectedEffects } from './container-selected';
import { initEffects } from './init';
import { uploadEffects } from './upload';
import { visitBrandfolderEffects } from './visit-brandfolder';

export function* uploadAssetsEffects() {
  yield all([
    fork(initEffects),
    fork(containerSelectedEffects),
    fork(uploadEffects),
    fork(visitBrandfolderEffects),
  ]);
}
