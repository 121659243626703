import { DI_CONTAINER } from '@integration-frontends/core';
import {
  ASSET_REPO_TOKEN,
  ATTACHMENT_REPO_TOKEN,
  IAssetRepo,
  IAttachmentRepo,
} from '@integration-frontends/integration/core/model';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  assetCustomFieldValueEntityActions,
  assetEntityActions,
  assetTagEntityActions,
  attachmentEntityActions,
} from '../../../common/entities-state';
import { goToAssetDetails } from '../actions';

const handler = function* (action: ReturnType<typeof goToAssetDetails>) {
  const attachmentRepo: IAttachmentRepo = DI_CONTAINER.get(ATTACHMENT_REPO_TOKEN);
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);

  const [assetDetails, attachments] = yield all([
    call(assetRepo.getAssetDetails, action.payload.assetId),
    call(attachmentRepo.listAssetAttachments, action.payload.assetId),
  ]);

  yield put(assetTagEntityActions.assetTagsReceived(assetDetails.tags));
  yield put(
    assetCustomFieldValueEntityActions.assetCustomFieldValuesReceived(
      assetDetails.customFieldValues,
    ),
  );
  yield put(assetEntityActions.assetsReceived([assetDetails]));
  yield put(attachmentEntityActions.attachmentsReceived(attachments));
};

export function* goToAssetDetailsEffects() {
  yield takeEvery(goToAssetDetails, handler);
}
