import {
  CheckboxListContainer,
  CheckboxListItemContainer,
  SectionContent,
  SectionHeader,
} from '@integration-fontends/integration/ui/attachment-selector/components/advanced-filters/common';
import {
  BFCheckbox,
  BFSearchInput,
  InputSize,
  ToggleSwitch,
} from '@integration-frontends/common/ui';
import React, { useState } from 'react';
import {
  ContainerTag,
  hasTagFilter,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import {
  ALL_KEY,
  ANY_KEY,
  SEARCH_TAGS_KEY,
  TOP_TAGS_KEY,
} from '@integration-fontends/integration/ui/attachment-selector/i18n';

export interface TopTagsFiltersComponentProps {
  searchParams: SearchParameters;
  tags: ContainerTag[];
  onTagToggle: (tag: ContainerTag) => void;
  onOperatorChange: (operator: SearchParameters['tagFilters']['operator']) => void;
}

export function TopTagsFiltersComponent({
  searchParams,
  tags,
  onTagToggle,
  onOperatorChange,
}: TopTagsFiltersComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const [search, setSearch] = useState('');

  return (
    <fieldset data-testid="top-tags-filters">
      <SectionHeader>
        <div className="flex justify-between">
          <legend className="text-primary">{t(TOP_TAGS_KEY)}</legend>

          <div className="flex items-center">
            <span>{t(ANY_KEY)}</span>
            <ToggleSwitch
              className="mx-sm"
              checked={searchParams?.tagFilters?.operator === 'AND'}
              onChange={() =>
                onOperatorChange(searchParams?.tagFilters?.operator === 'AND' ? 'OR' : 'AND')
              }
            />
            <span>{t(ALL_KEY)}</span>
          </div>
        </div>
      </SectionHeader>

      <SectionContent className="pb-lg">
        <CheckboxListContainer className="mb-xl">
          {tags
            .filter((tag) => search === '' || tag.name.toLowerCase().includes(search.toLowerCase()))
            .sort((tag1, tag2) => tag2.count - tag1.count)
            .slice(0, 10)
            .map((tag) => (
              <CheckboxListItemContainer key={tag.id}>
                <div
                  data-testid="tag-filter"
                  className="flex justify-between gap-xs cursor-pointer"
                  onClick={() => onTagToggle(tag)}
                >
                  <div className="min-w-0 flex items-center">
                    <BFCheckbox
                      value={tag.id}
                      id={tag.id}
                      className="flex-grow"
                      checked={hasTagFilter(searchParams, tag)}
                      style={{ marginRight: 10 }}
                      label={tag.name}
                      readOnly
                    />
                  </div>

                  <span className="text-gray">{tag.count}</span>
                </div>
              </CheckboxListItemContainer>
            ))}
        </CheckboxListContainer>

        <BFSearchInput
          className="w-full"
          size={InputSize.Small}
          placeholder={t(SEARCH_TAGS_KEY)}
          onChange={(e) => setSearch(e.target.value)}
        />
      </SectionContent>
    </fieldset>
  );
}
