import { DEFAULT_DATE_FORMAT } from '@integration-frontends/core';
import { Attachment } from '@integration-frontends/integration/core/model';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '../../../common/layout';
import { CREATED_ON_KEY, DETAILS_KEY, LAST_UPDATED_ON_KEY } from '../../i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';

export interface AssetDetailsProps {
  attachment: Attachment;
}

export function AttachmentDetails({ attachment }: AssetDetailsProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <div>
      <SectionTitle>{t(DETAILS_KEY)}</SectionTitle>
      <div className="flex flex-col">
        <span>
          {t(LAST_UPDATED_ON_KEY)} {format(attachment.updatedAt, DEFAULT_DATE_FORMAT)}
        </span>
        <span>
          {t(CREATED_ON_KEY)} {format(attachment.createdAt, DEFAULT_DATE_FORMAT)}
        </span>
      </div>
    </div>
  );
}
