import { IconCaretDown } from '@integration-frontends/common/ui';
import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import './common.scss';

export function SectionContent({ className, ...props }: HTMLAttributes<any>) {
  return <div className={classNames('section-content', className)} {...props} />;
}

export function ExpandableSectionIcon({ expanded }: { expanded: boolean }) {
  return expanded ? (
    <IconCaretDown className="cursor-pointer" style={{ transform: 'rotate(180deg)' }} />
  ) : (
    <IconCaretDown className="cursor-pointer" />
  );
}

export function SectionHeader(props: HTMLAttributes<any>) {
  return <div className="mt-lg mb-md section-header" {...props} />;
}

export function CheckboxListContainer({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return <div className={classNames('flex flex-wrap justify-between', className)} {...props} />;
}

export function CheckboxListItemContainer({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames('min-w-0', className)} style={{ flex: '0 0 48%' }} {...props} />
  );
}
