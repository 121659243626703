import { assetTagEntitySelectors } from '@integration-frontends/integration/core/application';
import { Asset } from '@integration-frontends/integration/core/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tags } from '../../common/tags';
import { TAGS_KEY } from '../../i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import {
  PageSection,
  PageSectionContent,
} from '@integration-fontends/integration/ui/attachment-selector/common/layout/page';

export interface TagsProps {
  asset: Asset;
}

export function AssetTags({ asset }: TagsProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const tags = useSelector(assetTagEntitySelectors.byAssetId(asset.id));

  return (
    <>
      {tags.length > 0 && (
        <PageSection title={t(TAGS_KEY)}>
          <PageSectionContent>
            <Tags tags={tags} />
          </PageSectionContent>
        </PageSection>
      )}
    </>
  );
}
