import {
  Asset, Attachment,
  Container,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';

export class BrandfolderWebsiteServiceMock implements IBrandfolderWebsiteService {
  getAssetPageUrl(container: Container, asset: Asset): string {
    return `/${container.id}/${asset.id}`;
  }

  getAttachmentPageUrl(container: Container, attachment: Attachment): string {
    return '';
  }

  getContainerUrl(container: Container): string {
    return `/${container.id}`;
  }

  getFileThumbnailUrl(extension: string): string {
    return `/${extension}`;
  }
}
