import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '@integration-frontends/common/utils/redux';

export const createNotification = createAction(
  'CREATE_NOTIFICATION',
  withPayloadType<{
    message: string,
    location: string,
  }>()
);
export const closeNotification = createAction('CLOSE_NOTIFICATION', withPayloadType<{position: number}>());
