import {
  IntegrationEntitiesSelectors,
  SearchAssetsSelectors,
} from '@integration-frontends/integration/core/application';
import { all, fork } from 'redux-saga/effects';
import { clearSearchEffects } from './clear-search';
import { initEffects } from './init';
import { paginationSectionEffects } from './pagination-by-section';
import { paginationContainerEffects } from './pagination-by-container';
import { searchEffects } from './search';

export function* searchAssetsEffects(
  searchAssetsSelectors: SearchAssetsSelectors,
  entitiesSelectors: IntegrationEntitiesSelectors,
) {
  yield all([
    fork(clearSearchEffects),
    fork(initEffects),
    fork(paginationContainerEffects, searchAssetsSelectors),
    fork(paginationSectionEffects, searchAssetsSelectors),
    fork(searchEffects, searchAssetsSelectors, entitiesSelectors),
  ]);
}
