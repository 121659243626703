import placeBackground from '@integration-frontends/assets/place-background-icon.svg';
import React from 'react';
import classNames from 'classnames';
import { createIcon } from './create-icon';

export const IconPlaceBackgroundInactive = createIcon(
  <img
    src={placeBackground}
    alt="Place to background"
    style={{
      filter:
        'invert(84%) sepia(97%) saturate(0%) hue-rotate(181deg) brightness(88%) contrast(83%)',
    }}
  />,
);

export const IconPlaceBackgroundActive = createIcon(
  <img src={placeBackground} alt="Place to background" />,
);

export function IconPlaceBackground({ active }) {
  return (
    <div className={classNames('flex items-center cursor-pointer, className')}>
      {active ? <IconPlaceBackgroundActive /> : <IconPlaceBackgroundInactive />}
    </div>
  );
}
