import { filterBy } from '@integration-frontends/common/utils/collection';
import {
  all,
  any,
  compose,
  dissoc,
  equals,
  groupBy,
  isEmpty,
  isNil,
  juxt,
  merge,
  mergeDeepRight,
  prop,
} from 'ramda';
import { Container, ContainerCustomField, ContainerTag } from '../../container';
import { AttachmentOrientation, AttachmentUploadDate } from '../attachment';
import { Label } from '../label';
import { ResourceBase, ResourceType } from '../resource-base';
import { SearchFilter } from '../search-filter';

export enum AssetStatus {
  Approved = 'APPROVED',
  Unapproved = 'UNAPPROVED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
}

export interface Asset extends ResourceBase {
  type: ResourceType.ASSET;
  sectionId: string;
  thumbnailUrl: string;
  attachmentCount: number;
  collectionId?: string;
  description?: string;
  availabilityEnd?: Date;
}

export const filterBySection = filterBy('sectionId');
export const filterByContainer = (container: Container) => {
  return (asset: Asset) => {
    return container?.type === ResourceType.BRANDFOLDER || asset.collectionId === container?.id;
  };
};
export const groupBySection = groupBy<Asset>(prop('sectionId'));
export const ASSET_REPO_TOKEN = Symbol.for('ASSET_REPO');

export interface CustomFieldFilter {
  customField: ContainerCustomField;
  value: string;
}

export interface SearchParameters {
  query?: string;
  customFieldFilters?: {
    [id: string]: CustomFieldFilter;
  };
  tagFilters?: {
    operator: 'AND' | 'OR';
    tags: ContainerTag[];
  };
  fileTypeFilters?: {
    fileTypes: string[];
  };
  labelFilter?: Label;
  assetStatusFilter?: AssetStatus;
  orientationFilters?: AttachmentOrientation[];
  skuFilters?: string[];
  uploadDateFilter?: {
    uploadDateEnum: AttachmentUploadDate;
    dateStart?: Date;
    dateEnd?: Date;
  };
  pinnedSearch?: SearchFilter;
  pageSize?: number;
  groupBy?: string;
}

export function createSearchParams(): SearchParameters {
  return createDefaultTagFilters({});
}

export const EMPTY_SEARCH_PARAMS = createSearchParams();

export function hasFilters(searchParams: SearchParameters): boolean {
  return (
    !!searchParams &&
    compose(
      any(Boolean),
      juxt([
        hasAssetStatusFilter,
        hasCustomFieldFilters,
        hasOrientationFilters,
        hasQueryFilter,
        hasSkuFilters,
        hasTagFilters,
        hasFileTypeFilters,
        hasUploadDateFilter,
        hasPinnedSearchFilter,
      ]),
    )(searchParams)
  );
}

export function areSearchParamsEqual(
  searchParams1: SearchParameters,
  searchParams2: SearchParameters,
): boolean {
  return all(
    Boolean,
    [
      areQueryFiltersEqual,
      areAssetStatusFiltersEqual,
      areOrientationFiltersEqual,
      areSkuFiltersEqual,
      areTagFiltersEqual,
      areUploadDateFiltersEqual,
      areCustomFieldFiltersEqual,
      areFileTypeFiltersEqual,
      areLabelFiltersEqual,
      arePinnedSearchFiltersEqual,
    ].map((fn) => fn(searchParams1, searchParams2)),
  );
}

function createFilterEqualityCheckFunction<T = SearchParameters[keyof SearchParameters]>(
  filterSelector: (searchParams: SearchParameters) => T,
  equalityOverride?: (filter1: T, filter2: T) => boolean,
) {
  return (searchParams1: SearchParameters, searchParams2: SearchParameters) =>
    equalityOverride
      ? equalityOverride(filterSelector(searchParams1), filterSelector(searchParams2))
      : equals(filterSelector(searchParams1), filterSelector(searchParams2));
}

export function getQueryFilter(searchParams: SearchParameters): SearchParameters['query'] {
  return searchParams?.query;
}

export const areQueryFiltersEqual = createFilterEqualityCheckFunction(getQueryFilter);

export function setQueryFilter(query: string, searchParams: SearchParameters): SearchParameters {
  return removePinnedSearchFilter({
    ...searchParams,
    query,
  });
}

export function hasQueryFilter(searchParams: SearchParameters): boolean {
  return !!searchParams?.query;
}

export function getAssetStatusFilters(
  searchParams: SearchParameters,
): SearchParameters['assetStatusFilter'] {
  return searchParams?.assetStatusFilter;
}

export const areAssetStatusFiltersEqual = createFilterEqualityCheckFunction(getAssetStatusFilters);

export function setAssetStatusFilter(
  searchParams: SearchParameters,
  status: AssetStatus,
): SearchParameters {
  return merge(searchParams, { assetStatusFilter: status });
}

export function hasAssetStatusFilter(searchParams: SearchParameters): boolean {
  return !isNil(searchParams.assetStatusFilter);
}

export function getOrientationFilters(
  searchParams: SearchParameters,
): SearchParameters['orientationFilters'] {
  return searchParams?.orientationFilters;
}

export const areOrientationFiltersEqual = createFilterEqualityCheckFunction(getOrientationFilters);

export function addOrientationFilter(
  searchParams: SearchParameters,
  orientation: AttachmentOrientation,
): SearchParameters {
  return {
    ...searchParams,
    orientationFilters: (searchParams?.orientationFilters || []).concat(orientation),
  };
}

export function removeOrientationFilter(
  searchParams: SearchParameters,
  orientation: AttachmentOrientation,
): SearchParameters {
  return {
    ...searchParams,
    orientationFilters: (searchParams?.orientationFilters || []).filter(
      (oFilter) => oFilter !== orientation,
    ),
  };
}

export function hasOrientationFilters(searchParams: SearchParameters): boolean {
  return searchParams?.orientationFilters?.length > 0;
}

export function getSkuFilters(searchParams: SearchParameters): SearchParameters['skuFilters'] {
  return searchParams?.skuFilters;
}

export const areSkuFiltersEqual = createFilterEqualityCheckFunction(getSkuFilters);

export function setSkuFilters(
  searchParams: SearchParameters,
  skuFilters: string[],
): SearchParameters {
  return {
    ...searchParams,
    skuFilters,
  };
}

export function hasSkuFilters(searchParams: SearchParameters): boolean {
  return searchParams?.skuFilters?.length > 0;
}

export function getUploadDateFilter(
  searchParams: SearchParameters,
): SearchParameters['uploadDateFilter'] {
  return searchParams?.uploadDateFilter;
}

export const areUploadDateFiltersEqual = createFilterEqualityCheckFunction(getUploadDateFilter);

export function setUploadDateFilter(
  searchParams: SearchParameters,
  uploadDateFilter: SearchParameters['uploadDateFilter'],
): SearchParameters {
  return {
    ...searchParams,
    uploadDateFilter,
  };
}

export function hasUploadDateFilter(searchParams: SearchParameters): boolean {
  return !!searchParams?.uploadDateFilter;
}

export function getUploadStartDate(searchParams: SearchParameters): Date | null {
  return searchParams?.uploadDateFilter?.dateStart
    ? new Date(searchParams?.uploadDateFilter?.dateStart)
    : null;
}

export function getUploadEndDate(searchParams: SearchParameters): Date | null {
  return searchParams?.uploadDateFilter?.dateEnd
    ? new Date(searchParams?.uploadDateFilter?.dateEnd)
    : null;
}

export function getTagFilters(searchParams: SearchParameters): SearchParameters['tagFilters'] {
  return searchParams?.tagFilters;
}

export const areTagFiltersEqual = createFilterEqualityCheckFunction(
  getTagFilters,
  (filter1, filter2) => {
    if (!filter1?.tags?.length && !filter2?.tags?.length) return true;
    return equals(filter1, filter2);
  },
);

export function createDefaultTagFilters(searchParams: SearchParameters): SearchParameters {
  return {
    ...searchParams,
    tagFilters: {
      operator: 'OR',
      tags: [],
    },
  };
}

export function addTagFilterTag(
  searchParams: SearchParameters,
  tag: ContainerTag,
): SearchParameters {
  searchParams = searchParams || {};
  return merge(searchParams, {
    tagFilters: merge(searchParams.tagFilters, {
      tags: Array.from(new Set(searchParams?.tagFilters?.tags || []).add(tag)),
    }),
  });
}

export function removeTagFilterTag(
  searchParams: SearchParameters,
  tag: ContainerTag,
): SearchParameters {
  return merge(searchParams, {
    tagFilters: {
      ...searchParams.tagFilters,
      tags: searchParams?.tagFilters?.tags.filter((t) => t.id !== tag.id) || [],
    },
  });
}

export function setTagFilterOperator(
  searchParams: SearchParameters,
  operator: SearchParameters['tagFilters']['operator'],
): SearchParameters {
  return mergeDeepRight(searchParams, {
    tagFilters: {
      operator,
    },
  });
}

export function hasTagFilters(searchParams: SearchParameters): boolean {
  return searchParams?.tagFilters?.tags?.length > 0;
}

export function hasTagFilter(searchParams: SearchParameters, tag: ContainerTag): boolean {
  return !!searchParams?.tagFilters?.tags?.find((t) => t.id === tag.id);
}

export function getCustomFieldFilter(
  searchParams: SearchParameters,
): SearchParameters['customFieldFilters'] {
  return searchParams?.customFieldFilters;
}

export const areCustomFieldFiltersEqual = createFilterEqualityCheckFunction(getCustomFieldFilter);

export function setCustomFieldFilter(
  searchParams: SearchParameters,
  customField: ContainerCustomField,
  value: string,
): SearchParameters {
  if (isEmpty(value) || isNil(value)) return removeCustomFieldFilter(searchParams, customField.id);

  return {
    ...searchParams,
    customFieldFilters: {
      ...(searchParams?.customFieldFilters || {}),
      [customField.id]: {
        customField,
        value,
      },
    },
  };
}

export function removeCustomFieldFilter(
  searchParams: SearchParameters,
  customFieldId: string,
): SearchParameters {
  const newCustomFieldFilters: SearchParameters['customFieldFilters'] = dissoc(
    customFieldId,
    searchParams.customFieldFilters,
  );

  return {
    ...searchParams,
    customFieldFilters: !isEmpty(newCustomFieldFilters) ? newCustomFieldFilters : null,
  };
}

export function hasCustomFieldFilters(searchParams: SearchParameters): boolean {
  return !!searchParams?.customFieldFilters;
}

export function getFileTypeFilters(
  searchParams: SearchParameters,
): SearchParameters['fileTypeFilters'] {
  return searchParams?.fileTypeFilters;
}

export const areFileTypeFiltersEqual = createFilterEqualityCheckFunction(getFileTypeFilters);

export function addFileTypeFilter(
  searchParams: SearchParameters,
  fileType: string,
): SearchParameters {
  return merge(searchParams, {
    fileTypeFilters: {
      fileTypes: Array.from(new Set(searchParams?.fileTypeFilters?.fileTypes || []).add(fileType)),
    },
  });
}

export function removeFileTypeFilter(
  searchParams: SearchParameters,
  fileType: string,
): SearchParameters {
  return merge(searchParams, {
    fileTypeFilters: {
      fileTypes: searchParams?.fileTypeFilters?.fileTypes.filter((t) => t !== fileType) || [],
    },
  });
}

export function hasFileTypeFilters(searchParams: SearchParameters): boolean {
  return searchParams?.fileTypeFilters?.fileTypes?.length > 0;
}

export function hasFileTypeFilter(searchParams: SearchParameters, fileType: string): boolean {
  return !!searchParams?.fileTypeFilters?.fileTypes?.includes(fileType);
}

export function getLabelFilter(searchParams: SearchParameters): SearchParameters['labelFilter'] {
  return searchParams?.labelFilter;
}

export const areLabelFiltersEqual = createFilterEqualityCheckFunction(getLabelFilter);

export function hasLabelFilter(searchParams: SearchParameters): boolean {
  return !!searchParams?.labelFilter;
}

export function setLabelFilter(label: Label, searchParams: SearchParameters): SearchParameters {
  return merge(searchParams, { labelFilter: label });
}

export function removeLabelFilter(searchParams: SearchParameters): SearchParameters {
  return merge(searchParams, { labelFilter: null });
}

export function getPinnedSearchFilter(
  searchParams: SearchParameters,
): SearchParameters['pinnedSearch'] {
  return searchParams?.pinnedSearch;
}

export const arePinnedSearchFiltersEqual = createFilterEqualityCheckFunction(getPinnedSearchFilter);

export function hasPinnedSearchFilter(searchParams: SearchParameters): boolean {
  return !!searchParams?.pinnedSearch;
}

export function setPinnedSearchFilter(
  filter: SearchFilter,
  searchParams: SearchParameters,
): SearchParameters {
  return merge(createSearchParams(), { pinnedSearch: filter });
}

export function removePinnedSearchFilter(searchParams: SearchParameters): SearchParameters {
  return merge(searchParams, { pinnedSearch: null });
}

export function filterCount(searchParams: SearchParameters) {
  let filterCount = 0;

  if (searchParams?.customFieldFilters?.length) {
    filterCount += 1;
  }

  if (searchParams?.tagFilters?.tags.length) {
    filterCount += 1;
  }

  if (searchParams?.fileTypeFilters?.fileTypes?.length) {
    filterCount += 1;
  }

  if (searchParams?.labelFilter) {
    filterCount += 1;
  }

  if (searchParams?.assetStatusFilter) {
    filterCount += 1;
  }

  if (searchParams?.assetStatusFilter) {
    filterCount += 1;
  }

  if (searchParams?.orientationFilters?.length) {
    filterCount += 1;
  }

  if (searchParams?.skuFilters?.length) {
    filterCount += 1;
  }

  if (searchParams?.uploadDateFilter) {
    filterCount += 1;
  }

  return filterCount;
}
