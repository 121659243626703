import {
    loginThunk,
  } from '@integration-frontends/common/auth/core/application';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
  
  
const getHashParam = (name) => {
    var param = decodeURI((RegExp(name + '=' + '(.+?)(&|$)').exec(location.hash) || [null])[1]);
    return param == 'undefined' ? undefined : param;
};

export function OauthCallbackCode() {
    // Receive implicit auth access_token
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loginThunk(getHashParam('access_token')))
    }, []);

    return null;
}