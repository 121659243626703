import { BFHR } from '@integration-frontends/common/ui';
import { assetCustomFieldValueEntitySelectors } from '@integration-frontends/integration/core/application';
import { Attachment } from '@integration-frontends/integration/core/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SectionTitle } from '../../../common/layout';
import { AssetCustomFieldValues } from '../../common/asset-custom-field-values';
import { CUSTOM_FIELDS_KEY } from '../../i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';

export interface CustomFieldsProps {
  attachment: Attachment;
}

export function CustomFields({ attachment }: CustomFieldsProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const customFieldValues = useSelector(
    assetCustomFieldValueEntitySelectors.byAssetId(attachment.assetId),
  );

  return (
    <>
      {customFieldValues.length > 0 && (
        <>
          <div className="mb-md">
            <SectionTitle>{t(CUSTOM_FIELDS_KEY)}</SectionTitle>
            <div>
              <AssetCustomFieldValues customFieldValues={customFieldValues} />
            </div>
          </div>
          <BFHR className="mb-md" />
        </>
      )}
    </>
  );
}
