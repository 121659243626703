import { RouteComponentProps } from 'react-router-dom';
import { AttachmentSelector } from '../../../libs/integration/ui/src';
import { Header } from './header';
import * as React from 'react';
import { useState } from 'react';

export function SelectAttachment(routeProps: RouteComponentProps) {
  const [containerId, setContainerId] = useState(null);
  const [assetId, setAssetId] = useState(null);

  return (
    <>
      <Header containerId={containerId} assetId={assetId} />
      <div id="attachment-selector-container" className="overflow-y-hidden h-full">
        <AttachmentSelector
          {...routeProps}
          containerSelect={setContainerId}
          assetSelect={setAssetId}
        />
      </div>
    </>
  );
}
