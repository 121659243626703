import {
  Asset,
  Attachment,
  Brandfolder,
  Collection,
  Container,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';

export class BrandfolderWebsiteService implements IBrandfolderWebsiteService {
  constructor(private baseUrl: string, private staticAssetBaseUrl: string) {}

  getContainerUrl = (container: Container): string => {
    return `${this.baseUrl}/${container.slug}`;
  };

  getAssetPageUrl = (brandfolder: Brandfolder, asset: Asset): string => {
    return `${this.baseUrl}/${brandfolder.slug}#!asset/${asset.id}`;
  };

  getFileThumbnailUrl = (extension: string): string => {
    return `${this.staticAssetBaseUrl}/assets/types/${extension ?? 'file'}.svg`;
  };

  getAttachmentPageUrl = (container: Container, attachment: Attachment): string => {
    return `${this.baseUrl}/${container.slug}#!attachment/${attachment.id}`;
  };
}
