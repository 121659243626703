import {Container, ResourceBase, ResourceType} from "@integration-frontends/integration/core/model";

export interface SearchFilter extends ResourceBase {
  type: ResourceType.SEARCH_FILTER;
  label: string;
  query: string;
  featured: boolean;
  containerId: string;
}

export const SEARCH_FILTER_REPO_TOKEN = 'SEARCH_FILTER_REPO';
export interface ISearchFilterRepo {
  listContainerSearchFilters: (container: Container) => Promise<SearchFilter[]>;
}
