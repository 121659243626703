import { DI_CONTAINER } from '@integration-frontends/core';
import {
  IMediaTypeSupportService,
  MediaType,
  OPENABLE_MEDIA_TYPES_TOKEN,
  SUPPORTED_MEDIA_TYPES_TOKEN,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';

@injectable()
export class MediaTypeSupportService implements IMediaTypeSupportService {
  getSupportedMediaTypes = (): Promise<MediaType[]> => {
    return new Promise((resolve) => {
      return resolve(DI_CONTAINER.get(SUPPORTED_MEDIA_TYPES_TOKEN));
    });
  };

  isSupported = (mediaType: MediaType): Promise<boolean> => {
    const supportedMediaTypes: MediaType[] = DI_CONTAINER.get(SUPPORTED_MEDIA_TYPES_TOKEN);
    return new Promise((resolve) => {
      return resolve(!!supportedMediaTypes.includes(mediaType));
    });
  };

  canBeOpened = (mediaType: MediaType): Promise<boolean> => {
    const openableMediaTypes: MediaType[] = DI_CONTAINER.get(OPENABLE_MEDIA_TYPES_TOKEN);
    return new Promise((resolve) => {
      return resolve(!!openableMediaTypes.includes(mediaType));
    });
  };
}
