import React from 'react';
import { Attachment } from '@integration-frontends/integration/core/model';
import { PlacementOptions } from '@integration-fontends/integration/ui/attachment-selector/components/asset-details-page/placement-options/placement-options';
import { useAttachmentTransforms } from '@integration-fontends/integration/ui/attachment-selector/common/attachment-transforms/use-attachment-transforms';

export interface PlacementOptionsContainerProps {
  attachment: Attachment;
}

export function PlacementOptionsContainer({ attachment }: PlacementOptionsContainerProps) {
  const { modifiedAttachment, mediaTypeChanged, resized, selectedMediaType, userResizingOptions } =
    useAttachmentTransforms({ attachment });

  return (
    <>
      {attachment && (
        <PlacementOptions
          attachment={modifiedAttachment || attachment}
          onMediaTypeChange={mediaTypeChanged}
          onResize={resized}
          selectedMediaType={selectedMediaType}
          userResizingOptions={userResizingOptions}
        />
      )}
    </>
  );
}
