import { filterBy } from '@integration-frontends/common/utils/collection';
import { Container } from '../container';
import { ResourceBase, ResourceType } from './resource-base';

export interface Section extends ResourceBase {
  type: ResourceType.SECTION;
  brandfolderId: string;
  assetCount?: number;
  name: string;
}

export const filterByBrandfolder = filterBy('brandfolderId');
export const filterSectionsByContainer = (container: Container) => (section: Section) =>
  container.type === ResourceType.BRANDFOLDER
    ? container.id === section.brandfolderId
    : container.sectionIds?.includes(section.id);

export const SECTION_REPO_TOKEN = Symbol.for('SECTION_REPO');

export interface ISectionRepo {
  listBrandfolderSections: (brandfolderId: string) => Promise<Section[]>;
  listCollectionSections: (collectionId: string) => Promise<Section[]>;
  listContainerSections: (container: Container) => Promise<Section[]>;
}
