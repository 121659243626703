import {
  BFButton,
  ButtonSize,
  ButtonType,
  IconPin,
  IconPinActive,
  IconSize,
} from '@integration-frontends/common/ui';
import {
  searchAssetsSelectors,
  searchFilterEntitySelectors,
} from '@integration-frontends/integration/core/application';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { INTEGRATION_COMMON_NAMESPACE } from '../../../common/i18n';

export interface PinsSelectorToggleProps {
  open: boolean;
}

export function PinsSelectorToggle({
  open,
  ...props
}: PinsSelectorToggleProps & HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const container = useSelector(searchAssetsSelectors.container);
  const searchFilters = useSelector(searchFilterEntitySelectors.byContainer(container));
  const searchParams = useSelector(searchAssetsSelectors.searchParams);

  return (
    <div className="pins-button-wrapper" {...props}>
      <BFButton
        buttonType={
          searchParams && searchParams.pinnedSearch ? ButtonType.ActiveDark : ButtonType.Secondary
        }
        className="pins-button"
        size={ButtonSize.Small}
        ariaLabel="Open Pinned Searches"
        hasIcons={true}
      >
        <div className="pins-button-content">
          {searchFilters.length}
          {searchParams && searchParams.pinnedSearch ? (
            <IconPinActive iconSize={IconSize.Small} />
          ) : (
            <IconPin iconSize={IconSize.Small} />
          )}
        </div>
      </BFButton>
    </div>
  );
}
