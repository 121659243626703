import { StateSelector } from '@integration-frontends/common/utils/redux';
import { createAssetCustomFieldValueSelectors } from '@integration-frontends/integration/core/application/common/entities-state/selectors/asset-custom-field-value';
import { createSearchFilterSelectors } from '@integration-frontends/integration/core/application/common/entities-state/selectors/search-filter';
import { createSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';
import { IntegrationEntitiesState } from '../model';
import { createAssetSelectors } from './asset';
import { createAssetTagSelectors } from './asset-tag';
import { createAttachmentSelectors } from './attachment';
import { createBrandfolderSelectors } from './brandfolder';
import { createBrandfolderTagSelectors } from './brandfolder-tag';
import { createCollectionSelectors } from './collection';
import { createContainerSelectors } from './container';
import { createContainerCustomFieldSelectors } from './container-custom-field';
import { createContainerFileTypeAggregateSelectors } from './container-file-type-aggregate';
import { createContainerTagSelectors } from './container-tag';
import { createLabelSelectors } from './label';
import { createOrganizationSelectors } from './organization';
import { createSectionSelectors } from './section';

export type IntegrationEntitiesStateSelector = StateSelector<IntegrationEntitiesState>;

export const createIntegrationEntitiesSelectors = (
  stateSelector: IntegrationEntitiesStateSelector,
) => {
  const attachmentSelectors = createAttachmentSelectors(
    createSelector(stateSelector, prop('attachments')),
  );
  const assetSelectors = createAssetSelectors(
    createSelector(stateSelector, prop('assets')),
    attachmentSelectors,
  );
  const brandfolderSelectors = createBrandfolderSelectors(
    createSelector(stateSelector, prop('brandfolders')),
  );
  const brandfolderTagSelectors = createBrandfolderTagSelectors(
    createSelector(stateSelector, prop('brandfolderTags')),
  );
  const collectionSelectors = createCollectionSelectors(
    createSelector(stateSelector, prop('collections')),
  );
  const containerCustomFieldsSelectors = createContainerCustomFieldSelectors(
    createSelector(stateSelector, prop('containerCustomFields')),
  );
  const containerFileTypeAggregateSelectors = createContainerFileTypeAggregateSelectors(
    createSelector(stateSelector, prop('containerFileTypeAggregates')),
  );
  const containerTagSelectors = createContainerTagSelectors(
    createSelector(stateSelector, prop('containerTags')),
  );
  const organizationSelectors = createOrganizationSelectors(
    createSelector(stateSelector, prop('organizations')),
  );
  const sectionSelectors = createSectionSelectors(createSelector(stateSelector, prop('sections')));

  return {
    asset: assetSelectors,
    assetCustomFieldValue: createAssetCustomFieldValueSelectors(
      createSelector(stateSelector, prop('assetCustomFieldValues')),
    ),
    assetTag: createAssetTagSelectors(createSelector(stateSelector, prop('assetTags'))),
    attachment: attachmentSelectors,
    brandfolder: brandfolderSelectors,
    brandfolderTags: brandfolderTagSelectors,
    collection: collectionSelectors,
    container: createContainerSelectors(
      assetSelectors,
      brandfolderSelectors,
      collectionSelectors,
      sectionSelectors,
      containerCustomFieldsSelectors,
      containerFileTypeAggregateSelectors,
      containerTagSelectors,
      organizationSelectors,
    ),
    containerCustomFields: containerCustomFieldsSelectors,
    containerFileTypeAggregates: containerFileTypeAggregateSelectors,
    containerTags: containerTagSelectors,
    label: createLabelSelectors(createSelector(stateSelector, prop('labels'))),
    organization: organizationSelectors,
    searchFilter: createSearchFilterSelectors(createSelector(stateSelector, prop('searchFilters'))),
    section: sectionSelectors,
  };
};

export type IntegrationEntitiesSelectors = ReturnType<typeof createIntegrationEntitiesSelectors>;
