import { StateSelector } from '@integration-frontends/common/utils/redux';
import { Organization } from '@integration-frontends/integration/core/model';
import { EntityState } from '@reduxjs/toolkit';
import { organizationAdapter } from '../entities';

export function createOrganizationSelectors(
  stateSelector: StateSelector<EntityState<Organization>>,
) {
  return organizationAdapter.getSelectors(stateSelector);
}
