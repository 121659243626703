import { StateSelector } from '@integration-frontends/common/utils/redux';
import { brandfolderTagAdapter } from '@integration-frontends/integration/core/application/common/entities-state/entities';
import { BrandfolderTag } from '@integration-frontends/integration/core/model';
import { createSelector, EntityState } from '@reduxjs/toolkit';

export function createBrandfolderTagSelectors(
  stateSelector: StateSelector<EntityState<BrandfolderTag>>,
) {
  const baseSelectors = brandfolderTagAdapter.getSelectors(stateSelector);

  return {
    ...baseSelectors,
    brandfolderTagsByBrandfolderId: (brandfolderId: string) =>
      createSelector(baseSelectors.selectAll, (tags) =>
        tags.filter((tag) => tag.brandfolderId === brandfolderId),
      ),
  };
}
