export enum FileType {
  Ai = 'AI',
  Pdf = 'PDF',
  Zip = 'ZIP',
}

export const ALL_FILE_TYPES = Object.values(FileType);
export const FILE_TYPE_EXTENSIONS = {
  [FileType.Ai]: ['ai'],
  [FileType.Pdf]: ['pdf'],
  [FileType.Zip]: ['zip'],
};

export const FILE_TYPE_MIME_TYPES = {
  [FileType.Ai]: ['application/vnd.adobe.illustrator'],
  [FileType.Pdf]: ['application/pdf'],
  [FileType.Zip]: ['application/zip'],
};

export function getFileTypeFromMimeType(mimeType: string): FileType {
  let record: [string, string[]];
  if (mimeType !== null) {
    record = Object.entries(FILE_TYPE_MIME_TYPES).find(([, mimeTypes]) =>
      mimeTypes.includes(mimeType.toLocaleLowerCase()),
    );
  }

  return (record?.[0] as FileType) || null;
}
