import { all, call, put, takeEvery } from 'typed-redux-saga';
import {
  brandfolderEntityActions,
  collectionEntityActions,
  orgLandingPageDataLoaded,
  orgLandingPageEntered,
} from '@integration-frontends/integration/core/application';
import {
  BRANDFOLDER_REPO_TOKEN,
  COLLECTION_REPO_TOKEN,
  IBrandfolderRepo,
  ICollectionRepo,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoaded } from '@integration-frontends/common/app';

function* handler() {
  const brandfolderRepo: IBrandfolderRepo = DI_CONTAINER.get(BRANDFOLDER_REPO_TOKEN);
  const collectionRepo: ICollectionRepo = DI_CONTAINER.get(COLLECTION_REPO_TOKEN);

  const [brandfolders, collections] = yield all([
    call(brandfolderRepo.listBrandfolders),
    call(collectionRepo.listCollections),
  ]);

  yield put(brandfolderEntityActions.brandfoldersReceived(brandfolders));
  yield put(collectionEntityActions.collectionsReceived(collections));
  yield put(orgLandingPageDataLoaded());
  yield put(pageLoaded());
}

export function* enteredOrgLandingPageEffects() {
  yield takeEvery(orgLandingPageEntered, handler);
}
