export class AuthError implements Error {
  constructor(public name: string, public message: string) {}
}

export function isAuthError(result: unknown): result is AuthError {
  return result instanceof AuthError;
}

export class CredentialsInvalidError extends AuthError {
  constructor() {
    super('CREDENTIALS_INVALID', 'Invalid credentials');
  }
}
