import { DI_CONTAINER } from '@integration-frontends/core';
import {
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';
import { takeEvery } from 'redux-saga/effects';
import { visitBrandfolder } from '../actions';

const handler = function* (action: ReturnType<typeof visitBrandfolder>) {
  const brandfolderWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );

  window.open(brandfolderWebsiteService.getContainerUrl(action.payload.container), '_blank');
  yield;
};

export function* visitBrandfolderEffects() {
  yield takeEvery(visitBrandfolder, handler);
}
