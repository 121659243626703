import { all, fork } from 'redux-saga/effects';
import { enteredAssetDetailsEffects } from './entered-asset-details';
import { attachmentSelectedEffects } from './attachment-selected';
import { goToAttachmentDetailsEffects } from './entered-attachment-details';
import { goToAssetDetailsEffects } from './go-to-asset-details';
import { initEffects } from './init';
import { endAttachmentSelectionEffects } from './end-attachment-selection';
import { IntegrationEntitiesSelectors } from '@integration-frontends/integration/core/application';
import { initiateAttachmentActionClickedEffects } from './attachment-action-clicked';
import { enteredOrgLandingPageEffects } from '@integration-frontends/integration/core/application/select-attachment/state/effects/entered-org-landing-page';
import {
  showPageEffects
} from '@integration-frontends/integration/core/application/select-attachment/state/effects/show-page';

export function* selectAttachmentEffects(entitiesSelectors: IntegrationEntitiesSelectors) {
  yield all([
    fork(initiateAttachmentActionClickedEffects),
    fork(attachmentSelectedEffects),
    fork(endAttachmentSelectionEffects),
    fork(enteredAssetDetailsEffects),
    fork(enteredOrgLandingPageEffects),
    fork(goToAttachmentDetailsEffects, entitiesSelectors),
    fork(goToAssetDetailsEffects),
    fork(initEffects),
    fork(showPageEffects)
  ]);
}
