export enum StorageMethods {
  GetItem = 'getItem',
  SetItem = 'setItem',
}

const myLocalStorage = {
  storage: {},
  getItem(key: string): string | null {
    return this.storage[key] || null;
  },

  setItem(key: string, value: string): string {
    return (this.storage[key] = value);
  }
};

export const useStorage = (func: StorageMethods, key: string, value?: string ): string | null => {
  try {
    return window.localStorage[func](key, value) || null;
  } catch (error) {
    console.log(error);
    return myLocalStorage[func](key, value);
  }
};
