import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import './badges.scss';

export enum BadgeType {
  Primary,
  Secondary,
}

export enum BadgeSize {
  Small,
  Medium,
}

export interface PillBadgeProps {
  badgeType?: BadgeType;
  badgeSize?: BadgeSize;
}

const BADGE_WIDTHS = {
  [BadgeSize.Small]: 33,
  [BadgeSize.Medium]: 40,
};

export function PillBadge({
  style,
  children,
  badgeType,
  badgeSize,
  ...props
}: PillBadgeProps & HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      {...props}
      className={classNames(
        {
          'badge-primary': badgeType === BadgeType.Primary,
          'badge-secondary': badgeType === BadgeType.Secondary,
        },
        props.className,
      )}
      style={{ borderRadius: 4, height: 18, width: BADGE_WIDTHS[badgeSize], ...style }}
    >
      <div className="pill-badge-content h-full w-full flex items-center justify-center">
        {children}
      </div>
    </span>
  );
}
