import { createReducer } from '@reduxjs/toolkit';
import { initiatePlaceAttachment, placeAttachment } from './actions';

export interface PlaceAttachmentState {
  loading: boolean;
}

const initialState: PlaceAttachmentState = {
  loading: false,
};

export const PLACE_ATTACHMENT_REDUCER = createReducer(initialState, (builder) =>
  builder
    .addCase(initiatePlaceAttachment, (state) => {
      state.loading = true;
    })
    .addCase(placeAttachment, (state) => {
      state.loading = false;
    }),
);
