import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

interface BFPanelProps {
  border?: boolean;
}

export const BFPanel = ({ border = true, className, style, ...props }: BFPanelProps & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={classNames(
        'rounded-sm',
        {'border border-bf-light-gray': border },
        className
      )}
      style={style}
      {...props}
    />
  );
};
