// TODO: Generalize this component. Very similar to ./top-file-types-filters-container.tsx

import {
  addTagFilterTag,
  ContainerTag,
  createDefaultTagFilters,
  hasTagFilter,
  removeTagFilterTag,
  SearchParameters,
  setTagFilterOperator,
} from '@integration-frontends/integration/core/model';
import React, { FunctionComponent, useEffect } from 'react';
import {
  TopTagsFiltersComponent,
  TopTagsFiltersComponentProps,
} from '@integration-fontends/integration/ui/attachment-selector/components/advanced-filters/components/top-tag-filters/top-tags-filters-component';

export interface TopTagsFiltersContainerProps {
  searchParams: SearchParameters;
  tags: ContainerTag[];
  onChange: (searchParams: SearchParameters) => void;
  Component?: FunctionComponent<TopTagsFiltersComponentProps>;
}

export function TopTagsFiltersContainer({
  searchParams,
  tags,
  onChange,
  Component = TopTagsFiltersComponent,
}: TopTagsFiltersContainerProps) {
  useEffect(() => {
    if (!searchParams?.tagFilters) {
      onChange(createDefaultTagFilters(searchParams));
    }
  }, []);

  function toggleTag(tag: ContainerTag) {
    onChange(
      !hasTagFilter(searchParams, tag)
        ? addTagFilterTag(searchParams, tag)
        : removeTagFilterTag(searchParams, tag),
    );
  }

  function operatorChanged(operator: SearchParameters['tagFilters']['operator']) {
    onChange(setTagFilterOperator(searchParams, operator));
  }

  return (
    <Component
      searchParams={searchParams}
      tags={tags}
      onTagToggle={toggleTag}
      onOperatorChange={operatorChanged}
    />
  );
}
