import {
  AUTHENTICATE_SERVICE_TOKEN,
  Credentials,
  IAuthenticateService,
  isAuthError,
} from '@integration-frontends/common/auth/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { ThunkAction } from 'redux-thunk';
import {
  AuthActionTypes,
  loginAttempt,
  loginError,
  loginSuccess,
  logout
} from './actions';
import { AuthState } from './reducer';

export type AuthThunkAction<ReturnType = void> = ThunkAction<
  ReturnType,
  AuthState,
  unknown,
  AuthActionTypes
>;

export { clear, loginSuccess, logout } from './actions';

// TODO: switch to redux-sagas ~PP
export const loginThunk = (credentials: Credentials): AuthThunkAction => async (
  dispatch
) => {
  const authService: IAuthenticateService = DI_CONTAINER.get(
    AUTHENTICATE_SERVICE_TOKEN
  );

  dispatch(loginAttempt({ credentials }));

  const result = await authService.authenticate(credentials);
  isAuthError(result)
    ? dispatch(loginError({ error: result }))
    : dispatch(loginSuccess({ identity: result }));
};

export const logoutThunk = (): AuthThunkAction => async(dispatch) => {
  dispatch(logout());
}
