import { IconArrowRight, IconSize } from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  RESOURCE_BASE_SORT_OPTIONS,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import {
  getSortOptionsKey,
  INTEGRATION_COMMON_NAMESPACE,
} from '@integration-frontends/integration/ui/common/i18n';
import { SELECT_ATTACHMENT_OPTIONS_TOKEN, ISelectAttachmentOptions, } from '@integration-frontends/integration/core/application';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SORT_BY_LABEL_KEY } from '../../../../i18n';
import { SectionContent } from '../../common';
import { BFLabel } from '@integration-frontends/common/ui';
import './sorting-selector.scss';

export interface SortingSelectorProps {
  sortOptions: SortOptions;
  onChange?: (sortOptions: SortOptions) => void;
}

export function SortingSelector({ sortOptions, onChange }: SortingSelectorProps) {
  const { t: tAttachmentSelector } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const { t: tIntegration } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const [showOptions, setShowOptions] = useState(false);
  const { showAToZSorting = true }: ISelectAttachmentOptions = DI_CONTAINER.get(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );

  function sortOptionsSelected(options: SortOptions) {
    setShowOptions(false);
    onChange(options);
  }

  return (
    <div className="view-options-item z-200 py-sm relative">
      <SectionContent className="flex justify-between items-center gap-sm pd-0 sort-options-selector">
        <BFLabel>{tAttachmentSelector(SORT_BY_LABEL_KEY)}</BFLabel>
        <span
          data-testid="selected-sort-option"
          className="select selected-sort-option flex items-center cursor-pointer"
          onClick={() => setShowOptions(!showOptions)}
        >
          {tIntegration(getSortOptionsKey(sortOptions))}
        </span>
      </SectionContent>
      <div className={`sorting-selectors shadow-border mt-xxs ${showOptions === true ? 'visible' : 'invisible'}`}>
        {RESOURCE_BASE_SORT_OPTIONS.map((sortOptions) => {
          if (showAToZSorting || (!showAToZSorting && sortOptions?.field !== 'name')) {
            return (
              <SectionContent
                data-testid="sort-option"
                key={`${sortOptions?.field}_${sortOptions?.direction}`}
                className="option py-xs cursor-pointer"
                onClick={() => sortOptionsSelected(sortOptions)}
              >
                {tIntegration(getSortOptionsKey(sortOptions))}
              </SectionContent>
            )
          }
        })}
      </div>
    </div>
  );
}
