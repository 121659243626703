import {
  IOrganizationRepo,
  Organization,
  organizationsMockData,
} from '@integration-frontends/integration/core/model';
import { OrganizationOptions } from '@integration-frontends/common/brandfolder-api';

export class OrganizationRepoMock implements IOrganizationRepo {
  listOrganizations(options: OrganizationOptions | undefined): Promise<Organization[]> {
    return Promise.resolve(organizationsMockData);
  }
}
