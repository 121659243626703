import { withFormControlStyles } from '@integration-frontends/common/ui/forms/form-controls/common';
import React, { HTMLAttributes, SelectHTMLAttributes, useCallback, SetStateAction } from 'react';
import {Dispatch} from 'redux';
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import classNames from 'classnames';

export const MULTI_SELECT_COMPONENT_KEY = 'MULTI_SELECT';

export enum BFMultiSelectSize {
  Small = 's',
  Medium = 'm',
}

export type BFMultiSelectOption = {
  value: string | number;
}

export type BFMultiSelectProps = {
  options: BFMultiSelectOption[];
  selected: BFMultiSelectOption[];
  onChange: any;
  isClearable?: boolean;
  size?: BFMultiSelectSize;
  selectedOptions?: BFMultiSelectOption[];
  getOptionKey: (opton: BFMultiSelectOption) => string | number;
} & Omit<HTMLAttributes<HTMLElement>, 'onChange'> &
  Omit<SelectHTMLAttributes<HTMLElement>, 'onChange' | 'size'>;

// function DefaultMultiSelect({
//   className,
//   isClearable,
//   onOptionChange,
//   options,
//   placeholder,
//   selectedOptions,
//   size = BFMultiSelectSize.Small,
//   ...restProps
// }: BFMultiSelectProps) {
//   const internalOnChange = useCallback(
//     (e) => {
//       const value = e.target.value;
//       const selectedOption = options.find((opt) => opt.value.toString() === value.toString());
//       onOptionChange && onOptionChange(selectedOption.value.toString());
//     },
//     [onOptionChange, options],
//   );
//
//   return (
//     <select
//       className={classNames('bf-select', size, className)}
//       value={selectedOptions || []}
//       {...restProps}
//       onChange={internalOnChange}
//       multiple
//     >
//       {placeholder && <option value="">{placeholder}</option>}
//       {options.map((option) => (
//         <option data-testid={option} key={option} value={option}>
//           {option}
//         </option>
//       ))}
//     </select>
//   );
// }

export const BFMultiSelect = withFormControlStyles<BFMultiSelectProps>((props) => {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const Component = componentResolver.getComponent(MULTI_SELECT_COMPONENT_KEY);
  return <Component {...props} />;
});
