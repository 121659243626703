import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DI_CONTAINER } from '@integration-frontends/core';
import { SearchAssetsGroupBy } from '@integration-frontends/integration/core/application/search-assets/state/reducer';
import {
  ResourceBaseSortableProperty,
  SearchParameters,
  SortDirection,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import { BFLabel, BFSelect, ToggleSwitch } from '@integration-frontends/common/ui';
import {
  ISelectAttachmentOptions,
  searchAssetsActions,
  searchAssetsSelectors,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';
import { SortingSelector } from '../advanced-filters/components/sorting-selector/sorting-selector';
import { useAttachmentSelectorNavigation } from '../../navigation';
import {
  ASSETS_PER_KEY,
  ORGANIZE_BY_SECTION,
  SHOW_ATTACHMENTS_EXPAND_KEY,
  VIEW_OPTIONS_KEY,
} from '../../i18n';
import { SectionContent } from '../advanced-filters/common';
import { INTEGRATION_COMMON_NAMESPACE } from '../../../common/i18n';
import './view-options.scss';

const DEFAULT_SORT_OPTIONS: SortOptions<ResourceBaseSortableProperty> = {
  direction: SortDirection.Asc,
  field: ResourceBaseSortableProperty.Position,
};

export interface ViewOptionsDropDownProps {
  search;
  containerId;
  searchParams: SearchParameters;
  sortOptions: SortOptions<ResourceBaseSortableProperty>;
  onApply: (searchParams: SearchParameters, sortOptions: SortOptions) => void;
  responsive?: boolean;
  showAdvancedFiltersView?: boolean;
  showAttachments: boolean;
}

export function ViewOptionsDropDown({
  search,
  containerId,
  sortOptions = DEFAULT_SORT_OPTIONS,
  searchParams = {},
  onApply,
  responsive,
  showAdvancedFiltersView,
  showAttachments,
}: ViewOptionsDropDownProps) {
  const { customSortOptions, showAttachmentsToggleDisabled = false }: ISelectAttachmentOptions =
    DI_CONTAINER.get(SELECT_ATTACHMENT_OPTIONS_TOKEN);
  const [localSortOptions, setLocalSortOptions] = useState(sortOptions);
  const [selectedAssetNumber, setSelectedAssetNumber] = useState(16);
  const pageSize = useSelector(searchAssetsSelectors.pageSize);
  const groupBy = useSelector(searchAssetsSelectors.groupBy);
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const dispatch = useDispatch();

  const navigation = useAttachmentSelectorNavigation();

  function sortOptionsChanged(sortOptions: SortOptions<ResourceBaseSortableProperty>) {
    setLocalSortOptions(sortOptions);
    search(searchParams, sortOptions);
  }

  function onSetSelectedAssetNumber(pageSize: number) {
    setSelectedAssetNumber(pageSize);
    dispatch(searchAssetsActions.changePageSize({ pageSize }));
    onApply({ ...searchParams, pageSize }, sortOptions);
  }

  function onChangeGroupBy() {
    if (groupBy === SearchAssetsGroupBy.Section) {
      dispatch(searchAssetsActions.changeGroupBy({ groupBy }));
      onApply({ ...searchParams, groupBy: SearchAssetsGroupBy.Container }, sortOptions);
    } else {
      dispatch(searchAssetsActions.changeGroupBy({ groupBy }));
      onApply({ ...searchParams, groupBy: SearchAssetsGroupBy.Section }, sortOptions);
    }
  }

  return (
    <div
      data-testid="view-options"
      className="view-options-dropdown py-md mt-xxs flex z-200 flex-col shadow-border"
    >
      <p className="font-medium py-sm px-md">{t(VIEW_OPTIONS_KEY)}</p>
      {!showAttachmentsToggleDisabled && (
        <div className="flex items-center py-sm px-md view-options-item">
          <ToggleSwitch
            data-cy="show-attachments-toggle"
            data-testid="show-attachments-toggle"
            disabled={showAttachmentsToggleDisabled}
            className={classNames(
              {
                'opacity-40': showAttachmentsToggleDisabled,
              },
              'mr-xs',
            )}
            checked={showAttachments}
            onChange={() => dispatch(searchAssetsActions.toggleShowAttachments())}
          />{' '}
          <BFLabel>{t(SHOW_ATTACHMENTS_EXPAND_KEY)}</BFLabel>
        </div>
      )}
      <div className="flex items-center view-options-item px-md">
        <ToggleSwitch
          data-cy="organize-by-section-toggle"
          className="mr-xs"
          checked={groupBy === SearchAssetsGroupBy.Section}
          onChange={onChangeGroupBy}
        />{' '}
        <BFLabel>{t(ORGANIZE_BY_SECTION)}</BFLabel>
      </div>
      <SectionContent className="view-options-item px-md">
        <div className="flex items-center justify-between gap-sm">
          <BFLabel className="whitespace-nowrap text-primary">{t(ASSETS_PER_KEY)}</BFLabel>
          <div style={{ minWidth: pageSize === 120 ? 95 : 90 }}>
            <BFSelect
              required
              options={[16, 32, 64, 120].map((field) => ({
                label: field,
                value: field,
              }))}
              value={pageSize}
              onOptionChange={(option) => onSetSelectedAssetNumber(option.value as number)}
              className="select"
              isClearable={false}
            />
          </div>
        </div>
      </SectionContent>
      <SortingSelector sortOptions={localSortOptions} onChange={sortOptionsChanged} />
    </div>
  );
}
