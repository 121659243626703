import React, { HTMLAttributes, ReactElement } from 'react';
import './thumbnail-overlay.scss';

export interface ThumbnailOverlayProps {
  centerElement?: ReactElement;
  className?: string;
  onClick?: () => void;
  topElement?: ReactElement;
}

// TODO: migrate to tailwind ~PP
export function ThumbnailOverlay({
  centerElement,
  className,
  onClick,
  topElement,
  ...props
}: ThumbnailOverlayProps & HTMLAttributes<HTMLElement>) {
  return (
    <div {...props} className={`thumbnail-overlay ${className || ''}`} onClick={onClick}>
      {topElement && <div className="overlay-top">{topElement}</div>}
      {centerElement && <div className="overlay-center">{centerElement}</div>}
    </div>
  );
}
