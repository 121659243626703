import { ALL_MEDIA_TYPES, IMediaTypeSupportService, MediaType } from '@integration-frontends/integration/core/model';

export class MediaTypeSupportServiceMock implements IMediaTypeSupportService {
  canBeOpened(mediaType: MediaType): Promise<boolean> {
    return Promise.resolve(true);
  }

  getSupportedMediaTypes(): Promise<MediaType[]> {
    return Promise.resolve(ALL_MEDIA_TYPES)
  }

  isSupported(mediaType: MediaType): Promise<boolean> {
    return Promise.resolve(true)
  }
}
