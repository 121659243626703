import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  getResponseListDataOrDefault,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import { IOrganizationRepo, Organization } from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { map } from 'ramda';
import { GET_API_KEY_TOKEN, IGetApiKey, mapOrganization } from './model';

@injectable()
export class OrganizationRepo implements IOrganizationRepo {
  private brandfolderApi: BrandfolderApi;
  private getApiKey: IGetApiKey;

  constructor() {
    this.brandfolderApi = DI_CONTAINER.get(BRANDFOLDER_API_TOKEN);
    this.getApiKey = DI_CONTAINER.get(GET_API_KEY_TOKEN);
  }

  listOrganizations = async (): Promise<Organization[]> => {
    return this.brandfolderApi
      .listOrganizations(await this.getApiKey())
      .then((response) => getResponseListDataOrDefault(response))
      .then(map(mapOrganization));
  };
}
