import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  getResponseDataOrDefault,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Asset,
  Container,
  ISharingService,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { GET_API_KEY_TOKEN, IGetApiKey } from './model';

@injectable()
export class SharingService implements ISharingService {
  private brandfolderApi: BrandfolderApi;
  private getApiKey: IGetApiKey;

  constructor() {
    this.brandfolderApi = DI_CONTAINER.get(BRANDFOLDER_API_TOKEN);
    this.getApiKey = DI_CONTAINER.get(GET_API_KEY_TOKEN);
  }
  getAssetShareUrl = async (container: Container, asset: Asset): Promise<string> => {
    const apiKey = await this.getApiKey();
    const response =
      container.type === ResourceType.BRANDFOLDER
        ? await this.brandfolderApi.getBrandfolderAssetShareUrl(apiKey, container.id, asset.id)
        : await this.brandfolderApi.getCollectionAssetShareUrl(apiKey, container.id, asset.id);

    return getResponseDataOrDefault(response)?.attributes.link;
  };
}
