import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectNewNotifcations, closeNotification } from '@integration-frontends/common/notifications';
import './notifications.scss';
import { IconSuccessCheckmark } from '@integration-frontends/common/ui/icons/icon-success-checkmark';
import { IconClose, BFIconButton, IconSize } from '@integration-frontends/common/ui';


interface NotificationsContainerProps {
  location: string;
  timer?: number;
  top?: number;
  margin?: number;
}

export function NotificationsContainer ({
  location,
  timer = 3000,
  top = 0,
  margin = 0,
}: NotificationsContainerProps) {
  const dispatch = useDispatch();
  const myTime = new Date().getTime();
  const notifications = useSelector(selectNewNotifcations);

  return (
    <section className="notifications-container" style={{top}}>
      {notifications.map((notification, index) => {
          const timeLeft = timer - (myTime - notification.time.getTime());
          if (notification.location === location && timeLeft > 0) {
            return <Notification key={index} notification={notification} margin={margin} timer={timeLeft}/>
          }
          return '';
      })}
    </section>
  )
}

export function Notification ({ notification, margin, timer }) {
  const dispatch = useDispatch();
  const { message, position } = notification;
  const timeout = setTimeout(() => dispatch(closeNotification({ position })), timer);
  useEffect(() => {
    return () => clearTimeout(timeout); // this clears the timeout if the component gets unmounted
  }, []);
  return (
    <div className="notification box-shadow" style={{marginLeft: margin, marginRight: margin}}>
      <div className="flex">
        <IconSuccessCheckmark iconSize={IconSize.Large} />
        <span className="notification-message">{message}</span>
      </div>
      <BFIconButton
        className="cursor-pointer flex mt-xxs"
        onClick={() => {
          dispatch(closeNotification({ position }));
          clearTimeout(timeout);
        }}
        iconElement={<IconClose iconSize={IconSize.Large} />}
        style={{
          background: "none",
          margin: 0,
        }}
      />
    </div>
  )
}

const createCloseNotificationPromise = (notification, timer, dispatch) => {
  new Promise<void>((resolve) => {
    setTimeout(() => {
      dispatch(closeNotification({ position: notification.position }));
      resolve()
    }, timer)
  });
};
