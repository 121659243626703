import { DI_CONTAINER } from '@integration-frontends/core';
import {
  ATTACHMENT_REPO_TOKEN,
  IAttachmentRepo,
} from '@integration-frontends/integration/core/model';
import { call, put, takeEvery } from 'redux-saga/effects';
import { attachmentEntityActions } from '../../../common/entities-state';
import { editAttachmentInit, editAttachmentInitSuccess } from '../actions';

const handler = function* (action: ReturnType<typeof editAttachmentInit>) {
  const attachmentRepo: IAttachmentRepo = DI_CONTAINER.get(ATTACHMENT_REPO_TOKEN);
  const attachment = yield call(attachmentRepo.getAttachment, action.payload.attachmentId);
  yield put(attachmentEntityActions.attachmentReceived(attachment));
  yield put(editAttachmentInitSuccess());
};

export function* initEffects() {
  yield takeEvery(editAttachmentInit, handler);
}
