import { AssetsListPagination, init } from '@integration-frontends/integration/core/application/common/assets-list/assets-list-state/assets-list-pagination';
import { createAssetsListActions } from './actions';
import { createReducer } from '@reduxjs/toolkit';
import { prop } from 'ramda';

export interface AssetsListState {
  showAttachments: boolean;
  pagination: AssetsListPagination;
}

export const assetsListInitialState: AssetsListState = {
  showAttachments: false,
  pagination: init(),
};

export function createAssetsListReducer(actions: ReturnType<typeof createAssetsListActions>) {
  return createReducer(assetsListInitialState, (builder) =>
    builder
      .addCase(actions.listInit, (state) => {
        state.pagination = init();
      })
      .addCase(actions.nextPage, (state) => {
        state.pagination.loading = true;
      })
      .addCase(actions.previousPage, (state) => {
        state.pagination.loading = true;
      })
      .addCase(actions.pageLoaded, (state, action) => {
        const { results } = action.payload;
        state.pagination.pageSize = results.perPage;
        state.pagination.totalCount = results.totalCount;
        state.pagination.currentPage = results.currentPage;
        state.pagination.currentPageAssetIds = results.data.assets.map(
          prop('id'),
        );
        state.pagination.loading = false;
      })
      .addCase(actions.toggleShowAttachment, (state) => {
        state.showAttachments = !state.showAttachments;
      })
  );
}
