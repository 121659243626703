import { withPayloadType } from '@integration-frontends/common/utils/redux';
import {
  Alignment,
  AttachmentEditOptions,
  LabelOptions,
  LinkOptions,
} from '@integration-frontends/integration/core/application';
import { Attachment, AttachmentTransforms } from '@integration-frontends/integration/core/model';
import { createAction } from '@reduxjs/toolkit';

export const attachmentEdited = createAction(
  'ATTACHMENT_EDITED',
  withPayloadType<{
    attachment: Attachment;
    options: AttachmentEditOptions;
  }>(),
);

export const editAttachmentInit = createAction(
  'EDIT_ATTACHMENT_INIT',
  withPayloadType<{ attachmentId: string }>(),
);

export const editAttachmentCanceled = createAction(
  'EDIT_ATTACHMENT_CANCELED',
  withPayloadType<{ attachment: Attachment }>(),
);
export const editAttachmentInitSuccess = createAction('EDIT_ATTACHMENT_INIT_SUCCESS');

export const editAttachmentEnd = createAction(
  'EDIT_ATTACHMENT_END',
  withPayloadType<{
    attachment: Attachment;
    labelOptions: LabelOptions;
    linkOptions: LinkOptions;
    transforms: AttachmentTransforms;
    alignment: Alignment;
  }>(),
);
