import {
  AbsoluteDimensions,
  CUSTOM_DIMENSIONS,
  CustomDimension,
  NULL_ABSOLUTE_DIMENSIONS,
  NULL_RELATIVE_DIMENSIONS,
  RelativeDimensions,
  RelativeOffsets,
} from '@integration-frontends/integration/core/model';
import { merge } from 'ramda';
import { PartialDeep } from 'type-fest';
import { Resizable } from '../common';
import { CropType } from './crop-type';

export interface CustomCropOptions extends Resizable {
  type: CropType.Custom;
  customDimension: CustomDimension;
  offsets: RelativeOffsets | null;
  newDimensions: RelativeDimensions | null;
}

export function createCustomCropOptions(
  overrides: PartialDeep<CustomCropOptions> = {},
): CustomCropOptions {
  const defaults: CustomCropOptions = {
    type: CropType.Custom,
    customDimension: CustomDimension.Hubspot,
    offsets: null,
    initialDimensions: NULL_RELATIVE_DIMENSIONS,
    newDimensions: NULL_RELATIVE_DIMENSIONS,
    lockAspectRatio: true,
  };

  return merge(defaults, overrides) as CustomCropOptions;
}

export function setCustomDimension(
  options: CustomCropOptions,
  customDimension: CustomDimension,
): CustomCropOptions {
  return merge(options, { customDimension });
}

export function getCustomImageOutputDimensions(options: CustomCropOptions): AbsoluteDimensions {
  return CUSTOM_DIMENSIONS[options.customDimension] || NULL_ABSOLUTE_DIMENSIONS;
}
