import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  FieldParameter,
  getResponseDataOrDefault,
  getResponseIncluded,
  getResponseListDataOrDefault,
  IncludedBrandfolderDto,
  Relationship,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Brandfolder,
  BrandfolderCustomField,
  BrandfolderFileTypeAggregates,
  BrandfolderTag,
  IBrandfolderRepo,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { flatten, map, pipe, prop, uniqBy } from 'ramda';
import {
  GET_API_KEY_TOKEN,
  IGetApiKey,
  mapBrandfolder,
  mapBrandfolderCustomField,
  mapBrandfolderFileTypeAggregates,
  mapBrandfolderTag,
  mapIncludedBrandfolder,
} from './model';

@injectable()
export class BrandfolderRepo implements IBrandfolderRepo {
  private brandfolderApi: BrandfolderApi;
  private getApiKey: IGetApiKey;

  constructor() {
    this.brandfolderApi = DI_CONTAINER.get(BRANDFOLDER_API_TOKEN);
    this.getApiKey = DI_CONTAINER.get(GET_API_KEY_TOKEN);
  }

  getBrandfolder: IBrandfolderRepo['getBrandfolder'] = async (brandfolderId: string) => {
    return this.brandfolderApi
      .fetchBrandfolder(await this.getApiKey(), brandfolderId, {
        fields: [FieldParameter.CardImage, FieldParameter.AssetCount],
        include: [Relationship.ORGANIZATION],
      })
      .then(getResponseDataOrDefault)
      .then(mapBrandfolder)
      .then(async (brandfolder) => {
        if (brandfolder) return brandfolder;

        // if brandfolder is undefined then we'll have to fetch it as a relation
        const brandfolders = await this._getRelatedBrandfolders();
        return brandfolders.find((bf) => bf.id === brandfolderId);
      });
  };

  listBrandfolders = async (): Promise<Brandfolder[]> => {
    return Promise.all([this._getAccessibleBrandfolders(), this._getRelatedBrandfolders()]).then(
      pipe(flatten, uniqBy(prop('id'))),
    );
  };

  private async _getAccessibleBrandfolders(): Promise<Brandfolder[]> {
    return this.brandfolderApi
      .listBrandfolders(await this.getApiKey(), {
        fields: [FieldParameter.CardImage, FieldParameter.AssetCount],
        include: [Relationship.ORGANIZATION],
      })
      .then(getResponseListDataOrDefault)
      .then(map(mapBrandfolder));
  }

  private async _getRelatedBrandfolders(): Promise<Brandfolder[]> {
    return Promise.all([
      this.brandfolderApi.listCollections(await this.getApiKey(), {
        fields: [FieldParameter.AssetCount],
        include: [Relationship.BRANDFOLDER],
      }),
      this.brandfolderApi.listOrganizations(await this.getApiKey(), {
        include: [Relationship.COLLECTIONS],
      }),
    ]).then(([collectionsResponse, organizationsResponse]) => {
      return getResponseListDataOrDefault(collectionsResponse).map((collectionDto) => {
        const includedBrandfolderDto = getResponseIncluded(collectionsResponse).find(
          (included) =>
            included.id === collectionDto.relationships[Relationship.BRANDFOLDER].data.id,
        ) as IncludedBrandfolderDto;
        const organization = getResponseDataOrDefault(organizationsResponse).find(
          (organizationDto) =>
            organizationDto?.relationships[Relationship.COLLECTIONS].data.find(
              (c) => c.id === collectionDto.id,
            ),
        );

        return mapIncludedBrandfolder(includedBrandfolderDto, organization.id);
      });
    });
  }

  getBrandfolderCustomFields = async (brandfolderId: string): Promise<BrandfolderCustomField[]> => {
    const apiKey = await this.getApiKey();
    const searchableThings = await this.brandfolderApi.getBrandfolderSearchableThings(
      apiKey,
      brandfolderId,
    );
    const customFieldsResponse = await this.brandfolderApi.getBrandfolderCustomFieldsKeys(
      apiKey,
      brandfolderId,
    );

    return getResponseListDataOrDefault(customFieldsResponse).map((customFieldDto) =>
      mapBrandfolderCustomField(brandfolderId, customFieldDto, searchableThings),
    );
  };

  getBrandfolderFileTypeAggregates = async (
    brandfolderId: string,
  ): Promise<BrandfolderFileTypeAggregates[]> => {
    return await this.brandfolderApi
      .getBrandfolderSearchableThings(await this.getApiKey(), brandfolderId)
      .then((searchableThings) =>
        mapBrandfolderFileTypeAggregates(brandfolderId, searchableThings),
      );
  };

  getBrandfolderTags = async (brandfolderId: string): Promise<BrandfolderTag[]> => {
    const apiKey = await this.getApiKey();
    const searchableThings = await this.brandfolderApi.getBrandfolderSearchableThings(
      apiKey,
      brandfolderId,
    );
    const tagsResponse = await this.brandfolderApi.getBrandfolderTags(apiKey, brandfolderId);

    const tags = getResponseListDataOrDefault(tagsResponse).map((tagDto) =>
      mapBrandfolderTag(brandfolderId, tagDto, searchableThings),
    );

    return uniqBy((tag) => tag.name, tags);
  };
}
