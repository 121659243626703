import { Attachment } from '@integration-frontends/integration/core/model';
import { useAttachmentTransforms } from '@integration-fontends/integration/ui/attachment-selector/common/attachment-transforms/use-attachment-transforms';
import React from 'react';
import { CdnEmbedOptionsComponent } from '@integration-fontends/integration/ui/attachment-selector/components/asset-details-page/cdn-embed-options/cdn-embed-options-component';

export interface CdnEmbedOptionsContainerProps {
  attachment: Attachment;
}

export function CdnEmbedOptionsContainer({ attachment }: CdnEmbedOptionsContainerProps) {
  const { modifiedAttachment, mediaTypeChanged, resized, selectedMediaType, userResizingOptions } =
    useAttachmentTransforms({ attachment });

  return (
    <>
      {attachment && (
        <CdnEmbedOptionsComponent
          attachment={modifiedAttachment || attachment}
          onMediaTypeChange={mediaTypeChanged}
          onResize={resized}
          selectedMediaType={selectedMediaType}
          userResizingOptions={userResizingOptions}
        />
      )}
    </>
  );
}
