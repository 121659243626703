import { StateSelector } from '@integration-frontends/common/utils/redux';
import { collectionAdapter } from '@integration-frontends/integration/core/application/common/entities-state/entities';
import { Collection } from '@integration-frontends/integration/core/model';
import { createSelector, EntityState } from '@reduxjs/toolkit';

export function createCollectionSelectors(stateSelector: StateSelector<EntityState<Collection>>) {
  const baseSelectors = collectionAdapter.getSelectors(stateSelector);

  const collectionsByBrandfolderId = (brandfolderId: string) =>
    createSelector(baseSelectors.selectAll, (collections) =>
      collections.filter((collection) => collection.brandfolderId === brandfolderId),
    );

  return {
    ...baseSelectors,
    byBrandfolderId: collectionsByBrandfolderId,
  };
}
