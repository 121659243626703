import {
  ContainerCustomField,
  SearchParameters,
  setCustomFieldFilter,
} from '@integration-frontends/integration/core/model';
import React, { FunctionComponent } from 'react';
import {
  PrioritizedCustomFieldsFiltersComponent,
  PrioritizedCustomFieldsFiltersComponentProps,
} from './prioritized-custom-fields-filters-component';

export interface PrioritizedCustomFieldsFiltersContainerProps {
  customFields: ContainerCustomField[];
  searchParams: SearchParameters;
  onChange: (searchParams: SearchParameters) => void;
  Component?: FunctionComponent<PrioritizedCustomFieldsFiltersComponentProps>;
}

export function PrioritizedCustomFieldsFiltersContainer({
  customFields,
  searchParams,
  onChange,
  Component = PrioritizedCustomFieldsFiltersComponent,
}: PrioritizedCustomFieldsFiltersContainerProps) {
  function changed(field: ContainerCustomField, value: string) {
    onChange(setCustomFieldFilter(searchParams, field, value));
  }

  return <Component customFields={customFields} searchParams={searchParams} onChange={changed} />;
}
