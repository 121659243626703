import { injectable } from 'inversify';
import {
  AttachmentSelectorFeature,
  IAttachmentSelectorFeaturesService,
} from '@integration-frontends/integration/ui';

@injectable()
export class AttachmentSelectorFeaturesService implements IAttachmentSelectorFeaturesService {
  constructor(private features: AttachmentSelectorFeature[] = []) {}

  hasFeature(feature: AttachmentSelectorFeature): boolean {
    return this.features.includes(feature);
  }
}
