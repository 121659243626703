import classNames from 'classnames';
import { Loader } from '@integration-frontends/common/ui';
import React from 'react';
import './page-loader.scss';

interface IntegrationPageLoaderProps {
  fullHeight?: boolean;
  fullMinusHeader?: boolean;
  fullMinusOrgHeader?: boolean;
}

export function IntegrationPageLoader({ fullHeight, fullMinusHeader, fullMinusOrgHeader }: IntegrationPageLoaderProps) {
  return (
    <div
      className={classNames(
        'page-loader',
        { 'full': fullHeight },
        { 'full-minus-header': fullMinusHeader },
        { 'full-minus-org-header': fullMinusOrgHeader }
      )}
    >
      <Loader />
    </div>
  );
}
