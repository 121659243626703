import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  BrandfolderDto,
  FieldParameter,
  getResponseDataOrDefault,
  getResponseIncluded,
  getResponseListDataOrDefault,
  Relationship,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Collection,
  CollectionCustomField,
  CollectionFileTypeAggregates,
  CollectionTag,
  ICollectionRepo,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { uniqBy } from 'ramda';
import {
  GET_API_KEY_TOKEN,
  IGetApiKey,
  mapCollection,
  mapCollectionCustomField,
  mapCollectionFileTypeAggregates,
  mapCollectionTag,
} from './model';

@injectable()
export class CollectionRepo implements ICollectionRepo {
  private brandfolderApi: BrandfolderApi;
  private getApiKey: IGetApiKey;

  constructor() {
    this.brandfolderApi = DI_CONTAINER.get(BRANDFOLDER_API_TOKEN);
    this.getApiKey = DI_CONTAINER.get(GET_API_KEY_TOKEN);
  }

  getCollection: ICollectionRepo['getCollection'] = async (collectionId: string) => {
    return this.brandfolderApi
      .fetchCollection(await this.getApiKey(), collectionId, {
        fields: [FieldParameter.AssetCount],
        include: [Relationship.BRANDFOLDER],
      })
      .then((response) => {
        const collection = getResponseDataOrDefault(response);
        const included = getResponseIncluded(response);

        const brandfolder = included.find(
          (resource) => resource.id === collection.relationships[Relationship.BRANDFOLDER].data.id,
        ) as BrandfolderDto;

        return mapCollection(collection, brandfolder);
      });
  };

  listCollections = async (): Promise<Collection[]> => {
    return this.brandfolderApi
      .listCollections(await this.getApiKey(), {
        fields: [FieldParameter.AssetCount],
        include: [Relationship.BRANDFOLDER],
      })
      .then((response) => {
        const data = getResponseListDataOrDefault(response);
        const included = getResponseIncluded(response);

        return data.map((collection) => {
          const brandfolder = included.find(
            (resource) =>
              resource.id === collection.relationships[Relationship.BRANDFOLDER].data.id,
          ) as BrandfolderDto;

          return mapCollection(collection, brandfolder);
        });
      });
  };

  getCollectionCustomFields = async (collectionId: string): Promise<CollectionCustomField[]> => {
    const apiKey = await this.getApiKey();
    const searchableThings = await this.brandfolderApi.getCollectionSearchableThings(
      apiKey,
      collectionId,
    );
    const customFieldsResponse = await this.brandfolderApi.getCollectionCustomFieldKeys(
      apiKey,
      collectionId,
    );

    return getResponseListDataOrDefault(customFieldsResponse).map((customFieldDto) =>
      mapCollectionCustomField(collectionId, customFieldDto, searchableThings),
    );
  };

  getCollectionFileTypeAggregates = async (
    collectionId: string,
  ): Promise<CollectionFileTypeAggregates[]> => {
    return await this.brandfolderApi
      .getCollectionSearchableThings(await this.getApiKey(), collectionId)
      .then((searchableThings) => mapCollectionFileTypeAggregates(collectionId, searchableThings));
  };

  getCollectionTags = async (collectionId: string): Promise<CollectionTag[]> => {
    const apiKey = await this.getApiKey();
    const searchableThings = await this.brandfolderApi.getCollectionSearchableThings(
      apiKey,
      collectionId,
    );
    const tagsResponse = await this.brandfolderApi.getCollectionTags(apiKey, collectionId);

    const tags = getResponseListDataOrDefault(tagsResponse).map((tagDto) =>
      mapCollectionTag(collectionId, tagDto, searchableThings),
    );

    return uniqBy((tag) => tag.name, tags);
  };
}
