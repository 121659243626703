import { call, takeEvery } from 'redux-saga/effects';
import {
  brandfolderEntityActions,
  collectionEntityActions,
  searchAssetsActions,
  showPageDataLoaded,
  showPageEntered,
} from '@integration-frontends/integration/core/application';
import {
  BRANDFOLDER_REPO_TOKEN,
  Container,
  CONTAINER_REPO_TOKEN,
  IBrandfolderRepo,
  IContainerRepo,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { put, select, take } from 'typed-redux-saga';
import { selectRehydrated } from '@integration-frontends/common/app';

function* handler(action: ReturnType<typeof showPageEntered>) {
  const brandfolderRepo: IBrandfolderRepo = DI_CONTAINER.get(BRANDFOLDER_REPO_TOKEN);
  const containerRepo: IContainerRepo = DI_CONTAINER.get(CONTAINER_REPO_TOKEN);

  const container = yield call(containerRepo.getContainer, action.payload.containerId);

  if (container.type === ResourceType.BRANDFOLDER) {
    yield put(brandfolderEntityActions.brandfoldersReceived([container]));
  } else {
    const brandfolder = yield call(brandfolderRepo.getBrandfolder, container.brandfolderId);

    yield put(brandfolderEntityActions.brandfoldersReceived([brandfolder]));
    yield put(collectionEntityActions.collectionsReceived([container]));
  }

  yield initSearch(container);
  yield put(showPageDataLoaded());
}

function* initSearch(container: Container) {
  yield put(searchAssetsActions.init({ container }));
  yield take(searchAssetsActions.initSuccess);
}

export function* enteredShowPageEffects() {
  const showPageEnteredAction = yield take(showPageEntered);
  const isRehydrated = yield select(selectRehydrated);

  if (isRehydrated) {
    // use cached data
    yield put(showPageDataLoaded());
  } else {
    yield handler(showPageEnteredAction);
  }

  yield takeEvery(showPageEntered, handler);
}
