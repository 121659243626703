import React, { HTMLAttributes } from 'react';

function createLogoComponent(logoUrl: string) {
  return (props: HTMLAttributes<any>) => {
    return (
      <img className="max-w-full h-auto max-h-16" src={logoUrl} alt="Brandfolder logo" {...props} />
    );
  };
}

export const PrimaryLogo = createLogoComponent(
  'https://cdn.bfldr.com/YUHW9ZNT/at/pk0081-5dri20-gfbvt3/bf_primary_logo',
);

export const PrimaryLogoWhite = createLogoComponent(
  'https://cdn.bfldr.com/6RCXFQTO/at/pfbdqq-edkps0-4gypa7/bf_logo_giant_png.png?auto=webp&format=png&height=22&width=75',
);

export const BrandfolderBySmartsheetLogo = createLogoComponent(
  'https://cdn.bfldr.com/6RCXFQTO/at/cxv4gm6gh4ck56kbss89m66/brandfolder-by-smartsheet-logo-full-color.svg',
);
