import { ContainerCustomField } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const containerCustomFieldAdapter = createEntityAdapter<ContainerCustomField>();

export const containerCustomFieldSlice = createSlice({
  initialState: containerCustomFieldAdapter.getInitialState(),
  name: 'containerCustomField',
  reducers: {
    containerCustomFieldsReceived: containerCustomFieldAdapter.upsertMany,
  },
});
