import { enterToClick } from '@integration-frontends/common/ui';
import {
  containerSelectors,
  deselectAllAttachments,
  selectAttachmentSelectors,
  SelectionType,
} from '@integration-frontends/integration/core/application';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAttachmentSelectorNavigation } from '../../../navigation';
import './nav.scss';
import { PageNav } from '@integration-fontends/integration/ui/attachment-selector/common/layout/page';
import { BackButton } from '@integration-fontends/integration/ui/attachment-selector/components/show-page/back-button';

export function ShowPageNav() {
  const navigation = useAttachmentSelectorNavigation();
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, navigation.containerId),
  );
  const organization = useSelector(containerSelectors.organization(selectedContainer));
  const selectedAttachmentIdsList = useSelector(selectAttachmentSelectors.selectedAttachmentIds);
  const selectAttachmentSelectionType = useSelector(selectAttachmentSelectors.selectionType);
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    if (
      selectAttachmentSelectionType === SelectionType.Multi &&
      selectedAttachmentIdsList.length > 0
    ) {
      dispatch(deselectAllAttachments());
    }
    navigation.goToContainerSelector();
  }, []);

  return (
    <PageNav className="show-page-nav" data-testid="show-page-nav">
      <div className="w-full flex relative">
        <BackButton
          text={organization?.name}
          tabIndex={0}
          onKeyDown={enterToClick}
          onClick={onClick}
        />
      </div>
    </PageNav>
  );
}
