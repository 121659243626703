import { merge } from 'ramda';
import { PartialDeep } from 'type-fest';

export enum Alignment {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export interface AlignmentOptions {
  alignment: Alignment;
}

export function createAlignmentOptions(
  overrides: PartialDeep<AlignmentOptions> = {}
): AlignmentOptions {
  const defaults: AlignmentOptions = { alignment: Alignment.Left };
  return merge(defaults, overrides);
}

export function setAlignment(options: AlignmentOptions, alignment: Alignment): AlignmentOptions {
  return merge(options, { alignment });
}
