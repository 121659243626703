import React from 'react';
import { createIcon } from './create-icon';
import './icon-arrows.scss';

export const IconArrowRight = createIcon(<div className="icon-arrow-right" />);
export const IconArrowLeft = createIcon(<div className="icon-arrow-left" />);

export const IconArrowRightWhite = createIcon(<div className="icon-arrow-right-white" />);
export const IconArrowLeftWhite = createIcon(<div className="icon-arrow-left-white" />);

/* Brandfolder uses left caret icon and Smartsheet uses left arrow icon */
export const IconArrowCaretLeft = createIcon(<div className="icon-arrow-caret-left" />);
