import {
  Asset,
  AssetCustomFieldValue,
  AssetTag,
  Attachment,
  Container,
  SearchParameters,
  Section,
  SortDirection,
  SortOptions,
} from '@integration-frontends/integration/core/model';

export const USER_SETTINGS_REPO_TOKEN = Symbol.for('USER_SETTINGS_REPO');

export interface UploadPreferences {
  organizationId: string,
  containerId: string,
  sectionId: string,
}

export interface UploadPreferencesResults {
  organizationId: string,
  containerId: string,
  sectionId: string,
}

export interface IUserSettingsRepo {
  setAssetUploadPreference: (
    organizationId: string,
    containerId: string,
    sectionId: string,
  ) => Promise<UploadPreferencesResults>;
  getAssetUploadPreference: () => Promise<UploadPreferencesResults>;
}
