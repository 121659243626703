import React, { HTMLAttributes, InputHTMLAttributes } from 'react';
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import { isString } from 'lodash';

export const RADIO_COMPONENT_KEY = 'RADIO';
export type BFRadioProps = { label: string; value: string } & HTMLAttributes<HTMLInputElement> &
  InputHTMLAttributes<HTMLInputElement>;

function DefaultRadio({ label, ...restProps }: BFRadioProps) {
  return (
    <label style={{ display: 'flex', alignItems: 'center' }} className="min-w-0">
      <input {...restProps} type="radio" style={{ marginRight: 10 }} />
      {isString(label) ? (
        <span className="flex-grow min-w-0 cursor-pointer truncate">{label}</span>
      ) : (
        <>{label}</>
      )}
    </label>
  );
}

export const BFRadio = (props: BFRadioProps) => {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const RadioComponent = componentResolver.getComponent(RADIO_COMPONENT_KEY) || DefaultRadio;
  return <RadioComponent {...props} />;
};
