import React, { useState } from 'react';
import { Asset, Attachment } from '@integration-frontends/integration/core/model';
import { useTranslation } from 'react-i18next';
import { VIEW_DETAILS_KEY } from '../../i18n';
import { useSelector } from 'react-redux';
import { containerSelectors } from '@integration-frontends/integration/core/application';
import { useAttachmentSelectorNavigation } from '../../navigation';
import { compose, prop } from 'ramda';
import {
  BadgeSize,
  BadgeType,
  enterToClick,
  IconPaperclip,
  IconSize,
  PillBadge,
} from '@integration-frontends/common/ui';
import { Thumbnail } from '../thumbnail';
import { AssetThumbnailImage } from '@integration-frontends/integration/ui/common/asset-thumbnail-image';
import { ThumbnailOverlay } from '../thumbnail-overlay';
import { AssetThumbnailMenu } from './asset-thumbnail-menu';
import { ThumbnailContainer, ThumbnailLabelContainer } from './common';
import { AttachmentThumbnail } from './attachment-thumbnail';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import { useMediaTypeSupport } from '@integration-frontends/integration/ui/common/use-media-type-support';
import './asset-thumbnail.scss';

interface AssetThumbnailProps {
  asset: Asset;
  attachments: Attachment[];
  containerId: string;
  responsive?: boolean;
}

export function AssetThumbnail({
  asset,
  attachments,
  containerId,
  responsive,
}: AssetThumbnailProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const { isSupported } = useMediaTypeSupport();
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const navigation = useAttachmentSelectorNavigation();
  const [menuFocus, setMenuFocus] = useState(false);

  const firstAttachment = attachments.find(compose(isSupported, prop('mediaType')));

  const action = (
    <a
      tabIndex={0}
      data-testid="asset-view-details-btn"
      className="attachment-adjust-margin w-max button-neutral"
      onClick={() => navigation.goToAssetDetails(asset.id, selectedContainer.id)}
      onKeyDown={enterToClick}
      onFocus={() => setMenuFocus(true)}
      onBlur={() => setMenuFocus(false)}
    >
      {t(VIEW_DETAILS_KEY)}
    </a>
  );

  return asset.attachmentCount !== 1 ? (
    <ThumbnailContainer data-testid="asset-thumbnail">
      <Thumbnail className="mb-sm">
        <div
          className="asset-thumbnail-content w-full flex items-center justify-center"
          style={{ height: 150 }}
        >
          <AssetThumbnailImage
            data-testid="asset-thumbnail-image"
            asset={asset}
            responsive={responsive}
          />
          <ThumbnailOverlay
            className={`asset-overlay ${menuFocus && 'show'}`}
            centerElement={action}

            topElement={
              <AssetThumbnailMenu
                asset={asset}
                attachment={firstAttachment}
                containerId={containerId}
                onFocus={() => setMenuFocus(true)}
                onBlur={() => setMenuFocus(false)}
              />
            }
          />
        </div>
      </Thumbnail>

      <ThumbnailLabelContainer>
        {asset.attachmentCount > 0 && (
          <span className="flex truncate justify-between">
            <p className="truncate">{asset.name}</p>
            <PillBadge badgeType={BadgeType.Secondary} badgeSize={BadgeSize.Medium}>
              <IconPaperclip iconSize={IconSize.ExtraSmall} />
              &nbsp;
              {asset.attachmentCount}
            </PillBadge>
          </span>
        )}
      </ThumbnailLabelContainer>
    </ThumbnailContainer>
  ) : (
    <AttachmentThumbnail
      asset={asset}
      attachment={attachments[0]}
      containerId={containerId}
      responsive={responsive}
      useAssetName={true}
    />
  );
}
