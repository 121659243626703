import { IconChevronDown, IconChevronRight } from '@integration-frontends/common/ui';
import { Organization } from '@integration-frontends/integration/core/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BRANDFOLDER_KEY,
  BRANDFOLDERS_KEY,
  INTEGRATION_COMMON_NAMESPACE,
} from '@integration-frontends/integration/ui/common/i18n';
import './organization-section-banner.scss';
import classNames from 'classnames';

interface OrganizationSectionBannerProps {
  brandfolderCount: number;
  collapsed: boolean;
  organization: Organization;
  multipleOrganizations: boolean;
}

export function OrganizationSectionBanner({
  brandfolderCount,
  collapsed = false,
  organization,
  multipleOrganizations,
}: OrganizationSectionBannerProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  return (
    <div
      className={classNames(
        'organization-section-banner text-primary font-medium rounded-xl gap-md',
        { collapsed: collapsed, listItemDelimiter: !multipleOrganizations },
      )}
      data-cy="organization-section-banner"
    >
      <div className="flex-grow flex items-center overflow-auto">
        <span className="flex-grow h-full flex items-center overflow-auto">
          <h2 className="font-medium org-name truncate">
            {organization.name}
            {collapsed &&
              ` (${brandfolderCount} ${multipleOrganizations ? t(BRANDFOLDERS_KEY) : t(BRANDFOLDER_KEY)})`
            }
          </h2>
        </span>
      </div>

        {multipleOrganizations ?
          <span className="px-xs">
            {collapsed ? (
              <IconChevronRight />
            ) : (
              <IconChevronDown />
            )}
          </span>
          :
          <span className="whitespace-nowrap">
            {brandfolderCount} {brandfolderCount > 1 ? t(BRANDFOLDERS_KEY) : t(BRANDFOLDER_KEY)}
          </span>
        }
    </div>
  );
}
