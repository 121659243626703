import { Disclosure } from '@headlessui/react';
import {
  ExpandableSectionIcon,
  SectionContent,
  SectionHeader,
} from '@integration-fontends/integration/ui/attachment-selector/components/advanced-filters/common';
import {
  HORIZONTAL_KEY,
  ORIENTATION_KEY,
  PANORAMIC_KEY,
  SQUARE_KEY,
  VERTICAL_KEY,
} from '@integration-fontends/integration/ui/attachment-selector/i18n';
import {
  BFCheckbox,
  IconOrientationHorizontal,
  IconOrientationPanoramic,
  IconOrientationSquare,
  IconOrientationVertical,
} from '@integration-frontends/common/ui';
import {
  AttachmentOrientation,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';

export interface OrientationFiltersComponentProps {
  searchParams: SearchParameters;
  onChange: (orientation: AttachmentOrientation, checked: boolean) => void;
}

export function OrientationFiltersComponent({
  searchParams,
  onChange,
}: OrientationFiltersComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  function isChecked(orientation: AttachmentOrientation): boolean {
    return searchParams?.orientationFilters?.includes(orientation) || false;
  }

  return (
    <Disclosure defaultOpen={searchParams?.orientationFilters?.length > 0}>
      {({ open }) => {
        return (
          <>
            <SectionHeader>
              <Disclosure.Button as="div" className="flex justify-between">
                <span>{t(ORIENTATION_KEY)}</span>
                <ExpandableSectionIcon expanded={open} />
              </Disclosure.Button>
            </SectionHeader>

            <Disclosure.Panel>
              <SectionContent className="pb-lg">
                <div className="flex flex-col gap-md">
                  <div className="flex justify-between">
                    <BFCheckbox
                      checked={isChecked(AttachmentOrientation.Horizontal)}
                      onChange={(e) => onChange(AttachmentOrientation.Horizontal, e.target.checked)}
                      className="mr-sm"
                      label={t(HORIZONTAL_KEY)}
                    />

                    <IconOrientationHorizontal />
                  </div>

                  <div className="flex justify-between">
                    <BFCheckbox
                      checked={isChecked(AttachmentOrientation.Vertical)}
                      onChange={(e) => onChange(AttachmentOrientation.Horizontal, e.target.checked)}
                      className="mr-sm"
                      label={t(VERTICAL_KEY)}
                    />

                    <IconOrientationVertical />
                  </div>

                  <div className="flex justify-between">
                    <BFCheckbox
                      checked={isChecked(AttachmentOrientation.Square)}
                      onChange={(e) => onChange(AttachmentOrientation.Horizontal, e.target.checked)}
                      className="mr-sm"
                      label={t(SQUARE_KEY)}
                    />

                    <IconOrientationSquare />
                  </div>

                  <div className="flex justify-between">
                    <BFCheckbox
                      checked={isChecked(AttachmentOrientation.Panoramic)}
                      onChange={(e) => onChange(AttachmentOrientation.Horizontal, e.target.checked)}
                      className="mr-sm"
                      label={t(PANORAMIC_KEY)}
                    />

                    <IconOrientationPanoramic />
                  </div>
                </div>
              </SectionContent>
            </Disclosure.Panel>
          </>
        );
      }}
    </Disclosure>
  );
}
