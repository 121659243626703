import { getAuthRootReducer } from '@integration-frontends/common/auth/core/application';
import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
} from '@integration-frontends/common/auth/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { Container } from 'inversify';

export interface ProviderDefinition<T> {
  useClass?: (...args: any[]) => T;
}

export interface AuthProviders {
  [AUTHENTICATE_SERVICE_TOKEN]: ProviderDefinition<IAuthenticateService>;
}

export interface AuthInitProps {
  providers: AuthProviders;
}

// TODO: Create generic module. Make state initialization more streamlined. ~PP
export class AuthUiModule {
  static initialize(container: Container) {
    DI_CONTAINER.merge(container);
  }

  static getRootReducer() {
    return getAuthRootReducer();
  }
}
