import {
  deselectAllAttachments,
  selectAllAttachments,
  entitiesSelectors,
  selectAttachmentSelectors,
} from '@integration-frontends/integration/core/application';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './multi-select-overlay.scss';
import { useMediaTypeSupport } from '../common/use-media-type-support';
import { MultiSelectActionsMenu } from '../multi-select-actions-menu';
import { ALL_VISIBLE_KEY, NONE_KEY } from '@integration-fontends/integration/ui/attachment-selector/i18n';
import { NotificationsContainer } from '../common/notifications/notifications';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import './multi-select-overlay.scss';
import { T } from 'ramda';

export function MultiSelectOverlay() {
  const dispatch = useDispatch();
  const attachmentEntitiesList = useSelector(entitiesSelectors.attachment.selectEntities);
  const { supportedMediaTypes } = useMediaTypeSupport();
  const selectedAttachmentIds = useSelector(selectAttachmentSelectors.selectedAttachmentIds);
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  const selectAll = () => {
    const supportedAttachmentIds = Object.keys(attachmentEntitiesList).filter((key) => {
      return supportedMediaTypes.includes(attachmentEntitiesList[key].mediaType);
    });

    dispatch(selectAllAttachments({ attachmentIds: supportedAttachmentIds }));
  };

  const deselectAll = () => {
    dispatch(deselectAllAttachments());
  };

  return (
    <section className={`multi-select-overlay ${selectedAttachmentIds.length ? 'open' : ''}`}>
        <NotificationsContainer location="multi-select" timer={3000} top={-35} margin={10} />
      <div
        className="flex flex-row justify-between items-center justify-between"
        style={{ width: '97vw' }}
      >
        <div className="flex flex-row items-center">
          <p className="font-medium">Select:</p>
          <p onClick={selectAll} className="font-bold link multi-select-overlay__link cursor-pointer">
            {t(ALL_VISIBLE_KEY)}
          </p>
          <p onClick={deselectAll} className="font-bold link multi-select-overlay__link cursor-pointer">
            {t(NONE_KEY)}
          </p>
        </div>
        <div className="flex flex-row items-center">
          <MultiSelectActionsMenu/>
        </div>
      </div>
    </section>
  );
}
