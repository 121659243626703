import {Brandfolder, Section} from "@integration-frontends/integration/core/model";
import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";

export const sectionAdapter = createEntityAdapter<Section>();

export const sectionSlice = createSlice({
  name: 'section',
  initialState: sectionAdapter.getInitialState(),
  reducers: {
    sectionsReceived: sectionAdapter.upsertMany,
  },
});
