import {
  AbsoluteDimensions,
  NULL_ABSOLUTE_DIMENSIONS,
} from '@integration-frontends/integration/core/model';
import { merge } from 'ramda';
import { PartialDeep } from 'type-fest/source/partial-deep';
import { Resizable, setDimensions } from './common';
import { CropOptions, getOutputDimensions } from './cropping';

export type ResizingOptions = Resizable<AbsoluteDimensions>;
export function createResizingOptions(
  overrides: PartialDeep<ResizingOptions> = {},
): ResizingOptions {
  const defaults: ResizingOptions = {
    initialDimensions: NULL_ABSOLUTE_DIMENSIONS,
    newDimensions: NULL_ABSOLUTE_DIMENSIONS,
    lockAspectRatio: true,
  };

  return merge(defaults, overrides) as ResizingOptions;
}

export function applyCropOptions(
  resizingOptions: ResizingOptions,
  cropOptions: CropOptions,
): ResizingOptions {
  if (!cropOptions) return resizingOptions;

  const outputDimensions = getOutputDimensions(resizingOptions?.newDimensions, cropOptions);
  return createResizingOptions(setDimensions(resizingOptions, outputDimensions));
}
