import { createReducer } from '@reduxjs/toolkit';
import { createNotification, closeNotification } from './actions';

interface Notification {
  message: string,
  time: Date,
  closed: boolean,
  location: string,
  position: number
}

export interface NotificationsState {
  notifications: Notification[];
}

export const initialState: NotificationsState = {
  notifications: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createNotification, (state, action) => {
      const notification: Notification = {
        message: action.payload.message,
        location: action.payload.location,
        closed: false,
        time: new Date(),
        position: state.notifications.length,
      };
      state.notifications.push(notification);
    })
    .addCase(closeNotification, (state, action) => {
      state.notifications[action.payload.position].closed = true;
    });
});

export interface NotificationsRootState {
  notifications: NotificationsState;
}

export const notificationsRootReducer = {
  notifications: reducer,
};
