import { searchAssetsEffects as localSearchAssetsEffects } from './search-assets/state/effects';
import { selectAttachmentEffects as localSelectAttachmentEffects } from './select-attachment/state/effects';
import { entitiesSelectors, searchAssetsSelectors } from './selectors';

export function* searchAssetsEffects() {
  yield localSearchAssetsEffects(searchAssetsSelectors, entitiesSelectors);
}

export function* selectAttachmentEffects() {
  yield localSelectAttachmentEffects(entitiesSelectors);
}
