import { Disclosure } from '@headlessui/react';
import {
  brandfolderEntitySelectors,
  collectionEntitySelectors,
  organizationEntitySelectors,
  orgLandingPageEntered,
  selectAttachmentSelectors,
} from '@integration-frontends/integration/core/application';
import { Brandfolder, filterByOrganization } from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { BrandfolderListItem } from './brandfolder-list-item';
import { OrganizationSectionBanner } from './organization-section-banner';
import { useTranslation } from 'react-i18next';
import {
  INTEGRATION_COMMON_NAMESPACE,
  ORGANIZATIONS_KEY,
} from '../../../../../../../../libs/integration/ui/src/lib/common/i18n';
import './organization-landing-page.scss';
import { IntegrationPageLoader } from '@integration-frontends/integration/ui/common/page-loader/page-loader';
import { useAttachmentSelectorNavigation } from '@integration-fontends/integration/ui/attachment-selector/navigation';
import { enterToClick } from '@integration-frontends/common/ui';
import {
  PageContainer,
  PageContent,
  PageHeader,
} from '@integration-fontends/integration/ui/attachment-selector/common/layout/page';

export function OrganizationLandingPage({ match }: RouteComponentProps) {
  const dispatch = useDispatch();
  const loading = useSelector(selectAttachmentSelectors.pageLoading);
  const organizations = useSelector(organizationEntitySelectors.selectAll);
  const multipleOrganizations = organizations.length > 1;
  const brandfolders = useSelector(brandfolderEntitySelectors.selectAll);
  const collections = useSelector(collectionEntitySelectors.selectAll);
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const navigation = useAttachmentSelectorNavigation();

  useEffect(() => {
    dispatch(orgLandingPageEntered());
  }, []);

  function onBrandfolderSelected(brandfolder: Brandfolder) {
    navigation.goToShowPage(brandfolder.id);
  }

  const collectionsForOrganization = useCallback(
    (orgId: string) => {
      return collections.filter(
        (collection) =>
          brandfolders.find((b) => b.id === collection.brandfolderId)?.organizationId === orgId,
      );
    },
    [collections],
  );

  return (
    <PageContainer>
    { multipleOrganizations ? (
      <PageHeader>
        <h2 className="organizations-list-header">{t(ORGANIZATIONS_KEY)}</h2>
      </PageHeader>
    ) : (
      organizations.map((org, index) => (
        <PageHeader>
          <OrganizationSectionBanner
            collapsed={false}
            organization={org}
            brandfolderCount={brandfolders.filter(filterByOrganization(org.id )).length}
            multipleOrganizations={false}
          />
        </PageHeader>
      ))

      )
    }

      <PageContent data-testid="brandfolders-list" className="brandfolders-list" data-cy="brandfolders-list">
        {loading ? (
          <IntegrationPageLoader fullMinusOrgHeader={true} />
        ) : (
          organizations.map((org, index) => (
            <div data-testid="org-list-item" className="org-list-item" key={org.id}>
              <Disclosure defaultOpen={index === 0}>
                {({ open }) => (
                  <>
                  {
                    multipleOrganizations &&
                    <Disclosure.Button className="w-full">
                      <OrganizationSectionBanner
                        collapsed={!open}
                        organization={org}
                        brandfolderCount={brandfolders.filter(filterByOrganization(org.id)).length}
                        multipleOrganizations={multipleOrganizations}
                      />
                    </Disclosure.Button>
                  }

                    <Disclosure.Panel>
                      {brandfolders
                        .filter(filterByOrganization(org.id))
                        .map((brandfolder, index) => (
                          <BrandfolderListItem
                            key={brandfolder.id}
                            brandfolder={brandfolder}
                            brandfolderCount={
                              brandfolders.filter(filterByOrganization(org.id)).length
                            }
                            collections={collectionsForOrganization(org.id).filter(
                              (collection) => collection.brandfolderId === brandfolder.id,
                            )}
                            onKeyDown={enterToClick}
                            tabIndex={0}
                            onClick={() => onBrandfolderSelected(brandfolder)}
                            className={classNames({
                              'brandfolders-list-item': index < brandfolders.length - 1,
                            })}
                          />
                        ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          ))
        )}
      </PageContent>
    </PageContainer>
  );
}
