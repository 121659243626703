import { StateSelector } from '@integration-frontends/common/utils/redux';
import { IntegrationEntitiesSelectors } from '@integration-frontends/integration/core/application/common';
import { createSelector } from '@reduxjs/toolkit';
import { SelectAttachmentState } from './reducer';

const selectInitLoading = (state: SelectAttachmentState) => state.initLoading;
const selectInitSuccess = (state: SelectAttachmentState) => state.initSuccess;
const selectPageLoading = (state: SelectAttachmentState) => state.pageLoading || state.initLoading;
//multi select list
const selectSelectedAttachmentIdsList = (state: SelectAttachmentState) =>
  state.selectedAttachmentIds;
//selection type
const selectSelectionType = (state: SelectAttachmentState) => state.selectionType;

//show page
const selectShowPageScrollPosition = (state: SelectAttachmentState) => state.showPageScrollPosition;

// TODO: make this reusable and less verbose ~PP
export type SelectAttachmentStateSelector = StateSelector<SelectAttachmentState>;

export function createSelectAttachmentSelectors(
  stateSelector: SelectAttachmentStateSelector,
  entitiesSelectors: IntegrationEntitiesSelectors,
) {
  const baseSelectors = {
    initLoading: createSelector(stateSelector, selectInitLoading),
    initSuccess: createSelector(stateSelector, selectInitSuccess),
    pageLoading: createSelector(stateSelector, selectPageLoading),
    selectedAttachmentIds: createSelector(stateSelector, selectSelectedAttachmentIdsList),
    selectionType: createSelector(stateSelector, selectSelectionType),
    showPageScrollPosition: createSelector(stateSelector, selectShowPageScrollPosition),
  };

  return {
    ...baseSelectors,
    selectedAttachments: createSelector(
      entitiesSelectors.attachment.selectEntities,
      baseSelectors.selectedAttachmentIds,
      (attachmentEntities, attachmentIds) => attachmentIds?.map((id) => attachmentEntities[id]),
    ),
  };
}
