import {
  assetsListBySectionInitialState,
  AssetsListBySectionState,
  createAssetsListBySectionReducer,
} from '@integration-frontends/integration/core/application/common';
import {
  assetsListInitialState,
  createAssetsListReducer,
} from '@integration-frontends/integration/core/application/common/assets-list/assets-list-state';
import {
  ResourceBaseSortableProperty,
  SearchParameters,
  SortDirection,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import { createReducer } from '@reduxjs/toolkit';
import {
  changeGroupBy,
  changePageSize,
  clearSearch,
  init,
  initSearchFiltersSuccess,
  initSuccess,
  search,
  searchAssetsActions,
  searchSuccess,
  toggleShowAttachments,
} from './actions';
import { AssetsListState } from '@integration-frontends/integration/core/application/common/assets-list/assets-list-state/reducer';

const assetsListReducer = createAssetsListReducer(searchAssetsActions);
const assetsListBySectionReducer = createAssetsListBySectionReducer(searchAssetsActions);

const DEFAULT_PAGE_SIZE = 16;

export enum SearchAssetsGroupBy {
  Container = 'CONTAINER',
  Section = 'SECTION',
}

export interface SearchAssetsState {
  containerId: string;
  loading: boolean;
  filtersLoading: boolean;
  searchParams: SearchParameters | null;
  sortOptions: SortOptions<ResourceBaseSortableProperty>;
  listByContainer: AssetsListState;
  listBySection: AssetsListBySectionState;
  pageSize: number;
  initSuccess: boolean;
  showAttachments: boolean;
  groupBy: SearchAssetsGroupBy;
}

const initialState: SearchAssetsState = {
  containerId: null,
  loading: false,
  filtersLoading: false,
  searchParams: null,
  sortOptions: { direction: SortDirection.Asc, field: ResourceBaseSortableProperty.Position },
  listByContainer: assetsListInitialState,
  listBySection: assetsListBySectionInitialState,
  pageSize: DEFAULT_PAGE_SIZE,
  initSuccess: false,
  showAttachments: false,
  groupBy: SearchAssetsGroupBy.Section,
};

export const SEARCH_ASSETS_REDUCER = createReducer(initialState, (builder) => {
  builder
    .addCase(init, (state, action) => {
      state.initSuccess = false;
      state.filtersLoading = true;
      state.containerId = action.payload.container.id;
    })
    .addCase(initSuccess, (state) => {
      state.initSuccess = true;
    })
    .addCase(initSearchFiltersSuccess, (state) => {
      state.filtersLoading = false;
    })
    .addCase(clearSearch, (state) => {
      state.searchParams = null;
      state.loading = false;
    })
    .addCase(search, (state, action) => {
      state.searchParams = action.payload.searchParams;
      state.sortOptions = action.payload.sortOptions;
      state.loading = true;
    })
    .addCase(searchSuccess, (state, action) => {
      state.loading = false;
    })
    .addCase(changePageSize, (state, action) => {
      const { pageSize } = action.payload;
      state.pageSize = pageSize;
    })
    .addCase(toggleShowAttachments, (state) => {
      state.showAttachments = !state.showAttachments;
    })
    .addCase(changeGroupBy, (state, action) => {
      const { groupBy } = action.payload;
      state.groupBy = groupBy;
    })
    .addMatcher(
      () => true,
      (state, action) => {
        state.listByContainer = assetsListReducer(state.listByContainer, action);
        state.listBySection = assetsListBySectionReducer(state.listBySection, action);
      },
    );
});
