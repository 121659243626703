import {
  AttachmentActionContainerBaseProps,
  IAttachmentActionProvider,
} from '@integration-fontends/integration/ui/attachment-selector/common/attachment-actions/attachment-actions-model';

export class AttachmentActionsProviderPanelSFMCStrategy implements IAttachmentActionProvider {
  getActionContainers(): React.FunctionComponent<AttachmentActionContainerBaseProps>[] {
    return [];
  }
}
