import { BFImage } from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Asset,
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

export interface AssetImageProps {
  asset: Asset;
  responsive?: boolean;
}

export function AssetThumbnailImage({
  asset,
  className,
  responsive,
  ...props
}: AssetImageProps & HTMLAttributes<HTMLImageElement>) {
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );

  return (
    <BFImage
      src={asset?.thumbnailUrl}
      alt={asset?.name}
      fallbackUrl={bfWebsiteService.getFileThumbnailUrl(null)}
      className={classNames('w-full h-full', className)}
      responsive={responsive}
      {...props}
    />
  );
}
