import { DI_CONTAINER } from '@integration-frontends/core';
import { put, takeEvery } from 'redux-saga/effects';
import { attachmentSelected, endAttachmentSelection } from '../actions';
import {
  ISelectAttachmentOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  SelectionType,
} from '../../select-attachment-options';

const handler = function* (action: ReturnType<typeof attachmentSelected>) {
  const options: ISelectAttachmentOptions = DI_CONTAINER.get(SELECT_ATTACHMENT_OPTIONS_TOKEN);

  if (options.selectionType === SelectionType.Single) {
    yield put(endAttachmentSelection({ attachments: [action.payload.attachment] }));
  }
};

export function* attachmentSelectedEffects() {
  yield takeEvery(attachmentSelected, handler);
}
