import { DI_CONTAINER } from '@integration-frontends/core';
import {
  IOrganizationRepo,
  ORGANIZATION_REPO_TOKEN,
} from '@integration-frontends/integration/core/model';
import { put, takeEvery } from 'redux-saga/effects';
import {
  ISelectAttachmentOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
} from '../../select-attachment-options';
import { selectAttachmentInit, selectAttachmentInitSuccess } from '../actions';
import { call, select, take } from 'typed-redux-saga';
import { organizationEntityActions } from '@integration-frontends/integration/core/application';
import { selectRehydrated } from '@integration-frontends/common/app';

const handler = function* () {
  const organizationRepo: IOrganizationRepo = DI_CONTAINER.get(ORGANIZATION_REPO_TOKEN);

  const organizations = yield call(organizationRepo.listOrganizations);
  yield put(organizationEntityActions.organizationsReceived(organizations));
  yield dispatchSuccess();
};

function* dispatchSuccess() {
  const selectAttachmentOptions: ISelectAttachmentOptions = DI_CONTAINER.get(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );

  yield put(selectAttachmentInitSuccess({ selectAttachmentOptions: selectAttachmentOptions }));
}

export function* initEffects() {
  yield take(selectAttachmentInit);
  const isRehydrated = yield select(selectRehydrated);

  if (!isRehydrated) {
    yield handler();
  } else {
    yield take(selectAttachmentInit);
    yield dispatchSuccess();
  }

  yield takeEvery(selectAttachmentInit, handler);
}
