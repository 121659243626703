import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import './flex-spacer.scss';

export type SpacingSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
export type SpacingDirection = 'vertical' | 'horizontal';

export interface SpacerProps {
  size?: SpacingSize;
  direction?: SpacingDirection;
}

const DEFAULT_HORIZONTAL_SIZE: SpacingSize = 'm';
const DEFAULT_VERTICAL_SIZE: SpacingSize = 'l';

export function FlexSpacer({
  className,
  direction = 'horizontal',
  size = direction === 'vertical' ? DEFAULT_VERTICAL_SIZE : DEFAULT_HORIZONTAL_SIZE,
  ...restProps
}: SpacerProps & HTMLAttributes<HTMLDivElement>) {
  return <div className={classNames(size, direction, className, 'spacer-container')} {...restProps} />;
}
