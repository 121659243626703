import { Label } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const labelAdapter = createEntityAdapter<Label>();

export const labelSlice = createSlice({
  name: 'label',
  initialState: labelAdapter.getInitialState(),
  reducers: {
    labelsReceived: labelAdapter.upsertMany,
  },
});
