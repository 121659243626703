import { prop } from 'ramda';
import { DEFAULT_PAGE_SIZE } from '../assets-list-state/common';
import { AssetsListPagination, init } from '../assets-list-state/assets-list-pagination';
import { createReducer } from '@reduxjs/toolkit';
import { createAssetsListBySectionActions } from './actions';

export interface AssetsListBySectionState {
  showAttachments: boolean;
  pagination: {
    pageSize: number;
    sections: { [sectionId: string]: AssetsListPagination };
  };
}

export const assetsListBySectionInitialState: AssetsListBySectionState = {
  showAttachments: false,
  pagination: {
    pageSize: DEFAULT_PAGE_SIZE,
    sections: {},
  },
};

export function createAssetsListBySectionReducer(actions: ReturnType<typeof createAssetsListBySectionActions>) {
  return createReducer(assetsListBySectionInitialState, (builder) =>
    builder
      .addCase(actions.sectionsLoaded, (state, action) => {
        const { sectionIds } = action.payload;
        state.pagination.sections = sectionIds.reduce((accum, sectionId) => {
          return { ...accum, [sectionId]: init() };
        }, {});
      })
      .addCase(actions.nextSectionPage, (state, action) => {
        const { sectionId } = action.payload;
        state.pagination.sections[sectionId].loading = true;
      })
      .addCase(actions.previousSectionPage, (state, action) => {
        const { sectionId } = action.payload;
        state.pagination.sections[sectionId].loading = true;
      })
      .addCase(actions.sectionPageLoaded, (state, action) => {
        const { sectionId, results } = action.payload;
        state.pagination.sections[sectionId].pageSize = results.perPage;
        state.pagination.sections[sectionId].totalCount = results.totalCount;
        state.pagination.sections[sectionId].currentPage = results.currentPage;
        state.pagination.sections[sectionId].currentPageAssetIds = results.data.assets.map(
          prop('id'),
        );
        state.pagination.sections[sectionId].loading = false;
      })
      .addCase(actions.toggleShowAttachment, (state) => {
        state.showAttachments = !state.showAttachments;
      })
  );
}
