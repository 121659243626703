import { ContainerFileTypeAggregates } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const containerFileTypeAggregatesAdapter = createEntityAdapter<ContainerFileTypeAggregates>({
  selectId: (entity) => `${entity.containerId}-${entity.name}`,
});

export const containerFileTypeAggregatesSlice = createSlice({
  initialState: containerFileTypeAggregatesAdapter.getInitialState(),
  name: 'containerFileTypeAggregates',
  reducers: {
    containerFileTypeAggregatesReceived: containerFileTypeAggregatesAdapter.upsertMany,
  },
});
