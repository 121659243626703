import { DI_CONTAINER } from '@integration-frontends/core';
import { initiateAttachmentActionClicked } from '@integration-frontends/integration/core/application';
import {
  Attachment,
  ATTACHMENT_REPO_TOKEN,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { AttachmentRepo } from '@integration-frontends/integration/infrastructure/isomorphic';
import { all, call, takeEvery } from 'redux-saga/effects';
import { noop } from 'lodash';

const initiateAttachmentActionClickedHandler = function* (
  action: ReturnType<typeof initiateAttachmentActionClicked>,
) {
  const callback = action.payload.callback;
  const done = action.payload.done || noop;
  const attachmentRepo: AttachmentRepo = DI_CONTAINER.get(ATTACHMENT_REPO_TOKEN);

  const payloadAttachments = action.payload.attachments;
  const attachments: Attachment[] = yield all(
    payloadAttachments.map((attachment) => call(attachmentRepo.getAttachment, attachment.id)),
  );

  const finalAttachments = payloadAttachments.map((payloadAttachment) => {
    if (payloadAttachment.type === ResourceType.ATTACHMENT_VARIANT) {
      // if attachment was modified (resizing/cropping) then we can assume that it has all the required data e.g. CDN url
      return payloadAttachment;
    } else {
      // Attachment data may be incomplete depending on which API calls were used previously (due to performance concerns),
      // we may therefore need to fetch the data here to ensure its integrity
      return attachments.find(({ id }) => payloadAttachment.id === id);
    }
  });

  callback(finalAttachments).then(done);
};

export function* initiateAttachmentActionClickedEffects() {
  yield takeEvery(initiateAttachmentActionClicked, initiateAttachmentActionClickedHandler);
}
