import { SortOptions } from '@integration-frontends/integration/core/model';
export const SELECT_ATTACHMENT_OPTIONS_TOKEN = 'SELECT_ATTACHMENT_OPTIONS';

export enum SelectActionType {
  Select = 'Select',
  Place = 'Place',
}

export enum SelectionType {
  Single,
  Multi,
}

export interface ISelectAttachmentOptions {
  actionType: SelectActionType;
  selectionType: SelectionType;
  customSortOptions?: SortOptions;
  showAttachmentsToggleDisabled?: boolean;
  showAToZSorting?: boolean;
}
