import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  getResponseListDataOrDefault,
  Relationship,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Container,
  ISectionRepo,
  ResourceType,
  Section,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { GET_API_KEY_TOKEN, IGetApiKey, mapSection } from './model';

@injectable()
export class SectionRepo implements ISectionRepo {
  private brandfolderApi: BrandfolderApi;
  private getApiKey: IGetApiKey;

  constructor() {
    this.brandfolderApi = DI_CONTAINER.get(BRANDFOLDER_API_TOKEN);
    this.getApiKey = DI_CONTAINER.get(GET_API_KEY_TOKEN);
  }

  listBrandfolderSections = async (brandfolderId: string): Promise<Section[]> => {
    return this.brandfolderApi
      .listBrandfolderSections(await this.getApiKey(), brandfolderId, {
        include: [Relationship.BRANDFOLDER],
      })
      .then((res) => {
        return getResponseListDataOrDefault(res).map(mapSection);
      });
  };

  listCollectionSections = async (collectionId: string): Promise<Section[]> => {
    return this.brandfolderApi
      .listCollectionSections(await this.getApiKey(), collectionId, {
        include: [Relationship.BRANDFOLDER],
      })
      .then((res) => {
        return getResponseListDataOrDefault(res).map(mapSection);
      });
  };

  listContainerSections = async (container: Container): Promise<Section[]> => {
    if (!container) return [];

    return container.type === ResourceType.BRANDFOLDER
      ? this.listBrandfolderSections(container.id)
      : this.listCollectionSections(container.id);
  };
}
