import React, { useContext } from 'react';
import { push } from 'connected-react-router';
import { generatePath, match, useLocation, useRouteMatch } from 'react-router-dom';
import { SearchParameters, SortOptions } from '@integration-frontends/integration/core/model';
import { compose, isNil, not } from 'ramda';
import { useDispatch } from 'react-redux';
import {
  ISelectAttachmentOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';
import { DI_CONTAINER } from '@integration-frontends/core';
import * as qs from 'qs';

export enum AttachmentSelectorView {
  ContainerSelector = 'ATTACHMENT_SELECTOR_CONTAINER_SELECTOR_VIEW',
  ShowPage = 'ATTACHMENT_SELECTOR_SHOW_PAGE_VIEW',
  AssetDetails = 'ATTACHMENT_SELECTOR_ASSET_DETAILS_VIEW',
  AssetDetailsNoContainer = 'ATTACHMENT_SELECTOR_ASSET_DETAILS_NO_CONTAINER',
  AttachmentDetails = 'ATTACHMENT_SELECTOR_ATTACHMENT_DETAILS_VIEW',
  AttachmentDetailsNoContainer = 'ATTACHMENT_SELECTOR_ATTACHMENT_DETAILS_VIEW_NO_CONTAINER',
}

export const ATTACHMENT_SELECTOR_ROUTES = {
  [AttachmentSelectorView.ContainerSelector]: '',
  [AttachmentSelectorView.ShowPage]: '/:containerId',
  [AttachmentSelectorView.AssetDetails]: '/:containerId/asset-details/:assetId',
  [AttachmentSelectorView.AssetDetailsNoContainer]: '/asset-details/:assetId',
  [AttachmentSelectorView.AttachmentDetails]: '/:containerId/attachment-details/:attachmentId',
  [AttachmentSelectorView.AttachmentDetailsNoContainer]: '/attachment-details/:attachmentId',
};

export interface AttachmentSelectorRouteParams {
  containerId?: string;
  assetId?: string;
  attachmentId?: string;
}

export const ATTACHMENT_SELECTOR_NAVIGATION_CONTEXT =
  React.createContext<{ navigationRootMatch: match }>(null);

export function useAttachmentSelectorNavigation() {
  const match = useRouteMatch<AttachmentSelectorRouteParams>();
  const location = useLocation();
  const dispatch = useDispatch();
  const { navigationRootMatch } = useContext(ATTACHMENT_SELECTOR_NAVIGATION_CONTEXT);
  const { customSortOptions }: ISelectAttachmentOptions = DI_CONTAINER.get(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );

  const urlSearch = qs.parse(location.search.substr(1)) as {
    searchParams?: string;
    sortOptions?: string;
  }; // substr(1) removes the leading '?'

  function getPathMatch(view: AttachmentSelectorView): string {
    return navigationRootMatch.path !== '/'
      ? `${navigationRootMatch.path}${ATTACHMENT_SELECTOR_ROUTES[view]}`
      : ATTACHMENT_SELECTOR_ROUTES[view];
  }

  function goToContainerSelector() {
    goTo(generatePath(ATTACHMENT_SELECTOR_ROUTES[AttachmentSelectorView.ContainerSelector]));
  }

  function getDefaultSortOptions() {
    return urlSearch.sortOptions ? JSON.parse(urlSearch.sortOptions) : customSortOptions || null;
  }

  function getDefaultSearchParams() {
    return urlSearch.searchParams ? JSON.parse(urlSearch.searchParams) : null;
  }

  function goToShowPage(
    containerId: string,
    searchParams: SearchParameters = getDefaultSearchParams(),
    sortOptions: SortOptions = getDefaultSortOptions(),
  ) {
    const serialize = compose(encodeURIComponent, JSON.stringify);
    const isDefined = compose(not, isNil);
    goTo(
      generatePath(ATTACHMENT_SELECTOR_ROUTES[AttachmentSelectorView.ShowPage], { containerId }),
      [
        searchParams && `searchParams=${serialize(searchParams)}`,
        sortOptions && `sortOptions=${serialize(sortOptions)}`,
      ]
        .filter(isDefined)
        .join('&'),
    );
  }

  function goToAssetDetails(assetId: string, containerId: string, attachmentId?: string) {
    const searchParams = new URLSearchParams(location.search);
    attachmentId && searchParams.append('selectedAttachmentId', attachmentId);

    goTo(
      generatePath(ATTACHMENT_SELECTOR_ROUTES[AttachmentSelectorView.AssetDetails], {
        assetId,
        containerId,
      }),
      searchParams.toString(),
    );
  }

  function goToAttachmentDetails(attachmentId: string, containerId: string) {
    goTo(
      generatePath(ATTACHMENT_SELECTOR_ROUTES[AttachmentSelectorView.AttachmentDetails], {
        attachmentId,
        containerId,
      }),
    );
  }

  function goToAttachmentDetailsNoContainer(attachmentId: string) {
    goTo(
      generatePath(
        ATTACHMENT_SELECTOR_ROUTES[AttachmentSelectorView.AttachmentDetailsNoContainer],
        {
          attachmentId,
        },
      ),
    );
  }

  function goTo(childRoute: string, search?: string) {
    dispatch(push({ pathname: `${navigationRootMatch.path}${childRoute}`, search }));
  }

  return {
    ...match.params,
    getPathMatch,
    goToAssetDetails,
    goToAttachmentDetails,
    goToAttachmentDetailsNoContainer,
    goToContainerSelector,
    goToShowPage,
  };
}
