import {
  AbsoluteDimensions,
  DEFAULT_ASSET_TYPES,
  FacebookAssetType,
  NULL_ABSOLUTE_DIMENSIONS,
  NULL_RELATIVE_DIMENSIONS,
  Platform,
  PLATFORM_ASSET_TYPE_DIMENSIONS,
  PlatformAssetType,
  RelativeDimensions,
  RelativeOffsets,
  SocialMediaPlatform,
} from '@integration-frontends/integration/core/model';
import { merge } from 'ramda';
import { PartialDeep } from 'type-fest';
import { Resizable } from '../common';
import { CropType } from './crop-type';

export interface SocialMediaCropOptions extends Resizable {
  type: CropType.SocialMedia;
  socialMedia: SocialMediaPlatform;
  assetType: PlatformAssetType;
  offsets: RelativeOffsets | null;
  newDimensions: RelativeDimensions | null;
}

export function createSocialMediaCropOptions(
  overrides: PartialDeep<SocialMediaCropOptions> = {},
): SocialMediaCropOptions {
  const defaults: SocialMediaCropOptions = {
    type: CropType.SocialMedia,
    socialMedia: Platform.Facebook,
    assetType: FacebookAssetType.Profile,
    offsets: null,
    initialDimensions: NULL_RELATIVE_DIMENSIONS,
    newDimensions: NULL_RELATIVE_DIMENSIONS,
    lockAspectRatio: true,
  };

  return merge(defaults, overrides) as SocialMediaCropOptions;
}

export function setSocialMedia(
  options: SocialMediaCropOptions,
  socialMedia: SocialMediaPlatform,
): SocialMediaCropOptions {
  return merge(options, { socialMedia, assetType: DEFAULT_ASSET_TYPES[socialMedia] });
}

export function setAssetType(
  options: SocialMediaCropOptions,
  assetType: PlatformAssetType,
): SocialMediaCropOptions {
  return merge(options, { assetType });
}

export function getSocialMediaImageOutputDimensions(
  options: SocialMediaCropOptions,
): AbsoluteDimensions {
  return (
    PLATFORM_ASSET_TYPE_DIMENSIONS[options.socialMedia]?.[options.assetType] ||
    NULL_ABSOLUTE_DIMENSIONS
  );
}
