import { BFButton, ButtonType } from '@integration-frontends/common/ui';
import {
  centerCropper,
  createFreeformCropOptions,
  CropOptions,
  getOutputDimensions,
  getRelativeCropDimensions,
  hasFixedOutputDimensions,
  setDimensions,
} from '@integration-frontends/integration/core/application';
import { Attachment } from '@integration-frontends/integration/core/model';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cropper, CropperOptions } from '../../../common/cropper';
import { CANCEL_KEY, SAVE_KEY } from '../../i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';

export interface AttachmentCropperProps {
  attachment: Attachment;
  cropOptions: CropOptions;
  onSave: (options: CropOptions) => void;
  onCancel: () => void;
}

export function AttachmentCropper({
  attachment,
  cropOptions,
  onSave,
  onCancel,
}: AttachmentCropperProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const cropperRef = useRef<any>(null);
  const [initialized, setInitialized] = useState(false);
  const [localCropOptions, setLocalCropOptions] = useState(
    cropOptions || createFreeformCropOptions(),
  );

  useEffect(() => {
    if (!initialized) {
      cropperRef.current && cropperRef.current.setCropOptions(cropOptions);
      setInitialized(true);
    }
  }, [cropperRef.current]);

  function cropImage(options: CropOptions) {
    options && setLocalCropOptions(options);
  }

  function cropperOptionsChanged(options: CropOptions) {
    options = hasFixedOutputDimensions(options)
      ? centerCropper(
          setDimensions(
            options,
            getRelativeCropDimensions(
              attachment.dimensions,
              getOutputDimensions(attachment.dimensions, options),
            ),
          ),
        )
      : options;

    setLocalCropOptions(options);
    cropperRef.current.setCropOptions(options);
  }

  return (
    <div className="w-full">
      <div className="mb-md">
        <Cropper key={attachment.id} attachment={attachment} onCrop={cropImage} ref={cropperRef} />
      </div>

      <div className="mb-xl">
        <CropperOptions options={localCropOptions} onChange={cropperOptionsChanged} />
      </div>

      <BFButton
        buttonType={ButtonType.Primary}
        className="w-full px-sm mb-sm"
        onClick={() => onSave(localCropOptions)}
      >
        {t(SAVE_KEY)}
      </BFButton>
      <BFButton buttonType={ButtonType.Secondary} className="w-full px-sm" onClick={onCancel}>
        {t(CANCEL_KEY)}
      </BFButton>
    </div>
  );
}
