import { assetCustomFieldValueEntitySelectors } from '@integration-frontends/integration/core/application';
import { Asset } from '@integration-frontends/integration/core/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SectionTitle } from '../../../common/layout';

import { AssetCustomFieldValues } from '../../common/asset-custom-field-values';
import { CUSTOM_FIELDS_KEY } from '../../i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import {
  PageSection,
  PageSectionContent,
} from '@integration-fontends/integration/ui/attachment-selector/common/layout/page';

export interface CustomFieldsProps {
  asset: Asset;
}

export function CustomFields({ asset }: CustomFieldsProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const customFieldValues = useSelector(assetCustomFieldValueEntitySelectors.byAssetId(asset.id));

  return (
    <>
      {customFieldValues.length > 0 && (
        <>
          <PageSection>
            <PageSectionContent>
              <SectionTitle>{t(CUSTOM_FIELDS_KEY)}</SectionTitle>
              <div>
                <AssetCustomFieldValues customFieldValues={customFieldValues} />
              </div>
            </PageSectionContent>
          </PageSection>
        </>
      )}
    </>
  );
}
