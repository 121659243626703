import {
  EMPTY_SEARCH_PARAMS,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import React from 'react';
import { useAttachmentSelectorNavigation } from '../../../navigation';
import { COMPONENT_RESOLVER_TOKEN, ComponentResolver } from '@integration-frontends/integration/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import { FILTERS_DISPLAY_COMPONENT_KEY, FiltersDisplay } from './filters-display';

export interface FiltersDisplayContainerProps {
  searchParams: SearchParameters;
}

export function FiltersDisplayContainer({ searchParams }: FiltersDisplayContainerProps) {
  const navigation = useAttachmentSelectorNavigation();
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const FiltersDisplayComponent =
    componentResolver.getComponent(FILTERS_DISPLAY_COMPONENT_KEY) || FiltersDisplay;

  function clear() {
    navigation.goToShowPage(navigation.containerId, EMPTY_SEARCH_PARAMS);
  }

  return <FiltersDisplayComponent searchParams={searchParams} onClear={clear} />;
}
