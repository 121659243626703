import React, { HTMLAttributes } from 'react';
import { createIcon } from './create-icon';

// Placeholders

function IconOrientationBase({ style = {} }: Pick<HTMLAttributes<any>, 'style'>) {
  return (
    <span style={{ width: 20, height: 20, ...style }}>
      <span
        className="w-full h-full border border-bf-light-gray block"
        style={{ borderWidth: 2 }}
      />
    </span>
  );
}

export const IconOrientationHorizontal = createIcon(
  <IconOrientationBase style={{ paddingTop: '15%', paddingBottom: '15%' }} />,
);

export const IconOrientationVertical = createIcon(
  <IconOrientationBase style={{ paddingLeft: '15%', paddingRight: '15%' }} />,
);

export const IconOrientationSquare = createIcon(<IconOrientationBase />);

export const IconOrientationPanoramic = createIcon(
  <IconOrientationBase style={{ paddingTop: '22%', paddingBottom: '22%' }} />,
);
