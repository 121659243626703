import React, { ReactElement } from 'react';
import { PrimaryLogoWhite } from '../logo';

export interface PanelHeaderProps {
  actions: ReactElement[];
}

export function PanelHeader({ actions }: PanelHeaderProps) {
  return (
    <div
      style={{ height: 50 }}
      className="px-lg py-md bg-bf-blueberry-delight flex items-center justify-between"
    >
      <div>
        <PrimaryLogoWhite style={{ width: 75, height: 20 }} />
      </div>
      <div className="cursor-pointer flex">
        {actions.map((action, idx) => (
          <div
            key={idx}
            className="ml-2 text-6xl text-white flex items-center"
          >
            {action}
          </div>
        ))}
      </div>
    </div>
  );
}
