import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import './hr.scss';

export function BFHR({ className, ...props }: HTMLAttributes<HTMLHRElement>) {
  return (
    <hr
      className={classNames('bf-hr', className)}
      {...props}
    />
  );
}
