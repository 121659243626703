import { Disclosure } from '@headlessui/react';
import { BFAnchor, BFCaretToggle, IconClose, IconLabel, IconSize, IconLabelsCheckMark } from '@integration-frontends/common/ui';
import {
  containerSelectors,
  labelEntitySelectors,
  searchAssetsSelectors,
} from '@integration-frontends/integration/core/application';
import {
  hasDescendant,
  Label,
  LabelTree,
  positionComparator,
  removeLabelFilter,
  setLabelFilter,
} from '@integration-frontends/integration/core/model';
import { Body, Nav } from '@integration-frontends/integration/ui/common/layout';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HIDE_LABELS_KEY, LABELS_TITLE_KEY } from '../../i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '../../../common/i18n';
import { useAttachmentSelectorNavigation } from '../../navigation';
import './labels-selector-view.scss';

interface LabelsSelectorProps {
  labelTrees: LabelTree[];
  selectedLabel?: Label;
  onLabelSelect: (label: Label) => void;
}

function LabelsSelector({ labelTrees, selectedLabel, onLabelSelect }: LabelsSelectorProps) {


  return (
    <div>
      {labelTrees.sort(positionComparator).map((rootNode) => (
        <LabelNode key={rootNode.id} node={rootNode} selectedLabel={selectedLabel} onLabelSelect={onLabelSelect}/>
      ))}
    </div>
  );
}

interface LabelNodeProps {
  node: LabelTree;
  selectedLabel?: Label;
  onLabelSelect: (label: Label) => void;
}

function LabelNode({ node, selectedLabel, onLabelSelect }: LabelNodeProps) {
  const nodeLabelElement = (
    <span
      className="cursor-pointer pl-xxs min-w-16 flex items-center hover:bg-bf-marshmallow"
      style={{ height: 35, minWidth: 200 }}
      onClick={() => onLabelSelect(node)}
      data-testid="label-selector-option"
    >
      <IconLabel className="pr-xs" iconSize={IconSize.Medium} />
      {node.name}
      {selectedLabel?.id === node?.id &&
        <IconLabelsCheckMark className="pl-xs" iconSize={IconSize.Large} />
      }
    </span>
  );

  return (
    <>
      {node.children?.length > 0 ? (
        <Disclosure defaultOpen={hasDescendant(selectedLabel, node)}>
          {({ open }) => (
            <>
              <div className="flex mb-xxs">
                <Disclosure.Button as="span">
                  <BFCaretToggle open={open} />
                </Disclosure.Button>
                {nodeLabelElement}

              </div>
              <Disclosure.Panel as="div" className="pl-xl">
                {node.children.sort(positionComparator).map((childNode) => (
                  <LabelNode key={childNode.id} node={childNode} selectedLabel={selectedLabel} onLabelSelect={onLabelSelect}/>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ) : (
        <div className="flex items-center pl-xxl mb-xxs">{nodeLabelElement}</div>
      )}
    </>
  );
}

export interface LabelsSelectorViewProps {
  onClose: () => void;
}

export function LabelsSelectorView({ onClose }: LabelsSelectorViewProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const navigation = useAttachmentSelectorNavigation();
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, navigation.containerId),
  );
  const labelTrees = useSelector(labelEntitySelectors.labelTreesByContainer(selectedContainer));
  const searchParams = useSelector(searchAssetsSelectors.searchParams);

  function clear() {
    navigation.goToShowPage(selectedContainer.id, removeLabelFilter(searchParams));
    onClose();
  }

  const onLabelSelected = useCallback((label: Label) => {
    navigation.goToShowPage(selectedContainer.id, setLabelFilter(label, searchParams));
    onClose();
  }, []);

  return (
    <div className="labels-selector-view">
      <div className="justify-between px-lg  flex items-center ">
        <span className="font-bold labels-title">{t(LABELS_TITLE_KEY)}</span>
        <BFAnchor onClick={onClose}>{t(HIDE_LABELS_KEY)}</BFAnchor>
      </div>
      <Body>
      <h4 className="labels-selector-container-name">{selectedContainer?.name}</h4>{' '}
        <LabelsSelector
          labelTrees={labelTrees}
          selectedLabel={searchParams?.labelFilter}
          onLabelSelect={onLabelSelected}
        />
      </Body>
    </div>
  );
}
