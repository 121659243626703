import { Container } from '@integration-frontends/integration/core/model';
import { any, append, curry, find, indexBy, map, merge, prop } from 'ramda';
import { ResourceBase, ResourceType } from './resource-base';

export interface Label extends ResourceBase {
  containerId: string;
  type: ResourceType.LABEL;
  path: string[];
  depth: number;
}

export const labelEquals = curry(
  (label1: Label, label2: Label): boolean => label1.id === label2.id,
);

export type LabelTree = Label & { children: LabelTree[] };

export function getLabelParentId(label: Label): string {
  return label.path[label.path.length - 2] || null;
}

export function initTreeNode(label: Label): LabelTree {
  return merge(label, { children: [] });
}

export function addTreeNodeChild(node: LabelTree, child: LabelTree): LabelTree {
  node.children = append(child, node.children);
  return node;
}

export function generateLabelTrees(labels: Label[]): LabelTree[] {
  const indexedTreeNodes = labels.reduce((indexedTreeNodes, label) => {
    const parentId = getLabelParentId(label);
    if (parentId) {
      indexedTreeNodes[parentId] = addTreeNodeChild(
        indexedTreeNodes[parentId],
        indexedTreeNodes[label.id],
      );
    }

    return indexedTreeNodes;
  }, indexBy(prop('id'), map(initTreeNode, labels)));

  return Object.values(indexedTreeNodes).filter((treeNode) => treeNode.depth === 1);
}

export const hasDescendant = curry((descendant: Label, node: LabelTree): boolean => {
  return (
    (!!descendant && !!find(labelEquals(descendant), node.children)) ||
    any(hasDescendant(descendant), node.children)
  );
});

export const LABEL_REPO_TOKEN = 'LABEL_REPO';
export interface ILabelRepo {
  getContainerLabels(container: Container): Promise<Label[]>;
}
