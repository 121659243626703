import { searchFilterSlice } from '@integration-frontends/integration/core/application/common/entities-state/entities/search-filter';
import { combineReducers } from '@reduxjs/toolkit';
import {
  assetCustomFieldValueSlice,
  assetSlice,
  assetTagSlice,
  attachmentSlice,
  brandfolderSlice,
  collectionSlice,
  containerCustomFieldSlice,
  containerFileTypeAggregatesSlice,
  containerTagSlice,
  brandfolderTagSlice,
  labelSlice,
  organizationSlice,
  sectionSlice,
} from './entities';

export const ENTITIES_STATE_REDUCER = combineReducers({
  attachments: attachmentSlice.reducer,
  assetCustomFieldValues: assetCustomFieldValueSlice.reducer,
  assets: assetSlice.reducer,
  assetTags: assetTagSlice.reducer,
  brandfolders: brandfolderSlice.reducer,
  brandfolderTags: brandfolderTagSlice.reducer,
  containerCustomFields: containerCustomFieldSlice.reducer,
  containerFileTypeAggregates: containerFileTypeAggregatesSlice.reducer,
  containerTags: containerTagSlice.reducer,
  collections: collectionSlice.reducer,
  labels: labelSlice.reducer,
  organizations: organizationSlice.reducer,
  searchFilters: searchFilterSlice.reducer,
  sections: sectionSlice.reducer,
});
