import { SectionContent } from '@integration-fontends/integration/ui/attachment-selector/components/advanced-filters/common';
import { BFLabel, BFSelect } from '@integration-frontends/common/ui';
import {
  INTEGRATION_COMMON_NAMESPACE,
  SELECT_LABEL_KEY,
} from '@integration-frontends/integration/ui/common/i18n';
import React from 'react';
import {
  ContainerCustomField,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import { useTranslation } from 'react-i18next';

export interface PrioritizedCustomFieldsFiltersComponentProps {
  customFields: ContainerCustomField[];
  searchParams: SearchParameters;
  onChange: (field: ContainerCustomField, value: string) => void;
}

export function PrioritizedCustomFieldsFiltersComponent({
  customFields,
  searchParams,
  onChange,
}: PrioritizedCustomFieldsFiltersComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <SectionContent className="py-lg">
      <div className="flex flex-col gap-lg">
        {customFields
          .filter((field) => field.prioritized)
          .map((field) => (
            <div key={field.id} className="flex flex-col">
              <BFLabel htmlFor={field.name}>{field.name}</BFLabel>
              <BFSelect
                id={field.name}
                required
                placeholder={t(SELECT_LABEL_KEY)}
                options={field.allowedValues.map((value) => ({ label: value, value }))}
                value={searchParams?.customFieldFilters?.[field.id]?.value || ''}
                onOptionChange={(option) => onChange(field, option?.value as string)}
                className="text-primary text-base"
              />
            </div>
          ))}
      </div>
    </SectionContent>
  );
}
