import { IconAlignCenter, IconAlignLeft, IconAlignRight } from '@integration-frontends/common/ui';
import {
  Alignment,
  AlignmentOptions,
  setAlignment,
} from '@integration-frontends/integration/core/application';
import classNames from 'classnames';
import React from 'react';

export interface AlignmentProps {
  options: AlignmentOptions;
  onChange: (options: AlignmentOptions) => void;
}

export function Align({ options, onChange }: AlignmentProps) {
  function createAlignButton(alignment: Alignment, IconComponent) {
    return (
      <IconComponent
        className={classNames('w-full h-full', 'cursor-pointer', {
          'text-bf-purple-popsicle': alignment === options.alignment,
        })}
        onClick={() => onChange(setAlignment(options, alignment))}
      />
    );
  }

  return (
    <div className="flex w-24">
      {createAlignButton(Alignment.Left, IconAlignLeft)}
      {createAlignButton(Alignment.Center, IconAlignCenter)}
      {createAlignButton(Alignment.Right, IconAlignRight)}
    </div>
  );
}
