import { EditAttachmentState } from '@integration-frontends/integration/core/application';
import { createSelector } from '@reduxjs/toolkit';

export const selectLoading = (state: EditAttachmentState) => state.loading;

export function createEditAttachmentSelectors(stateSelector: (rootState) => EditAttachmentState) {
  return {
    loading: createSelector(stateSelector, selectLoading),
  };
}
