import { StateSelector } from '@integration-frontends/common/utils/redux';
import { containerCustomFieldAdapter } from '@integration-frontends/integration/core/application/common/entities-state/entities';
import { ContainerCustomField } from '@integration-frontends/integration/core/model';
import { EntityState } from '@reduxjs/toolkit';

export function createContainerCustomFieldSelectors(
  stateSelector: StateSelector<EntityState<ContainerCustomField>>,
) {
  return containerCustomFieldAdapter.getSelectors(stateSelector);
}
