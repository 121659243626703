import { IntegrationEntitiesSelectors } from '@integration-frontends/integration/core/application';
import { createSelector } from '@reduxjs/toolkit';
import { UploadAssetsState } from './reducer';

const selectAssets = (state: UploadAssetsState) => state.assets;
const selectLoading = (state: UploadAssetsState) => state.loading;
const selectSelectedContainerId = (state: UploadAssetsState) => state.selectedContainerId;
const selectSelectedOrganizationId = (state: UploadAssetsState) => state.selectedOrganizationId;
const selectSelectedSectionId = (state: UploadAssetsState) => state.selectedSectionId;
const selectSuccess = (state: UploadAssetsState) => state.success;
const selectUploading = (state: UploadAssetsState) => state.uploading;

export function createUploadAssetSelectors(
  stateSelector: (rootState) => UploadAssetsState,
  entitiesSelectors: IntegrationEntitiesSelectors,
) {
  const baseSelectors = {
    assets: createSelector(stateSelector, selectAssets),
    loading: createSelector(stateSelector, selectLoading),
    selectedContainerId: createSelector(stateSelector, selectSelectedContainerId),
    selectedOrganizationId: createSelector(stateSelector, selectSelectedOrganizationId),
    selectedSectionId: createSelector(stateSelector, selectSelectedSectionId),
    success: createSelector(stateSelector, selectSuccess),
    uploading: createSelector(stateSelector, selectUploading),
  };

  return {
    ...baseSelectors,
    selectedOrganization: createSelector(
      baseSelectors.selectedOrganizationId,
      entitiesSelectors.organization.selectEntities,
      (id, orgEntities) => orgEntities[id],
    ),
    selectedContainer: createSelector(
      baseSelectors.selectedContainerId,
      entitiesSelectors.container.selectAll,
      (id, containers) => containers.find((c) => c.id === id),
    ),
    selectedSection: createSelector(
      baseSelectors.selectedSectionId,
      entitiesSelectors.section.selectEntities,
      (id, sectionEntities) => sectionEntities[id],
    ),
  };
}
