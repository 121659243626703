import {
  CUSTOM_DIMENSIONS,
  CustomDimension,
  Platform,
  PLATFORM_ASSET_TYPE_DIMENSIONS,
  PlatformAssetType,
  SocialMediaPlatform,
} from '@integration-frontends/integration/core/model';
import { CropType } from '@integration-frontends/integration/core/application';
import {
  getCropTypeKey,
  getCustomDimensionKey,
  getPlatformAssetTypeKey,
  getPlatformKey,
} from '../../common/i18n';

export interface Option<T extends string | number = string | number> {
  i18nKey: string;
  value: T;
}

export function getCropTypeOptions(): Option<CropType>[] {
  return [
    { i18nKey: getCropTypeKey(CropType.Freeform), value: CropType.Freeform },
    { i18nKey: getCropTypeKey(CropType.SocialMedia), value: CropType.SocialMedia },
    { i18nKey: getCropTypeKey(CropType.Custom), value: CropType.Custom },
  ];
}

export function getSocialMediaOptions(): Option<SocialMediaPlatform>[] {
  return [
    { i18nKey: getPlatformKey(Platform.Facebook), value: Platform.Facebook },
    { i18nKey: getPlatformKey(Platform.Twitter), value: Platform.Twitter },
    { i18nKey: getPlatformKey(Platform.LinkedIn), value: Platform.LinkedIn },
    { i18nKey: getPlatformKey(Platform.Instagram), value: Platform.Instagram },
    { i18nKey: getPlatformKey(Platform.Pinterest), value: Platform.Pinterest },
  ];
}

export function getSocialMediaImageSizeOptions(socialMedia: SocialMediaPlatform): Option[] {
  return Object.keys(PLATFORM_ASSET_TYPE_DIMENSIONS[socialMedia] ?? []).map((type) => {
    return {
      i18nKey: getPlatformAssetTypeKey(socialMedia, type as PlatformAssetType),
      value: type,
    };
  });
}

export function getCustomImageSizeOptions(): Option[] {
  return Object.keys(CUSTOM_DIMENSIONS).map((customDimension) => {
    return {
      i18nKey: getCustomDimensionKey(customDimension as CustomDimension),
      value: customDimension,
    };
  });
}
