import { Brandfolder, Collection, ResourceBase, ResourceType } from './entities';

export type Container = Brandfolder | Collection;
export interface ContainerCustomField extends ResourceBase {
  type: ResourceType.CUSTOM_FIELD;
  containerId: string;
  searchable: boolean;
  prioritized: boolean;
  allowedValues: string[];
}

export interface ContainerTag extends ResourceBase {
  type: ResourceType.TAG;
  containerId: string;
  count: number;
  searchable: boolean;
}

export interface ContainerFileTypeAggregates {
  containerId: string;
  name: string;
  count: number;
}

export const CONTAINER_REPO_TOKEN = 'CONTAINER_REPO';
export interface IContainerRepo {
  getContainer: (containerId: string) => Promise<Container>;
  getContainerCustomFields: (container: Container) => Promise<ContainerCustomField[]>;
  getContainerTags: (container: Container) => Promise<ContainerTag[]>;
  getContainerFileTypeAggregates: (container: Container) => Promise<ContainerFileTypeAggregates[]>;
}
