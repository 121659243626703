import React, { HTMLProps } from 'react';

export interface ContentGroupProps {
  title: string;
}

export function ContentGroup({ title, children }: ContentGroupProps & HTMLProps<any>) {
  return (
    <div>
      <h2 className="py-4 text-primary">{title}</h2>
      {children}
    </div>
  );
}
