import { BFButton, BFErrorLabel, BFHR, BFLabel } from '@integration-frontends/common/ui';
import {
  AlignmentOptions,
  attachmentEdited,
  AttachmentEditOptions,
  attachmentEntitySelectors,
  centerCropper,
  createAlignmentOptions,
  createLabelOptions,
  createLinkOptions,
  createResizingOptions,
  CropOptions,
  editAttachmentCanceled,
  editAttachmentInit,
  getOutputDimensions,
  getRelativeCropDimensions,
  hasFixedOutputDimensions,
  IntegrationRootState,
  LabelOptions,
  LinkOptions,
  ResizingOptions,
  setDimensions,
} from '@integration-frontends/integration/core/application';
import { DimensionType, isImage } from '@integration-frontends/integration/core/model';
import { supportsCdnTransforms } from '@integration-frontends/integration/infrastructure/isomorphic';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Cropper, CropperOptions } from '../common/cropper';
import { SectionTitle } from '../common/layout';
import { Align } from './align';
import { Label } from './label';
import { Link } from './link';
import { Resizer } from './resizer';
import { CANCEL_KEY, CROP_KEY, CROPPING_NOT_SUPPORTED_KEY, PREVIEW_KEY } from './i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import { useAttachmentTransforms } from '@integration-fontends/integration/ui/attachment-selector/common/attachment-transforms/use-attachment-transforms';

export interface AttachmentEditorProps {
  attachmentId: string;
  options?: AttachmentEditOptions;
}

export function AttachmentEditor({ attachmentId, options = {} }: AttachmentEditorProps) {
  const dispatch = useDispatch();
  const [alignmentOptions, setAlignmentOptions] = useState<AlignmentOptions>(
    options.alignmentOptions || createAlignmentOptions(),
  );
  const [labelOptions, setLabelOptions] = useState<LabelOptions>(
    options.labelOptions || createLabelOptions(),
  );
  const [linkOptions, setLinkOptions] = useState<LinkOptions>(
    options.linkOptions || createLinkOptions(),
  );
  const attachment = useSelector((state: IntegrationRootState) =>
    attachmentEntitySelectors.selectById(state, attachmentId),
  );
  const { userResizingOptions, resized, cropOptions, setCropOptions } = useAttachmentTransforms({
    attachment,
  });
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const cropperRef = useRef<any>(null);

  useEffect(() => {
    if (attachment && cropperRef.current && !initialized) {
      setInitialized(true);
      resized(
        createResizingOptions({
          initialDimensions: attachment.dimensions,
          newDimensions: attachment.dimensions,
        }),
      );

      cropperRef.current.setCropOptions(cropOptions);
    }
  }, [attachment, !!cropperRef.current]);

  useEffect(() => {
    dispatch(editAttachmentInit({ attachmentId }));
  }, [attachmentId]);

  function resizeImage({ newDimensions: { width, height } }: ResizingOptions) {
    resized(
      setDimensions(userResizingOptions, {
        width: width / cropOptions.newDimensions.width,
        height: height / cropOptions.newDimensions.height,
        type: DimensionType.Absolute,
      }),
    );
  }

  function resolveResizingOptions(): ResizingOptions {
    const outputDimensions = getOutputDimensions(
      userResizingOptions?.newDimensions || attachment.dimensions,
      cropOptions,
    );

    return createResizingOptions(setDimensions(userResizingOptions, outputDimensions));
  }

  function cropImage(options: CropOptions) {
    options && setCropOptions(options);
  }

  function cropperOptionsChanged(options: CropOptions) {
    options = hasFixedOutputDimensions(options)
      ? centerCropper(
          setDimensions(
            options,
            getRelativeCropDimensions(
              attachment.dimensions,
              getOutputDimensions(attachment.dimensions, options),
            ),
          ),
        )
      : options;

    setCropOptions(options);
    cropperRef.current.setCropOptions(options);
  }

  function previewImage() {
    dispatch(
      attachmentEdited({
        attachment,
        options: {
          alignmentOptions: alignmentOptions,
          cropOptions: cropOptions,
          labelOptions: labelOptions,
          resizingOptions: resolveResizingOptions(),
          linkOptions: linkOptions,
        },
      }),
    );
  }

  return (
    <>
      {attachment && (
        <div data-cy="attachment-editor" data-testid="attachment-editor" className="pt-lg">
          <div className="mb-lg flex flex-col gap-md">
            <div className="flex items-center">
              <BFLabel>Alignment: </BFLabel>
              <Align options={alignmentOptions} onChange={setAlignmentOptions} />
            </div>

            <Label options={labelOptions} onChange={setLabelOptions} />
            <Link options={linkOptions} onChange={setLinkOptions} />
            <Resizer options={resolveResizingOptions()} onChange={resizeImage} />
          </div>

          <BFHR className="mb-md" />

          <div className="flex flex-col gap-md">
            {isImage(attachment.mimetype) && (
              <>
                <SectionTitle>{t(CROP_KEY)}</SectionTitle>

                {supportsCdnTransforms(attachment.url) ? (
                  <div className="flex flex-col gap-md w-full">
                    <Cropper
                      key={attachment.id}
                      attachment={attachment}
                      onCrop={cropImage}
                      ref={cropperRef}
                    />

                    <div className="flex-none">
                      <CropperOptions options={cropOptions} onChange={cropperOptionsChanged} />
                    </div>
                  </div>
                ) : (
                  <BFErrorLabel>{t(CROPPING_NOT_SUPPORTED_KEY)}</BFErrorLabel>
                )}
              </>
            )}

            <div className="w-full flex justify-end gap-xs">
              <BFButton
                data-cy="cancel-action"
                onClick={() => dispatch(editAttachmentCanceled({ attachment }))}
              >
                {t(CANCEL_KEY)}
              </BFButton>
              <BFButton data-cy="import-action" onClick={previewImage}>
                {t(PREVIEW_KEY)}
              </BFButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
