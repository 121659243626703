import placeForeground from '@integration-frontends/assets/place-foreground-icon.svg';
import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { createIcon } from './create-icon';

export const IconPlaceForegroundInactive = createIcon(
  <img
    src={placeForeground}
    alt="Place to background"
    style={{
      filter:
        'invert(84%) sepia(97%) saturate(0%) hue-rotate(181deg) brightness(88%) contrast(83%)',
    }}
  />,
);

export const IconPlaceForegroundActive = createIcon(
  <img src={placeForeground} alt="Place to background" />,
);

export type IconPlaceForegroundProps = HTMLAttributes<HTMLDivElement> & {
  active: boolean;
};

export function IconPlaceForeground({ active }: IconPlaceForegroundProps) {
  return (
    <div className={classNames('flex items-center cursor-pointer, className')}>
      {active ? <IconPlaceForegroundActive /> : <IconPlaceForegroundInactive />}
    </div>
  );
}
