
export enum VideoType {
  '3gp' = '3GP',
  M4f = 'M4F',
  Mj2 = 'MJ2',
  Mkv = 'MKV',
  Mov = 'MOV',
  Mp4 = 'MP4',
  Ogg = 'OGG',
  Ogv = 'OGV',
  Webm = 'WEBM',
}

export const ALL_VIDEO_TYPES = [
  VideoType['3gp'],
  VideoType.M4f,
  VideoType.Mj2,
  VideoType.Mkv,
  VideoType.Mov,
  VideoType.Mp4,
  VideoType.Ogg,
  VideoType.Ogv,
  VideoType.Webm,
];

export const VIDEO_TYPE_EXTENSIONS = {
  [VideoType['3gp']]: ['3gp'],
  [VideoType.M4f]: ['m4f'],
  [VideoType.Mj2]: ['mj2'],
  [VideoType.Mkv]: ['mkv'],
  [VideoType.Mov]: ['mov'],
  [VideoType.Mp4]: ['mp4'],
  [VideoType.Ogg]: ['ogg'],
  [VideoType.Ogv]: ['ogv'],
  [VideoType.Webm]: ['webm'],
};

export const VIDEO_TYPE_MIME_TYPES = {
  [VideoType['3gp']]: ['video/3gpp'],
  [VideoType.M4f]: ['video/m4f'],
  [VideoType.Mj2]: ['video/mj2'],
  [VideoType.Mkv]: ['video/x-matroska'],
  [VideoType.Mov]: ['video/quicktime'],
  [VideoType.Mp4]: ['video/mp4'],
  [VideoType.Ogg]: ['video/ogg'],
  [VideoType.Ogv]: ['video/ogv'],
  [VideoType.Webm]: ['video/webm'],
};

export function getVideoTypeFromMimeType(mimeType: string): VideoType {
  let record: [string, string[]];
  if (mimeType !== null) {
    record = Object.entries(VIDEO_TYPE_MIME_TYPES).find(([, mimeTypes]) =>
      mimeTypes.includes(mimeType.toLocaleLowerCase()),
    );
  }

  return (record?.[0] as VideoType) || null;
}
