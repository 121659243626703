/**
 * Modules allow libraries to define dependencies to other libraries in a way
 * that simplifies bootstrapping all that's needed to run the exposed functionality.
 */

import { Container, interfaces } from 'inversify';
import { Class } from 'type-fest';
import { DI_CONTAINER } from './di-container';

export interface Provider<T> {
  to?: Class<T>;
  toConstantValue?: T;
  toDynamicValue?: (context: interfaces.Context) => T;
  toFactory?: interfaces.FactoryCreator<T>;
}

export type Providers = { [token: string]: Provider<unknown> };

export interface BFModule<T extends Providers = Providers> {
  name: string;
  providers: T;
  provideDependencies?: (providers: T) => BFModule[];
}

export function bootstrap(appModule: BFModule) {
  loadDependencies(appModule);
}

function loadDependencies(module: BFModule) {
  const dependencies = module.provideDependencies?.(module.providers);
  if (dependencies?.length) {
    return dependencies.forEach(loadDependencies);
  }

  loadProviders(module);
}

function loadProviders(module: BFModule) {
  if (module.providers) {
    const container = new Container();
    Object.entries(module.providers).forEach(([token, provider]) => {
      if (provider.to) {
        container.bind(token).to(provider.to);
      } else if (provider.toConstantValue) {
        container.bind(token).toConstantValue(provider.toConstantValue);
      } else if (provider.toDynamicValue) {
        container.bind(token).toDynamicValue(provider.toDynamicValue);
      } else if (provider.toFactory) {
        container.bind(token).toFactory(provider.toFactory);
      }
    });

    DI_CONTAINER.merge(container);
  }
}
