import { withPayloadType } from '@integration-frontends/common/utils/redux';
import {
  AssetsListResultSet,
  Container,
  PagedResults,
} from '@integration-frontends/integration/core/model';
import { createAction } from '@reduxjs/toolkit';

export function createAssetsListBySectionActions(namespace: string) {
  return {
    sectionsLoaded: createAction(
      `${namespace}_ASSETS_LIST_SECTIONS_LOADED`,
      withPayloadType<{ sectionIds: string[] }>(),
    ),
    nextSectionPage: createAction(
      `${namespace}_ASSETS_LIST_SECTION_NEXT_PAGE`,
      withPayloadType<{ container: Container; sectionId: string }>(),
    ),
    previousSectionPage: createAction(
      `${namespace}_ASSETS_LIST_SECTION_PREVIOUS_PAGE`,
      withPayloadType<{ container: Container; sectionId: string }>(),
    ),
    sectionPageLoaded: createAction(
      `${namespace}_ASSETS_LIST_SECTION_PAGE_LOADED`,
      withPayloadType<{ sectionId: string; results: PagedResults<AssetsListResultSet> }>(),
    ),
    toggleShowAttachment: createAction(`${namespace}_ASSETS_LIST_TOGGLE_SHOW_ATTACHMENT`),
  };
}
