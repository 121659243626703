import { BFInput, BFLabel } from '@integration-frontends/common/ui';
import { LinkOptions, setLink } from '@integration-frontends/integration/core/application';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMAGE_LINK_LABEL_KEY, INTEGRATION_COMMON_NAMESPACE } from '../common/i18n';

export interface LinkProps {
  options: LinkOptions;
  onChange: (link: LinkOptions) => void;
}

export function Link({ options, onChange }: LinkProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <div className="flex flex-col w-full">
      <BFLabel>{t(IMAGE_LINK_LABEL_KEY)}</BFLabel>
      <BFInput
        type="text"
        value={options.link}
        onChange={(e) => onChange(setLink(e.target.value, options))}
      />
    </div>
  );
}
