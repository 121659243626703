import { DI_CONTAINER } from '@integration-frontends/core';
import { ASSET_REPO_TOKEN, IAssetRepo, USER_SETTINGS_REPO_TOKEN, IUserSettingsRepo } from '@integration-frontends/integration/core/model';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { upload, uploadSuccess } from '../actions';

const handler = function* (action: ReturnType<typeof upload>) {
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const userSettingsRepo: IUserSettingsRepo = DI_CONTAINER.get(USER_SETTINGS_REPO_TOKEN);
  const { organizationId, container, sectionId, assets, source, savePreferences, tags = [] } = action.payload;
  if (savePreferences) {
    yield call(userSettingsRepo.setAssetUploadPreference, organizationId, container.id, sectionId);
  }
  const files = yield all(
    assets.map((asset) =>
      call(async (url: string) => {
        const response = await fetch(url);
        return response.blob().then((blob) => new File([blob], asset.name, { type: blob.type }));
      }, asset.url),
    ),
  );
  const newAsset = yield call(assetRepo.create, container, sectionId, assets[0].name, files, source);
  if (tags?.length) {
    yield call(assetRepo.addAssetTags, newAsset.data.map((asset) => asset.id), tags.map((tag) => tag.value));
  }
  yield put(uploadSuccess());
};

export function* uploadEffects() {
  yield takeEvery(upload, handler);
}
