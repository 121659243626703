import {
  AuthError,
  Credentials,
  Identity,
} from '@integration-frontends/common/auth/core/model';
import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';

export const loginAttempt = createAction(
  'AUTH_LOGIN_ATTEMPT',
  withPayloadType<{ credentials: Credentials }>()
);
export const loginError = createAction(
  'AUTH_LOGIN_ERROR',
  withPayloadType<{ error: AuthError }>()
);
export const loginSuccess = createAction(
  'AUTH_LOGIN_SUCCESS',
  withPayloadType<{ identity: Identity }>()
);
export const logout = createAction('AUTH_LOGOUT');
export const clear = createAction('AUTH_CLEAR');

export type AuthActionTypes =
  | ReturnType<typeof loginAttempt>
  | ReturnType<typeof loginError>
  | ReturnType<typeof loginSuccess>
  | ReturnType<typeof logout>;
