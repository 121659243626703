import { BrandfolderTag } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const brandfolderTagAdapter = createEntityAdapter<BrandfolderTag>();

export const brandfolderTagSlice = createSlice({
  initialState: brandfolderTagAdapter.getInitialState(),
  name: 'brandfolderTags',
  reducers: {
    brandfolderTagsReceived: brandfolderTagAdapter.upsertMany,
  },
});
