import { DI_CONTAINER } from '@integration-frontends/core';
import { Loader, ScrollingContainerRefContext } from '@integration-frontends/common/ui';
import {
  containerSelectors,
  ISelectAttachmentOptions,
  searchAssetsSelectors,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  selectAttachmentSelectors,
  SelectionType,
  showPageEntered,
  showPageScrollStopped,
  showPageSearch,
} from '@integration-frontends/integration/core/application';
import {
  hasFilters,
  ResourceBaseSortableProperty,
  SearchParameters,
  Section,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IntegrationPageLoader } from '@integration-frontends/integration/ui/common/page-loader/page-loader';
import { AssetsListContainer } from './assets-list';
import { FiltersDisplayContainer } from './filters-display/filters-display-container';
import { LabelsSelectorView } from './labels-selector-view';
import { ShowPageNav } from './nav/nav';
import { MultiSelectModal, MultiSelectOverlay } from '@integration-frontends/integration/ui';
import { useAttachmentSelectorNavigation } from '../../navigation';
import * as qs from 'qs';
import { ResultsDisplay } from './results-display';
import { noop } from 'lodash';
import { AttachmentSelectorFeature } from '../../../features';
import { useFeatures } from '../../common/use-features';
import { NotificationsContainer } from '../../../common/notifications/notifications';
import { ShowPageControls } from './show-page-controls/show-page-controls';
import { LabelPathList } from '../labels/label-path-list';
import { AdvancedFiltersContainer } from '@integration-fontends/integration/ui/attachment-selector/components/advanced-filters';
import './show-page.scss';
import {
  PageContainer,
  PageContent,
} from '@integration-fontends/integration/ui/attachment-selector/common/layout/page';
import OverlayScrollbars from 'overlayscrollbars';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react/dist/types/OverlayScrollbarsComponent';

// TODO: Need to refactor this component ~PP

export interface ShowPageProps {
  containerSelect: (containerId: string) => void;
}

export function ShowPage({
  containerSelect = noop,
  match,
  location,
}: ShowPageProps & RouteComponentProps<{ [x: string]: string }>) {
  const { containerId } = match.params;
  const urlSearch = qs.parse(location.search.substr(1)) as {
    searchParams?: string;
    sortOptions?: string;
  };
  const dispatch = useDispatch();
  let showAttachments;
  const pageLoading = useSelector(selectAttachmentSelectors.pageLoading);
  const container = useSelector((state) => containerSelectors.selectById(state, containerId));
  const searchLoading = useSelector(searchAssetsSelectors.loading);
  const searchParams = useSelector(searchAssetsSelectors.searchParams);
  const sortOptions = useSelector(searchAssetsSelectors.sortOptions);
  const selectedAttachmentIds = useSelector(selectAttachmentSelectors.selectedAttachmentIds);
  const initialScrollPosition = useSelector(selectAttachmentSelectors.showPageScrollPosition);
  const { customSortOptions, showAttachmentsToggleDisabled }: ISelectAttachmentOptions =
    DI_CONTAINER.get(SELECT_ATTACHMENT_OPTIONS_TOKEN);
  const selectAttachmentSelectionType = useSelector(selectAttachmentSelectors.selectionType);
  selectAttachmentSelectionType === SelectionType.Multi && showAttachmentsToggleDisabled
    ? (showAttachments = true)
    : (showAttachments = useSelector(searchAssetsSelectors.showAttachments));
  const searchInitSuccess = useSelector(searchAssetsSelectors.initSuccess);
  const [selectedSection, setSelectedSection] = useState<Section>(null);
  const [showAdvancedFiltersView, setShowAdvancedFiltersView] = useState<boolean>(false);
  const [showSearchView, setShowSearchView] = useState<boolean>(false);
  const [showLabelsView, setShowLabelsView] = useState<boolean>(false);
  const [modalActive, setModalActive] = useState(false);
  const navigation = useAttachmentSelectorNavigation();
  const containerRef = useRef();
  const pageContainerRef = useRef<OverlayScrollbarsComponent>();

  const { hasFeature } = useFeatures();
  const responsiveFeature = hasFeature(AttachmentSelectorFeature.Responsive);

  function getSearchParams(): SearchParameters {
    return urlSearch.searchParams ? JSON.parse(urlSearch.searchParams) : undefined;
  }

  function getSortOptions(): SortOptions<ResourceBaseSortableProperty> {
    return urlSearch.sortOptions ? JSON.parse(urlSearch.sortOptions) : customSortOptions;
  }

  useEffect(() => {
    dispatch(showPageEntered({ containerId }));
  }, [containerId]);

  useEffect(() => {
    containerSelect(containerId);
  }, [container?.id]);

  // Run search based on url parameters
  useEffect(() => {
    if (searchInitSuccess && !pageLoading) {
      dispatch(showPageSearch({ searchParams: getSearchParams(), sortOptions: getSortOptions() }));
    }
  }, [urlSearch.searchParams, urlSearch.sortOptions, pageLoading, searchInitSuccess]);

  function search(
    searchParams?: SearchParameters,
    sortOptions?: SortOptions<ResourceBaseSortableProperty>,
  ) {
    navigation.goToShowPage(
      containerId,
      searchParams || getSearchParams(),
      sortOptions || getSortOptions(),
    );
  }

  const onApplyFilters = useCallback(
    (searchParams: SearchParameters, sortOptions: SortOptions<ResourceBaseSortableProperty>) => {
      setShowAdvancedFiltersView(false);
      search(searchParams, sortOptions);
    },
    [],
  );

  const onScrollStop: OverlayScrollbars.Options['callbacks']['onScrollStop'] = useCallback(
    (e: UIEvent) => {
      const scrollElement = e.target as Element;
      dispatch(showPageScrollStopped({ scrollPosition: scrollElement.scrollTop }));
    },
    [],
  );

  useEffect(() => {
    if (pageContainerRef.current) {
      pageContainerRef.current.osInstance().scroll(initialScrollPosition);
    }
  }, []);

  function toggleModal() {
    setModalActive(!modalActive);
  }
  // TODO: make this into its own component
  const showPageLanding = (
    <ScrollingContainerRefContext.Provider value={responsiveFeature ? null : containerRef}>
      {showLabelsView ? (
        <LabelsSelectorView onClose={() => setShowLabelsView(false)} />
      ) : (
        <div
          data-testid="show-page"
          style={{ visibility: location.pathname.includes('asset-details') ? 'hidden' : 'visible' }}
          className="show-page h-full flex flex-col"
        >
          <ShowPageNav />

          <PageContent className="show-page-body">
            <>
              <ShowPageControls
                containerId={containerId}
                selectedSection={selectedSection}
                onSectionSelect={setSelectedSection}
                onCollectionSelect={(collection) => navigation.goToShowPage(collection.id)}
                onSetShowAdvancedFiltersView={() =>
                  setShowAdvancedFiltersView(!showAdvancedFiltersView)
                }
                showAdvancedFiltersView={showAdvancedFiltersView}
                onSetShowSearchView={() => setShowSearchView(!showSearchView)}
                showSearchView={showSearchView}
                search={search}
                sortOptions={sortOptions}
                onApplyFilters={onApplyFilters}
                responsive={responsiveFeature}
                showAttachments={showAttachments}
                labelsClick={() => setShowLabelsView(!showLabelsView)}
              />

              {searchParams && searchParams.labelFilter && (
                <LabelPathList searchParams={searchParams} />
              )}
              <div className="relative">
                <NotificationsContainer location="show-page"/>
                <div>
                  {searchLoading ? (
                    <Loader />
                  ) : (
                    <div className="flex flex-col gap-xl">
                      {hasFilters(searchParams) && (
                        <>
                          <FiltersDisplayContainer searchParams={searchParams} />
                          <ResultsDisplay containerId={containerId} />
                        </>
                      )}
                      <AssetsListContainer
                        containerId={containerId}
                        selectedSection={selectedSection}
                        showAttachments={showAttachments}
                      />
                    </div>
                  )}
                </div>
              </div>
              {selectAttachmentSelectionType === SelectionType.Multi && <MultiSelectOverlay />}
              {modalActive && selectAttachmentSelectionType === SelectionType.Multi && (
                <MultiSelectModal
                  prompt={`You have ${selectedAttachmentIds.length} assets selected within ${container.name}.`}
                  subText={`You will lose this selection if you leave this page.`}
                  toggleModal={toggleModal}
                />
              )}
            </>
          </PageContent>
        </div>
      )}
    </ScrollingContainerRefContext.Provider>
  );

  return (
    <>
      {pageLoading && <IntegrationPageLoader />}
      <div id="show-page" className="h-full overflow-y-hidden relative">
        <PageContainer
        ref={pageContainerRef}
        scrollOptions={{ callbacks: { onScrollStop } }}
        id="show-page-landing"
        className="h-full relative"
      >
          {showPageLanding}
        </PageContainer>

        {showAdvancedFiltersView && (
          <AdvancedFiltersContainer
            containerId={containerId}
            searchParams={searchParams}
            sortOptions={sortOptions}
            onClose={() => setShowAdvancedFiltersView(false)}
            onApply={onApplyFilters}
            responsive={responsiveFeature}
            showAdvancedFiltersView={showAdvancedFiltersView}
          />
        )}
      </div>
    </>
  );
}
