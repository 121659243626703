import { loginSuccess, logout } from '@integration-frontends/common/auth/core/application';
import { DI_CONTAINER } from '@integration-frontends/core';
import { push } from 'connected-react-router';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { IDENTITY_STORE_TOKEN, IIdentityStore } from '../common';
import { identifyLogRocket } from 'apps/salesforce-marketing-cloud/logrocket/logrocketSetup';

function* onLoginSuccess(action: ReturnType<typeof loginSuccess>) {
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  yield call(identityStore.set, action.payload.identity);
  identifyLogRocket(action.payload.identity);
  yield put(push('/select-attachment'));
}

function* onLogout() {
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  yield call(identityStore.set, null);
  yield put(push('/welcome'));
}

export function* authWorkflow() {
  yield all([takeEvery(loginSuccess.type, onLoginSuccess), takeEvery(logout.type, onLogout)]);
}
