import {logout} from "@integration-frontends/common/auth/core/application";
import { AuthUiModule } from '@integration-frontends/common/auth/ui';
import { IntegrationUiModule } from '@integration-frontends/integration/ui';
import { applyMiddleware, combineReducers, compose, createStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import ReduxThunk from 'redux-thunk';
import { rootWorkflow } from './workflows';
import LogRocket from 'logrocket';
import { appRootReducer } from '@integration-frontends/common/app';
import { notificationsRootReducer } from '@integration-frontends/common/notifications';

// TODO add configs ~PP
const ENVIRONMENT = 'development';

const reduxLogger = createLogger({
  predicate: () => ENVIRONMENT === 'development'
});

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers({
  router: connectRouter(history),
  ...notificationsRootReducer,
  ...appRootReducer,
  ...AuthUiModule.getRootReducer(),
  ...IntegrationUiModule.getRootReducer(),
});

const rootReducer = function (state, action) {
  // clear state on logout
  if (action.type === logout.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware, ReduxThunk, routerMiddleware(history), reduxLogger, LogRocket.reduxMiddleware())
  )
);

const rootSaga = function* () {
  yield all([fork(rootWorkflow), fork(IntegrationUiModule.getEffects())]);
};

sagaMiddleware.run(rootSaga);
