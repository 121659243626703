import classNames from 'classnames';
import React, { HTMLAttributes, InputHTMLAttributes } from 'react';
import './toggle-switch.scss';
import { withFormControlStyles } from './common';

export const ToggleSwitch = withFormControlStyles<
  HTMLAttributes<HTMLInputElement> & InputHTMLAttributes<HTMLInputElement>
>((props) => {
  return (
    <label className={classNames('switch', props.className)} style={props.style}>
      <input type="checkbox" checked={props.checked} onChange={props.onChange} {...props} />
      <span className="slider round" />
    </label>
  );
});
