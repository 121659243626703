import {
  Attachment,
  attachmentsMockData,
  IAttachmentRepo,
} from '@integration-frontends/integration/core/model';

export class AttachmentRepoMock implements IAttachmentRepo {
  getAttachment(attachmentId: string): Promise<Attachment> {
    return Promise.resolve(attachmentsMockData[0]);
  }

  listAssetAttachments(assetId: string): Promise<Attachment[]> {
    return Promise.resolve(attachmentsMockData);
  }
}
