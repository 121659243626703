import {
  Container,
  ILabelRepo,
  Label,
  labelsMockData,
} from '@integration-frontends/integration/core/model';

export class LabelRepoMock implements ILabelRepo {
  getContainerLabels(container: Container): Promise<Label[]> {
    return Promise.resolve(labelsMockData);
  }
}
