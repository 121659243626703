import { StateSelector } from '@integration-frontends/common/utils/redux';
import { AttachmentSelectors } from './attachment';
import { Asset, filterByAsset } from '@integration-frontends/integration/core/model';
import { createSelector, EntityState } from '@reduxjs/toolkit';
import { assetAdapter } from '../entities';

export function createAssetSelectors(
  stateSelector: StateSelector<EntityState<Asset>>,
  attachmentsSelectors: AttachmentSelectors,
) {
  const baseSelectors = assetAdapter.getSelectors(stateSelector);

  return {
    ...baseSelectors,
    assetAttachments: (asset: Asset) =>
      createSelector(attachmentsSelectors.selectAll, (attachments) =>
        attachments.filter(filterByAsset(asset.id)),
      ),
  };
}
