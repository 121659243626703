import { Container } from './container';
import { Asset, Attachment } from './entities';

export const BRANDFOLDER_WEBSITE_SERVICE_TOKEN = 'BRANDFOLDER_WEBSITE_SERVICE';

export interface IBrandfolderWebsiteService {
  getContainerUrl(container: Container): string;
  getAssetPageUrl(container: Container, asset: Asset): string;
  getAttachmentPageUrl(container: Container, attachment: Attachment): string;
  getFileThumbnailUrl(extension: string): string;
}
