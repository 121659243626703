import { BFLabel, BFSelect, BFSelectOption } from '@integration-frontends/common/ui';
import {
  AssetStatus,
  SearchParameters,
  setAssetStatusFilter,
} from '@integration-frontends/integration/core/model';
import { noop } from 'lodash';
import { isNil } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ASSET_STATUS_LABEL_KEY,
  getAssetStatusKey,
  INTEGRATION_COMMON_NAMESPACE,
  SELECT_STATUS_LABEL_KEY,
} from '@integration-frontends/integration/ui/common/i18n';
import { SectionContent } from '../common';

export interface AssetStatusFilterProps {
  searchParams: SearchParameters;
  onChange?: (searchParams: SearchParameters) => void;
}

export function AssetStatusFilter({ searchParams, onChange = noop }: AssetStatusFilterProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  function changed(option: BFSelectOption) {
    onChange(setAssetStatusFilter(searchParams, (option.value as AssetStatus) || null));
  }

  return (
    <SectionContent className="py-lg">
      <div className="flex items-center justify-between gap-lg">
        <BFLabel id={`asset-status-label`} className="whitespace-nowrap text-primary">{t(ASSET_STATUS_LABEL_KEY)}</BFLabel>
        <BFSelect
          aria-labelledby={`asset-status-label`}
          required
          placeholder={t(SELECT_STATUS_LABEL_KEY)}
          options={[
            {
              label: t(getAssetStatusKey(AssetStatus.Approved)) as string,
              value: AssetStatus.Approved,
            },
            {
              label: t(getAssetStatusKey(AssetStatus.Unapproved)) as string,
              value: AssetStatus.Unapproved,
            },
            {
              label: t(getAssetStatusKey(AssetStatus.Draft)) as string,
              value: AssetStatus.Draft,
            },
            {
              label: t(getAssetStatusKey(AssetStatus.Expired)) as string,
              value: AssetStatus.Expired,
            },
          ]}
          className="text-primary text-base"
          style={{ flex: '1 1 240px' }}
          value={isNil(searchParams?.assetStatusFilter) ? '' : searchParams.assetStatusFilter}
          onOptionChange={changed}
        />
      </div>
    </SectionContent>
  );
}
