import React from 'react';
import './logo-header.scss';

export function LogoHeader() {
  return (
    <div className="logo-header bg-bf-marshmallow flex items-center content-center">
      <img
        className="logo"
        src="https://cdn.bfldr.com/YUHW9ZNT/at/pk0081-5dri20-gfbvt3/bf_primary_logo"
        alt="Brandfolder logo"
      />
    </div>
  );
}
