import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import './layout.scss';

export function Nav({ className, style, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames('flex items-center', className)}
      style={{ zIndex: 75, ...style }}
      {...props}
    />
  );
}


export function Body({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames('relative flex flex-col gap-3 layout-body', className)} {...props} />
  );
}

export function SectionTitle({ className, style, ...props }: HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={classNames('mb-xs text-dark sentencecase block font-medium', className)}
      style={style}
      {...props}
    />
  );
}

export function Title({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return <div className={classNames('layout-title', className)} {...props} />;
}
