import {
  Attachment,
  AttachmentTransforms,
  AttachmentVariant,
  IAttachmentTransformService,
  ResourceType,
} from '@integration-frontends/integration/core/model';

export class AttachmentTransformServiceMock implements IAttachmentTransformService {
  supportsCropping(attachment: Attachment | AttachmentVariant): boolean {
    return true;
  }

  transform(attachment: Attachment, transforms: AttachmentTransforms): Promise<AttachmentVariant> {
    return Promise.resolve({
      ...attachment,
      attachmentId: attachment.id,
      url: attachment.url + '[TRANSFORMED]',
      type: ResourceType.ATTACHMENT_VARIANT,
      mediaType: transforms.mediaType || attachment.mediaType,
    });
  }
}
