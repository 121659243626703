import {
  ApiListDataResponse,
  AssetDto,
  AssetOptions,
  AttachmentDto,
  AttachmentInputDto,
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  CustomFieldValueDto,
  FieldParameter,
  getResponseDataOrDefault,
  getResponseIncluded,
  getResponseListDataOrDefault,
  getResponseMeta,
  IncludedAttachmentDto,
  isError,
  Relationship,
  ResourceType as ApiResourceType,
  SortDirection,
  SortField as ApiSortField,
  TagDto,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Asset,
  AssetCustomFieldValue,
  AssetDetails,
  AssetsListResultSet,
  AssetTag,
  Attachment,
  Container,
  IAssetRepo,
  ListOptions,
  MediaType,
  PagedResults,
  ResourceBaseSortableProperty,
  ResourceType,
  Section,
  SUPPORTED_MEDIA_TYPES_TOKEN,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { compose, map } from 'ramda';
import {
  buildSearchQuery,
  GET_API_KEY_TOKEN,
  IGetApiKey,
  mapAsset,
  mapAssetCustomFieldValue,
  mapAssetTag,
  mapAttachmentDto,
  mapIncludedAttachmentDto,
} from './model';

@injectable()
export class AssetRepo implements IAssetRepo {
  private brandfolderApi: BrandfolderApi;
  private getApiKey: IGetApiKey;
  private supportedMediaTypes: MediaType[];

  constructor() {
    this.brandfolderApi = DI_CONTAINER.get(BRANDFOLDER_API_TOKEN);
    this.getApiKey = DI_CONTAINER.get(GET_API_KEY_TOKEN);
    this.supportedMediaTypes = DI_CONTAINER.get(SUPPORTED_MEDIA_TYPES_TOKEN);
  }

  fetchAsset = async (assetId: string): Promise<Asset> => {
    return this.brandfolderApi
      .fetchAsset(await this.getApiKey(), assetId, this._generateDefaultAssetFieldOptions())
      .then(compose(mapAsset, getResponseDataOrDefault));
  };

  addAssetTags = async (assetIds: string[], tags: string[]): Promise<void> => {
    return this.brandfolderApi
      .addAssetTags(await this.getApiKey(), assetIds, tags);
  };

  getAssetDetails = async (assetId: string): Promise<AssetDetails> => {
    const response = await this.brandfolderApi.fetchAsset(await this.getApiKey(), assetId, {
      include: [Relationship.CUSTOM_FIELDS, Relationship.TAGS],
    });
    if (!isError(response) && response.included != null) {
      return {
        tags: response.included
          .filter(({ type }) => type === ApiResourceType.TAG)
          .map((tagDto: TagDto) => mapAssetTag(assetId, tagDto)),
        customFieldValues: response.included
          .filter(({ type }) => type === ApiResourceType.CUSTOM_FIELD_VALUE)
          .map((customFieldValueDto: CustomFieldValueDto) =>
            mapAssetCustomFieldValue(assetId, customFieldValueDto),
          ),
      };
    } else {
      return {
        tags: <AssetTag[]>[],
        customFieldValues: <AssetCustomFieldValue[]>[],
      };
    }
  };

  listContainerAssets = async (
    container: Container,
    options?: ListOptions,
  ): Promise<PagedResults<AssetsListResultSet>> => {
    return container.type === ResourceType.BRANDFOLDER
      ? this._listBrandfolderAssets(container.id, options)
      : this._listCollectionAssets(container.id, options);
  };

  private _listBrandfolderAssets = async (
    brandfolderId: string,
    options?: ListOptions,
  ): Promise<PagedResults<AssetsListResultSet>>  => {
    return this.brandfolderApi.listBrandfolderAssets(
      await this.getApiKey(),
      brandfolderId,
      {
        ...this._generateDefaultAssetListOptions(options),
        include: [Relationship.SECTION, Relationship.ATTACHMENTS]
      },
    ).then(res => this._mapAssetsListResponse(res, options));
  };

  private _listCollectionAssets = async (
    collectionId: string,
    options?: ListOptions,
  ): Promise<PagedResults<AssetsListResultSet>>  => {
    return this.brandfolderApi.listCollectionAssets(
      await this.getApiKey(),
      collectionId,
      {
        ...this._generateDefaultAssetListOptions(options),
        include: [Relationship.SECTION, Relationship.ATTACHMENTS]
      },
    ).then(res => this._mapAssetsListResponse(res, options));
  };

  listSectionAssets = async (
    sectionId: string,
    options?: ListOptions,
  ): Promise<PagedResults<AssetsListResultSet>> => {
    return this.brandfolderApi
      .listSectionAssets(await this.getApiKey(), sectionId, {
        ...this._generateDefaultAssetListOptions(options),
        include: [Relationship.SECTION, Relationship.ATTACHMENTS],
      })
      .then(res => this._mapAssetsListResponse(res, options));
  };

  listCollectionSectionAssets = async (
    collectionId: string,
    sectionId: string,
    options?: ListOptions,
  ): Promise<PagedResults<AssetsListResultSet>> => {
    return this.brandfolderApi
      .listCollectionSectionAssets(await this.getApiKey(), collectionId, sectionId, {
        ...this._generateDefaultAssetListOptions(options),
        include: [Relationship.SECTION, Relationship.ATTACHMENTS],
      })
      .then(res => this._mapAssetsListResponse(res, options));
  };

  listContainerSectionAssets = async (
    container: Container,
    sectionId: string,
    options?: ListOptions,
  ) => {
    return container.type === ResourceType.BRANDFOLDER
      ? this.listSectionAssets(sectionId, options)
      : this.listCollectionSectionAssets(container.id, sectionId, options);
  };

  listContainerSectionsAssets = async (
    container: Container,
    sections: Section[],
    options?: ListOptions,
  ): Promise<{ sectionId: string; results: PagedResults<AssetsListResultSet> }[]> => {
    return Promise.all(
      sections.map((section) =>
        this.listContainerSectionAssets(container, section.id, options).then((results) => ({
          sectionId: section.id,
          results,
        })),
      ),
    );
  };

  private _mapAssetsListResponse(
    res: ApiListDataResponse<AssetDto>,
    options?: ListOptions,
  ): PagedResults<AssetsListResultSet> {
    return {
      data: {
        assets: getResponseListDataOrDefault(res).map((asset) => mapAsset(asset)),
        attachments: getResponseListDataOrDefault(res).flatMap((asset) =>
          asset.relationships?.[Relationship.ATTACHMENTS].data.map(({ id }) =>
            mapIncludedAttachmentDto(
              getResponseIncluded(res).find((inc) => inc.id === id) as IncludedAttachmentDto,
              asset.id,
            ),
          ),
        ),
      },
      currentPage: getResponseMeta(res).current_page,
      totalCount: getResponseMeta(res).total_count,
      perPage: options?.pagination?.perPage || 1,
    };
  }

  create = async (
    container: Container,
    sectionId: string,
    name: string,
    files: File[],
    source: string,
  ): Promise<void> => {
    const attachments: AttachmentInputDto[] = await Promise.all(
      files.map(async (file) => {
        const payload = await this.brandfolderApi.uploadFile(await this.getApiKey(), file);
        return {
          type: ApiResourceType.ATTACHMENT as ApiResourceType.ATTACHMENT,
          url: payload.objectUrl,
          filename: file.name,
          mimetype: file.type,
          source,
        };
      }),
    );

    const createAsset =
      container.type === ResourceType.BRANDFOLDER
        ? this.brandfolderApi.createBrandfolderAsset.bind(this.brandfolderApi)
        : this.brandfolderApi.createCollectionAsset.bind(this.brandfolderApi);

    return await createAsset(await this.getApiKey(), container.id, sectionId, name, attachments);
  };

  private _mapListResponses(
    [assetsResponse, attachmentsResponse]: [
      ApiListDataResponse<AssetDto>,
      ApiListDataResponse<AttachmentDto>,
    ],
    collectionId?: string,
  ): { assets: Asset[]; attachments: Attachment[] } {
    const assetDtos = getResponseListDataOrDefault(assetsResponse);
    const attachmentDtos = getResponseListDataOrDefault(attachmentsResponse);

    return {
      assets: assetDtos.map((dto) => mapAsset(dto, collectionId)),
      attachments: attachmentDtos.map(mapAttachmentDto),
    };
  }

  private _generateDefaultAssetFieldOptions(): AssetOptions {
    return {
      fields: [
        FieldParameter.CardImage,
        FieldParameter.AttachmentCount,
        FieldParameter.CdnUrl,
        FieldParameter.CreatedAt,
        FieldParameter.ExpiresOn,
        FieldParameter.UpdatedAt,
      ],
    };
  }

  private _generateDefaultAssetListOptions(options: ListOptions): AssetOptions {
    const sortFieldMap = {
      [ResourceBaseSortableProperty.Position]: ApiSortField.Position,
      [ResourceBaseSortableProperty.CreatedAt]: ApiSortField.CreatedAt,
      [ResourceBaseSortableProperty.Name]: ApiSortField.Name,
      [ResourceBaseSortableProperty.UpdatedAt]: ApiSortField.UpdatedAt,
      [ResourceBaseSortableProperty.Popularity]: ApiSortField.Score,
    };

    return {
      ...this._generateDefaultAssetFieldOptions(),
      include: [Relationship.SECTION],
      search: buildSearchQuery(options?.searchParams),
      per: options?.pagination?.perPage,
      page: options?.pagination?.page,
      sort: {
        direction: (options?.sort?.direction === 'DESC' && SortDirection.DESC) || SortDirection.ASC,
        field: sortFieldMap[options?.sort?.field] || ApiSortField.Position,
      },
    };
  }
}
