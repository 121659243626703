import { AssetTag } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const assetTagAdapter = createEntityAdapter<AssetTag>();

export const assetTagSlice = createSlice({
  initialState: assetTagAdapter.getInitialState(),
  name: 'assetTags',
  reducers: {
    assetTagsReceived: assetTagAdapter.upsertMany,
  },
});
