import {AuthError, Identity} from '@integration-frontends/common/auth/core/model';
import {createReducer} from '@reduxjs/toolkit';
import { clear, loginError, loginSuccess, logout } from './actions';

export interface AuthState {
  identity: Identity | null;
  error: AuthError;
}

const initialState: AuthState = {
  identity: null,
  error: null,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(loginSuccess, (state, action) => {
      state.identity = action.payload.identity;
    })
    .addCase(loginError, (state, action) => {
      state.error = action.payload.error;
    })
    .addCase(logout, (state) => {
      state.identity = null;
    })
    .addCase(clear, (state) => {
      state.error = null;
      state.identity = null;
    });
});
