import classNames from 'classnames';
import { ReactElementLike } from 'prop-types';
import React, {
  ButtonHTMLAttributes,
  HTMLAttributes,
  MutableRefObject,
  ReactElement,
  useRef,
  useState,
} from 'react';
import './buttons.scss';
import useResizeObserver from '@react-hook/resize-observer';

export enum ButtonType {
  Primary,
  Secondary,
  ActiveDark,
  Disabled,
  Success,
}

export enum ButtonSize {
  Small,
  Medium,
  Smallest,
}

export type BFButtonProps = {
  buttonType?: ButtonType;
  size?: ButtonSize;
  transparentBackground?: boolean;
  ariaLabel?: string;
  iconBefore?: ReactElement;
  iconAfter?: ReactElement;
  hasIcons?: boolean;
} & HTMLAttributes<HTMLButtonElement> &
  ButtonHTMLAttributes<HTMLButtonElement>;

const BUTTON_HEIGHTS = {
  [ButtonSize.Smallest]: 30,
  [ButtonSize.Small]: 40,
  [ButtonSize.Medium]: 50,
};

export const BFButton = React.forwardRef(
  (
    {
      buttonType = ButtonType.Primary,
      children,
      className,
      size = ButtonSize.Medium,
      ariaLabel,
      iconBefore,
      iconAfter,
      hasIcons = false,
      ...props
    }: BFButtonProps,
    ref: MutableRefObject<HTMLButtonElement>,
  ) => {
    return (
      <button
        ref={ref}
        {...props}
        className={classNames(
          {
            'btn-primary': buttonType === ButtonType.Primary,
            'btn-secondary': buttonType === ButtonType.Secondary,
            'btn-active-dark': buttonType === ButtonType.ActiveDark,
            'btn-success': buttonType === ButtonType.Success,
            'btn-disabled': props.disabled === true,
            'has-icons': hasIcons || !!iconAfter || !!iconBefore,
            'has-after-icon': !!iconAfter,
          },
          className,
        )}
        style={{
          height: BUTTON_HEIGHTS[size],
          ...props.style,
        }}
        aria-label={ariaLabel}
      >
        <div className="btn-content">
          {iconBefore && iconBefore}
          {children}
          {iconAfter && iconAfter}
        </div>
      </button>
    );
  },
);

const ICON_BUTTON_PADDING_X = 6;
export const BFIconButton = React.forwardRef(
  (
    {
      iconElement,
      className,
      style,
      size = ButtonSize.Smallest,
      ariaLabel,
      transparentBackground,
      ...props
    }: BFButtonProps &
      HTMLAttributes<HTMLButtonElement> &
      ButtonHTMLAttributes<HTMLButtonElement> & { iconElement: ReactElementLike },
    ref: MutableRefObject<HTMLButtonElement>,
  ) => {
    return (
      <BFButton
        ref={ref}
        buttonType={ButtonType.Secondary}
        className={classNames(
          'icon-btn',
          'rounded flex items-center justify-center border border-bf-lighter-gray',
          { transparent: transparentBackground },
          className,
        )}
        style={{
          minWidth: BUTTON_HEIGHTS[size],
          height: BUTTON_HEIGHTS[size],
          paddingLeft: ICON_BUTTON_PADDING_X,
          paddingRight: ICON_BUTTON_PADDING_X,
          ...style,
        }}
        ariaLabel={ariaLabel}
        {...props}
      >
        {iconElement}
      </BFButton>
    );
  },
);

export const BFDynamicButton = React.forwardRef(
  (
    { children, className, style, ...props }: BFButtonProps,
    ref: MutableRefObject<HTMLButtonElement>,
  ) => {
    const virtualButtonRef = useRef(null);
    const [width, setWidth] = useState(null);

    useResizeObserver(virtualButtonRef, (entry) =>
      setWidth(entry.target.getBoundingClientRect().width),
    );

    // render a "virtual" (hidden) button to measure width and apply
    // an ease transition on the visible button
    return (
      <>
        <BFButton
          ref={virtualButtonRef}
          className={classNames('dynamic', className)}
          children={children}
          style={{ position: 'absolute', visibility: 'hidden', ...style }}
          {...props}
          data-testid={null}
        />
        <BFButton
          ref={ref}
          className={classNames('dynamic', className)}
          children={children}
          {...props}
          style={{ width }}
        />
      </>
    );
  },
);
