import {
  Attachment,
  AttachmentTransforms,
  AttachmentVariant,
  getAttachmentUrl,
  IAttachmentTransformService,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { createAttachmentCdnVariant, supportsCdnTransforms, supportsCropping } from '../common';

@injectable()
export class AttachmentTransformService implements IAttachmentTransformService {
  transform = async (
    attachment: Attachment,
    transforms: AttachmentTransforms,
  ): Promise<AttachmentVariant> => {
    console.log(transforms);
    if (supportsCdnTransforms(getAttachmentUrl(attachment))) {
      return createAttachmentCdnVariant(attachment, transforms);
    } else {
      // return this for now, but we could do the transformations client side.
      // TODO: throw exception and let consumer handle error. ~PP
      return {
        ...attachment,
        type: ResourceType.ATTACHMENT_VARIANT,
        attachmentId: attachment.id,
        dimensions: transforms.size,
        url: getAttachmentUrl(attachment),
      };
    }
  };

  supportsCropping(attachment: Attachment): boolean {
    return supportsCropping(attachment.url);
  }
}
