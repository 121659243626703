import { Asset } from '@integration-frontends/integration/core/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DESCRIPTION_KEY } from '../../i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import {
  PageSection,
  PageSectionContent,
} from '@integration-fontends/integration/ui/attachment-selector/common/layout/page';

export interface DescriptionProps {
  asset: Asset;
}

export function AssetDescription({ asset }: DescriptionProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <>
      {asset.description && (
        <PageSection title={t(DESCRIPTION_KEY)}>
          <PageSectionContent>{asset.description}</PageSectionContent>
        </PageSection>
      )}
    </>
  );
}
