import { BFInput, BFLabel } from '@integration-frontends/common/ui';
import {
  ResizingOptions,
  setHeight,
  setWidth,
} from '@integration-frontends/integration/core/application';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IMAGE_HEIGHT_LABEL_KEY,
  IMAGE_WIDTH_LABEL_KEY,
  INTEGRATION_COMMON_NAMESPACE,
} from '../common/i18n';

export interface ResizerProps {
  options: ResizingOptions;
  onChange: (options: ResizingOptions) => void;
}

export function Resizer({ options, onChange }: ResizerProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const { newDimensions } = options;

  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="flex gap-lg justify-start">
        <div className="flex flex-col flex-auto">
          <BFLabel>{t(IMAGE_WIDTH_LABEL_KEY)}</BFLabel>
          <BFInput
            data-cy="resize-width"
            data-testid="resize-width"
            className="w-full"
            type="number"
            value={Math.round(newDimensions?.width) || ''}
            onChange={(e) => onChange(setWidth(options, parseInt(e.target.value)))}
          />
        </div>

        <div className="flex flex-col flex-auto">
          <BFLabel>{t(IMAGE_HEIGHT_LABEL_KEY)}</BFLabel>
          <BFInput
            data-cy="resize-height"
            data-testid="resize-height"
            className="w-full"
            type="number"
            value={Math.round(newDimensions?.height) || ''}
            onChange={(e) => onChange(setHeight(options, parseInt(e.target.value)))}
          />
        </div>
      </div>
    </div>
  );
}
