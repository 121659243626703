import { BFInput, BFLabel } from '@integration-frontends/common/ui';
import { LabelOptions, setLabel } from '@integration-frontends/integration/core/application';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMAGE_LABEL_LABEL_KEY, INTEGRATION_COMMON_NAMESPACE } from '../common/i18n';

export interface LabelProps {
  options: LabelOptions;
  onChange: (options: LabelOptions) => void;
}

export function Label({ options, onChange }: LabelProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <div className="flex flex-col w-full">
      <BFLabel>{t(IMAGE_LABEL_LABEL_KEY)}</BFLabel>
      <BFInput
        type="text"
        value={options.label}
        onChange={(e) => onChange(setLabel(e.target.value, options))}
      />
    </div>
  );
}
