import {
  Alignment,
  LabelOptions,
  LinkOptions
} from '@integration-frontends/integration/core/application';
import {
  Attachment,
  AttachmentVariant,
  getAttachmentUrl,
  isImage
} from '@integration-frontends/integration/core/model';
import React from 'react';

export interface AttachmentRenderProps {
  attachment: Attachment | AttachmentVariant;
  alignment?: Alignment;
  labelOptions?: LabelOptions;
  linkOptions?: LinkOptions;
}

export function AttachmentRenderer({
  attachment,
  alignment = Alignment.Left,
  labelOptions,
  linkOptions
}: AttachmentRenderProps) {
  const flexAlignments = {
    [Alignment.Right]: 'flex-end',
    [Alignment.Center]: 'center',
    [Alignment.Left]: 'flex-start'
  };

  const image = (
    <a href={linkOptions?.link} target="_blank" rel="noreferrer">
      <img
        style={{
          width: '100%',
          height: 'auto'
        }}
        src={getAttachmentUrl(attachment)}
        alt={attachment.name}
      />
    </a>
  );

  const video = (
    <video
      controls
      style={{
        width: '100%',
        height: 'auto'
      }}
    >
      <source src={getAttachmentUrl(attachment)} type={attachment.mimetype} />
    </video>
  );

  // Note: We're using the style attribute (vs classes) here because the component is usually
  // used to generate static HTML that is sent to an external system. It is better to keep things
  // as simple as possible so that we don't need to bootstrap a complex rendering context.
  return (
    attachment && (
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: flexAlignments[alignment]
          }}
        >
          <div
            style={{
              maxWidth: attachment.dimensions.width,
              maxHeight: attachment.dimensions.height,
              width: '100%'
            }}
          >
            {isImage(attachment.mimetype) ? image : video}
            {labelOptions && (
              <span style={{ paddingTop: 5, alignSelf: 'flex-start' }}>{labelOptions.label}</span>
            )}
          </div>
        </div>
      </div>
    )
  );
}
