import {
  AuthError,
  CredentialsInvalidError,
  IAuthenticateService,
  Identity,
} from '@integration-frontends/common/auth/core/model';
import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  isError,
  UserDto,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import { injectable } from 'inversify';

@injectable()
export class AuthenticationServiceApiKeyStrategy implements IAuthenticateService<string> {
  private brandfolderApi: BrandfolderApi;

  constructor() {
    this.brandfolderApi = DI_CONTAINER.get(BRANDFOLDER_API_TOKEN);
  }

  authenticate = async (apiKey: string): Promise<Identity | AuthError> => {
    const user = await this.getCurrentUserDto(apiKey);
    return user
      ? {
          token: apiKey,
          userId: user.id,
          firstName: user.attributes.first_name,
          lastName: user.attributes.last_name,
          email: user.attributes.email,
        }
      : new CredentialsInvalidError();
  };

  isAuthenticated = (identity: Identity): Promise<boolean> => {
    return this.getCurrentUserDto(identity?.token).then(Boolean);
  };

  private getCurrentUserDto = async (apiKey: string): Promise<UserDto> => {
    return this.brandfolderApi.whoAmI(apiKey).then((res) => (isError(res) ? null : res.data));
  };
}
