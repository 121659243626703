import { createSelector } from '@reduxjs/toolkit';
import { PlaceAttachmentState } from './reducer';

const selectLoading = (state: PlaceAttachmentState) => state.loading;

export function createPlaceAttachmentSelectors(
  rootStateSelector: (rootState) => PlaceAttachmentState,
) {
  return {
    loading: createSelector(rootStateSelector, selectLoading),
  };
}
