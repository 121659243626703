import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { IconCaretDown, IconCaretRight, IconCaretLeft, IconSize } from './icons';

export type BFCaretToggleProps = HTMLAttributes<HTMLDivElement> & {
  open: boolean;
  closedDirection?: string;
};

export function BFCaretToggle({ children, className, open, closedDirection = 'right', ...props }: BFCaretToggleProps) {
  return (
    <div className={classNames('flex items-center cursor-pointer', className)} {...props}>
      <div
        style={{ width: 35, height: 35 }}
        className="hover:bg-bf-marshmallow flex justify-center items-center"
      >
        {open ? (
          <IconCaretDown className={`icon-caret-down-primary`} iconSize={IconSize.Small} />
        ) : (
          <IconCaretRight className={`icon-caret-${closedDirection}-primary`} iconSize={IconSize.Small} />
        )}
      </div>
      {children}
    </div>
  );
}
