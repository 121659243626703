import { AssetCustomFieldValue } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const assetCustomFieldValueAdapter = createEntityAdapter<AssetCustomFieldValue>();

export const assetCustomFieldValueSlice = createSlice({
  initialState: assetCustomFieldValueAdapter.getInitialState(),
  name: 'assetCustomFieldValue',
  reducers: {
    assetCustomFieldValuesReceived: assetCustomFieldValueAdapter.upsertMany,
  },
});
