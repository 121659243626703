import { Identity } from '@integration-frontends/common/auth/core/model';
import { injectable } from 'inversify';
import { useStorage, StorageMethods } from '@apps-common/utils';

export const IDENTITY_STORE_TOKEN = 'IDENTITY_STORE_TOKEN';
export interface IIdentityStore {
  get: () => Promise<Identity>;
  set: (identity: Identity) => Promise<void>;
}

export const IDENTITY_STORE_KEY = 'IDENTITY_STORE_KEY';

@injectable()
export class IdentityStoreLocalStorageStrategy implements IIdentityStore {
  get = async function (): Promise<Identity> {
    return JSON.parse(useStorage(StorageMethods.GetItem, IDENTITY_STORE_KEY));
  };

  set = async function (identity: Identity): Promise<void> {
    useStorage(StorageMethods.SetItem, IDENTITY_STORE_KEY, JSON.stringify(identity));
  };
}
