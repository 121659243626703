import { AbsoluteDimensions, DimensionType } from './common/media';

export enum CustomDimension {
  Hubspot = 'HUBSPOT_CUSTOM_DIMENSION',
  Square512 = 'SQUARE512_CUSTOM_DIMENSION',
}

export const CUSTOM_DIMENSIONS: { [customDimension: string]: AbsoluteDimensions } = {
  [CustomDimension.Hubspot]: { width: 320, height: 240, type: DimensionType.Absolute },
  [CustomDimension.Square512]: { width: 512, height: 512, type: DimensionType.Absolute },
};
