import { DI_CONTAINER } from '@integration-frontends/core';
import { put, takeEvery } from 'redux-saga/effects';
import {
  multiSelectAttachmentEnded,
  singleSelectAttachmentEnded,
  endAttachmentSelection,
} from '../actions';
import {
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  ISelectAttachmentOptions,
  SelectionType,
} from '../../select-attachment-options';

const handler = function* (action: ReturnType<typeof endAttachmentSelection>) {
  const options: ISelectAttachmentOptions = DI_CONTAINER.get(SELECT_ATTACHMENT_OPTIONS_TOKEN);

  if (options.selectionType === SelectionType.Single) {
    yield put(singleSelectAttachmentEnded({ attachments: action.payload.attachments }));
  } else if (options.selectionType === SelectionType.Multi) {
    yield put(multiSelectAttachmentEnded({ attachments: action.payload.attachments }));
  }
};

export function* endAttachmentSelectionEffects() {
  yield takeEvery(endAttachmentSelection, handler);
}
