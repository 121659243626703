import React from 'react';
import { createIcon } from './create-icon';

const icon = (
  <svg
    width="16px"
    height="10px"
    viewBox="0 0 16 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M16,4.63186593 C15.992,4.80036593 15.9245,4.98786593 15.8305,5.09686593 L12.4755,8.99786593 C12.189,9.31786593 11.6975,9.34436643 11.383,9.10086593 C11.0685,8.85736493 11.016,8.36536543 11.274,8.06836543 L13.5965,5.36336593 L0.774,5.36336593 C0.34650015,5.36336593 0,5.03586593 0,4.63186593 C0,4.22786593 0.34700015,3.90036593 0.774,3.90036593 L13.5965,3.90036593 L11.274,1.19536593 C11.016,0.898365929 11.0735,0.412365929 11.383,0.162865929 C11.7035,-0.0951340714 12.2185,-0.0361340714 12.4755,0.265865929 L15.8305,4.16686593 C15.9615,4.31836593 15.99,4.44686593 16,4.63186593 L16,4.63186593 Z"
        id="Fill-1"
        fill="#4462C9"
        transform="translate(8.000000, 4.631737) scale(-1, 1) translate(-8.000000, -4.631737) "
      ></path>
    </g>
  </svg>
);

export const IconBackArrow = createIcon(icon);
