import { StateSelector } from '@integration-frontends/common/utils/redux';
import { containerFileTypeAggregatesAdapter } from '@integration-frontends/integration/core/application/common/entities-state/entities';
import { ContainerFileTypeAggregates } from '@integration-frontends/integration/core/model';
import { EntityState } from '@reduxjs/toolkit';

export function createContainerFileTypeAggregateSelectors(
  stateSelector: StateSelector<EntityState<ContainerFileTypeAggregates>>,
) {
  return containerFileTypeAggregatesAdapter.getSelectors(stateSelector);
}
