import {
  ApiSearchableThingsResponse,
  AssetDto,
  AttachmentDto,
  BrandfolderDto,
  CollectionDto,
  CustomFieldKeyDto,
  CustomFieldValueDto,
  getAttachmentDtoUrl,
  IncludedAttachmentDto,
  IncludedBrandfolderDto,
  LabelDto,
  OrganizationDto,
  Relationship,
  ResourceDto,
  SearchFilterDto,
  SectionDto,
  TagDto,
} from '@integration-frontends/common/brandfolder-api';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Asset,
  AssetCustomFieldValue,
  AssetStatus,
  AssetTag,
  Attachment,
  AttachmentOrientation,
  AttachmentUploadDate,
  Brandfolder,
  BrandfolderCustomField,
  BrandfolderFileTypeAggregates,
  BrandfolderTag,
  Collection,
  CollectionCustomField,
  CollectionFileTypeAggregates,
  CollectionTag,
  ContainerCustomField,
  ContainerTag,
  DimensionType,
  getMimeTypeMediaType,
  hasAssetStatusFilter,
  hasCustomFieldFilters,
  hasFileTypeFilters,
  hasLabelFilter,
  hasOrientationFilters,
  hasPinnedSearchFilter,
  hasQueryFilter,
  hasSkuFilters,
  hasTagFilters,
  hasUploadDateFilter,
  ImageType,
  IMediaTypeSupportService,
  isImage,
  Label,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  Organization,
  ResourceBase,
  ResourceType,
  SearchFilter,
  SearchParameters,
  Section,
} from '@integration-frontends/integration/core/model';
import { format } from 'date-fns';
import { curry } from 'ramda';
import { setMediaType } from './common';

export const GET_API_KEY_TOKEN = 'GET_API_KEY';
export type IGetApiKey = () => Promise<string>;

export function mapAsset(assetDto: AssetDto, collectionId?: string): Asset {
  return {
    ...mapResourceFields(assetDto),
    type: ResourceType.ASSET,
    sectionId: assetDto.relationships?.[Relationship.SECTION]?.data.id,
    thumbnailUrl: assetDto.attributes.thumbnail_url,
    attachmentCount: assetDto.attributes.attachment_count,
    collectionId,
    description: assetDto.attributes.description,
    availabilityEnd: assetDto.attributes.availability_end,
  };
}

export function mapAssetTag(assetId: string, tagDto: TagDto): AssetTag {
  return {
    id: tagDto.id,
    assetId,
    type: ResourceType.TAG,
    autoGenerated: tagDto.attributes.auto_generated,
    name: tagDto.attributes.name,
    position: tagDto.attributes.position,
  };
}

export function mapAssetCustomFieldValue(
  assetId: string,
  customFieldValueDto: CustomFieldValueDto,
): AssetCustomFieldValue {
  return {
    id: customFieldValueDto.id,
    assetId,
    type: ResourceType.CUSTOM_FIELD_VALUE,
    key: customFieldValueDto.attributes.key,
    value: customFieldValueDto.attributes.value,
    position: customFieldValueDto.attributes.position,
  };
}

export type AttachmentWithDto = Attachment & { dto: AttachmentDto };
export function mapAttachmentDto(attachmentDto: AttachmentDto): AttachmentWithDto {
  const mediaTypeSupportService: IMediaTypeSupportService = DI_CONTAINER.get(
    MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  );
  const mediaType = getMimeTypeMediaType(attachmentDto.attributes.mimetype);
  const supported = mediaTypeSupportService.isSupported(mediaType);

  return {
    ...mapResourceFields(attachmentDto),
    type: ResourceType.ATTACHMENT,
    assetId: attachmentDto.relationships?.[Relationship.ASSET].data.id,
    url:
      supported || !isImage(attachmentDto.attributes.mimetype)
        ? getAttachmentDtoUrl(attachmentDto).replace('&format=png', '')
        : setMediaType(getAttachmentDtoUrl(attachmentDto), ImageType.Png),
    filename: attachmentDto.attributes.filename,
    extension: attachmentDto.attributes.extension,
    name: attachmentDto.attributes.filename,
    mimetype: attachmentDto.attributes.mimetype,
    thumbnailUrl: attachmentDto.attributes.thumbnail_url,
    dimensions: {
      width: attachmentDto.attributes.width,
      height: attachmentDto.attributes.height,
      type: DimensionType.Absolute,
    },
    sizeInBytes: attachmentDto.attributes.size,
    dto: attachmentDto,
    mediaType,
    supported,
    createdAt: new Date(attachmentDto.attributes.created_at),
    updatedAt: new Date(attachmentDto.attributes.updated_at),
  };
}

export function mapIncludedAttachmentDto(
  includedAttachmentDto: IncludedAttachmentDto,
  assetId: string,
): AttachmentWithDto {
  return mapAttachmentDto({
    ...includedAttachmentDto,
    relationships: {
      [Relationship.ASSET]: {
        data: {
          id: assetId,
        },
      },
    },
  } as AttachmentDto);
}

export function mapBrandfolder(brandfolderDto: BrandfolderDto): Brandfolder {
  if (!brandfolderDto) return null;

  return {
    ...mapResourceFields(brandfolderDto),
    type: ResourceType.BRANDFOLDER,
    assetCount: brandfolderDto.attributes.asset_count,
    organizationId: brandfolderDto.relationships?.[Relationship.ORGANIZATION]?.data.id,
    cardImage: brandfolderDto.attributes.card_image,
    name: brandfolderDto.attributes.name,
    hasAccess: true,
  };
}

export function mapIncludedBrandfolder(
  includedBrandfolderDto: IncludedBrandfolderDto,
  organizationId: string,
): Brandfolder {
  return {
    ...mapResourceFields(includedBrandfolderDto),
    type: ResourceType.BRANDFOLDER,
    id: includedBrandfolderDto.id,
    organizationId,
    name: includedBrandfolderDto.attributes.name,
    hasAccess: false,
  };
}

export function mapCollection(
  collectionDto: CollectionDto,
  brandfolderDto: BrandfolderDto,
): Collection {
  if (!collectionDto || !brandfolderDto) return null;

  return {
    ...mapResourceFields(collectionDto),
    name: `${collectionDto.attributes.name}`,
    type: ResourceType.COLLECTION,
    assetCount: collectionDto.attributes.asset_count,
    brandfolderId: collectionDto.relationships?.[Relationship.BRANDFOLDER]?.data.id,
    hasAccess: true,
    slug: `${brandfolderDto.attributes.slug}/${collectionDto.attributes.slug}`,
  };
}

export function mapOrganization(organizationDto: OrganizationDto): Organization {
  return {
    id: organizationDto.id,
    type: ResourceType.ORGANIZATION,
    name: organizationDto.attributes.name,
    position: organizationDto.attributes.position,
  };
}

export const mapSection = (sectionDto: SectionDto): Section => {
  return {
    id: sectionDto.id,
    type: ResourceType.SECTION,
    name: sectionDto.attributes.name,
    position: sectionDto.attributes.position,
    brandfolderId: sectionDto.relationships?.[Relationship.BRANDFOLDER]?.data.id,
  };
};

export function mapBrandfolderCustomField(
  brandfolderId: string,
  customFieldKeyDto: CustomFieldKeyDto,
  searchableThings: ApiSearchableThingsResponse,
): BrandfolderCustomField {
  return {
    ...mapCustomField(customFieldKeyDto, searchableThings),
    brandfolderId,
  };
}

export function mapCollectionCustomField(
  collectionId: string,
  customFieldKeyDto: CustomFieldKeyDto,
  searchableThings: ApiSearchableThingsResponse,
): CollectionCustomField {
  return {
    ...mapCustomField(customFieldKeyDto, searchableThings),
    collectionId,
  };
}

function mapCustomField(
  customFieldKeyDto: CustomFieldKeyDto,
  searchableThings: ApiSearchableThingsResponse,
): Omit<ContainerCustomField, 'containerId'> {
  return {
    ...mapResourceFields(customFieldKeyDto),
    type: ResourceType.CUSTOM_FIELD,
    searchable: searchableThings.custom_fields
      .flatMap(Object.values)
      .includes(customFieldKeyDto.id),
    prioritized: customFieldKeyDto.attributes.prioritized,
    allowedValues: customFieldKeyDto.attributes.allowed_values,
  };
}

export function mapBrandfolderTag(
  brandfolderId: string,
  tagDto: TagDto,
  searchableThings: ApiSearchableThingsResponse,
): BrandfolderTag {
  return {
    ...mapTag(tagDto, searchableThings),
    brandfolderId,
  };
}

export function mapCollectionTag(
  collectionId: string,
  tagDto: TagDto,
  searchableThings: ApiSearchableThingsResponse,
): CollectionTag {
  return {
    ...mapTag(tagDto, searchableThings),
    collectionId,
  };
}

function mapTag(
  tagDto: TagDto,
  searchableThings: ApiSearchableThingsResponse,
): Omit<ContainerTag, 'containerId'> {
  const searchableTag = searchableThings.tags?.find((tag) => tag.name === tagDto.attributes.name);

  return {
    ...mapResourceFields(tagDto),
    type: ResourceType.TAG,
    searchable: !!searchableTag,
    count: searchableTag?.count,
  };
}

export function mapBrandfolderFileTypeAggregates(
  brandfolderId: string,
  searchableThings: ApiSearchableThingsResponse,
): BrandfolderFileTypeAggregates[] {
  return searchableThings.filetypes.map(({ count, extension }) => ({
    name: extension,
    count,
    brandfolderId,
  }));
}

export function mapCollectionFileTypeAggregates(
  collectionId: string,
  searchableThings: ApiSearchableThingsResponse,
): CollectionFileTypeAggregates[] {
  return (
    searchableThings.filetypes?.map(({ count, extension }) => ({
      name: extension,
      count,
      collectionId,
    })) || []
  );
}

export const mapSearchFilter = curry(
  (containerId: string, searchFilter: SearchFilterDto): SearchFilter => {
    return {
      ...mapResourceFields(searchFilter),
      type: ResourceType.SEARCH_FILTER,
      label: searchFilter.attributes.label,
      query: searchFilter.attributes.query,
      featured: searchFilter.attributes.featured,
      containerId,
    };
  },
);

export function mapLabel(containerId: string, labelDto: LabelDto): Label {
  return {
    ...mapResourceFields(labelDto),
    type: ResourceType.LABEL,
    containerId,
    path: labelDto.attributes.path,
    depth: labelDto.attributes.depth,
  };
}

function mapResourceFields(
  resourceDto: ResourceDto,
): Pick<ResourceBase, 'id' | 'name' | 'slug' | 'createdAt' | 'updatedAt' | 'position'> {
  const { name, slug, created_at, updated_at, position } = resourceDto.attributes;

  return {
    id: resourceDto.id,
    name,
    slug,
    createdAt: created_at && new Date(created_at),
    updatedAt: updated_at && new Date(updated_at),
    position,
  };
}

export function buildSearchQuery(searchParams: SearchParameters): string {
  if (!searchParams) return null;

  const searchFragments = [];

  if (hasQueryFilter(searchParams)) {
    searchFragments.push(`(${searchParams.query})`);
  }

  if (hasTagFilters(searchParams)) {
    const tags = searchParams.tagFilters.tags.map((tag) => tag.name);
    searchFragments.push(`tags:(${tags.join(` ${searchParams.tagFilters.operator || ' OR '} `)})`);
  }

  if (hasCustomFieldFilters(searchParams)) {
    const customFieldFilters = Object.values(searchParams.customFieldFilters).filter(Boolean);
    searchFragments.push(
      customFieldFilters
        .map((filter) => `custom_fields.${filter.customField.name}:${filter.value}`)
        .join(' AND '),
    );
  }

  if (hasFileTypeFilters(searchParams)) {
    searchFragments.push(`filetype:(${searchParams.fileTypeFilters.fileTypes.join(' OR ')})`);
  }

  if (hasAssetStatusFilter(searchParams)) {
    searchFragments.push(`${mapAssetStatus(searchParams.assetStatusFilter)}:true`);
  }

  if (hasOrientationFilters(searchParams)) {
    searchFragments.push(
      `aspect:(${searchParams.orientationFilters
        .map((oFilter) => `${mapOrientation(oFilter)}`)
        .join(' OR ')})`,
    );
  }

  if (hasSkuFilters(searchParams)) {
    searchFragments.push(`(${searchParams.skuFilters.join(' OR ')})`);
  }

  if (hasUploadDateFilter(searchParams)) {
    const DATE_FORMAT = 'YYYY-MM-DD';

    switch (searchParams.uploadDateFilter.uploadDateEnum) {
      case AttachmentUploadDate.DateRange:
        searchFragments.push(
          `created_at:[${format(searchParams.uploadDateFilter.dateStart, DATE_FORMAT)} TO ${format(
            searchParams.uploadDateFilter.dateEnd,
            DATE_FORMAT,
          )}]`,
        );
        break;
      case AttachmentUploadDate.Last30Minutes:
        searchFragments.push(`created_at:>now-30m`);
        break;
      case AttachmentUploadDate.Past24Hours:
        searchFragments.push(`created_at:>now-1d`);
        break;
      case AttachmentUploadDate.Past7Days:
        searchFragments.push(`created_at:>now-7d`);
        break;
    }
  }

  if (hasLabelFilter(searchParams)) {
    searchFragments.push(`labels:"${searchParams.labelFilter.name}"`);
  }

  if (hasPinnedSearchFilter(searchParams)) {
    searchFragments.push(`(${searchParams.pinnedSearch.query})`);
  }

  return searchFragments.join(' AND ');
}

function mapAssetStatus(status: AssetStatus): string {
  switch (status) {
    case AssetStatus.Approved:
      return 'approved';
    case AssetStatus.Expired:
      return 'expired';
    case AssetStatus.Draft:
      return 'draft';
    case AssetStatus.Unapproved:
      return 'unapproved';
  }
}

function mapOrientation(orientation: AttachmentOrientation): string {
  switch (orientation) {
    case AttachmentOrientation.Horizontal:
      return 'landspace';
    case AttachmentOrientation.Panoramic:
      return 'panorama';
    case AttachmentOrientation.Square:
      return 'square';
    case AttachmentOrientation.Vertical:
      return 'portrait';
  }
}
