import {ResourceBaseSortableProperty} from "@integration-frontends/integration/core/model";

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface SortOptions<T = string>{
  direction: SortDirection;
  field: T;
}

export function setSortOptionsField(
  field: ResourceBaseSortableProperty,
  options: SortOptions,
): SortOptions {
  return { ...options, field };
}
