import { Disclosure } from '@headlessui/react';
import {
  ExpandableSectionIcon,
  SectionContent,
  SectionHeader,
} from '@integration-fontends/integration/ui/attachment-selector/components/advanced-filters/common';
import {
  SKU_SEARCH_KEY,
  TERMS_KEY,
} from '@integration-fontends/integration/ui/attachment-selector/i18n';
import { BFLabel, BFTextArea } from '@integration-frontends/common/ui';
import React from 'react';
import { SearchParameters } from '@integration-frontends/integration/core/model';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';

export interface SkuFilterComponentProps {
  searchParams: SearchParameters;
  onChange: (sku: string) => void;
}

export function SkuFilterComponent({ searchParams, onChange }: SkuFilterComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <Disclosure defaultOpen={searchParams?.skuFilters?.length > 0}>
      {({ open }) => (
        <>
          <SectionHeader>
            <Disclosure.Button as="div" className="flex justify-between">
              <span>{t(SKU_SEARCH_KEY)}</span>
              <ExpandableSectionIcon expanded={open} />
            </Disclosure.Button>
          </SectionHeader>

          <Disclosure.Panel>
            <SectionContent className="pb-lg">
              <BFLabel>{t(TERMS_KEY)}</BFLabel>
              <BFTextArea aria-label="Sku Search Terms" className="w-full" rows={5} onChange={(e) => onChange(e.target.value)} />
            </SectionContent>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
