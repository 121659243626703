import { AuthError } from '@integration-frontends/common/auth/core/model';
import {
  BFAnchor,
  BFButton,
  BFErrorLabel,
  BFInput,
  BFLabel,
  LogoHeader,
} from '@integration-frontends/common/ui';
import React from 'react';

import './set-api-key-form.scss';

export interface SetApiKeyFormProps {
  apiKey: string;
  error?: AuthError;
  hostUrl?: string;
  oauthClientId?: string;
  oauthBaseUrl?: string;
  onApiKeyChange: (key: string) => void;
  onSubmit: () => void;
}

export function SetApiKeyForm({ apiKey, error, onApiKeyChange, onSubmit, hostUrl, oauthClientId, oauthBaseUrl }: SetApiKeyFormProps) {
  return (
    <div className="w-full flex justify-center">
      <div className="auth">
        <LogoHeader />

        <div className="auth__content">
          <div className="auth__content__section auth__get-started">
            <span className="title text-primary">Get started</span>
            <p>
              To get started, paste your Brandfolder API key below.&nbsp;
              <BFAnchor
                data-cy="brandfolder-integrations-link"
                external={true}
                target="_blank"
                href="https://brandfolder.com/profile#integrations"
              >
                You can find it here.
              </BFAnchor>
            </p>
            <p>
              {/* Make optional and translate */}
              {/* <BFAnchor
                data-cy="brandfolder-integrations-link"
                external={true}
                // target="_blank"
                href={`${oauthBaseUrl}/oauth2/auth?client_id=${oauthClientId}&response_type=token&redirect_uri=${hostUrl}/callback/code&state=1599045135410-jFe&scope=offline_access`}
              >
                Sign in via oAuth2
              </BFAnchor> */}
            </p>
          </div>

          <div className="auth__content__section">
            <div className="flex flex-col api-key-input-container">
              <BFLabel htmlFor="brandfolder-api-key">API key</BFLabel>
              <BFInput
                id="brandfolder-api-key"
                type="text"
                value={apiKey || ''}
                onChange={(e) => onApiKeyChange(e.target.value)}
              />
              {error && <BFErrorLabel data-cy="error">{error.message}</BFErrorLabel>}
            </div>

            <BFButton className="w-full" data-cy="save" onClick={onSubmit} disabled={!apiKey}>
              Submit
            </BFButton>
          </div>

          <div className="auth__content__section auth__learn-more">
            <p>
              Don't have a Brandfolder account? <br />
              <BFAnchor
                data-cy="brandfolder-home-link"
                external={true}
                target="_blank"
                href="https://brandfolder.com/home"
              >
                Learn more
              </BFAnchor>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
