import {
  canResize,
  CropOptions,
  getCropDimensions,
  isAspectRatioLocked,
  setDimensions,
  setOffsets,
} from '@integration-frontends/integration/core/application';
import {
  Attachment,
  DimensionType,
  getAspectRatio,
  getAttachmentUrl,
} from '@integration-frontends/integration/core/model';
import 'cropperjs/dist/cropper.css';
import { ReactComponentLike } from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ReactCropper from 'react-cropper';

export interface CropperProps {
  attachment: Attachment;
  onCrop: (options: CropOptions) => void;
}

export const Cropper = forwardRef(({ attachment, onCrop }: CropperProps, ref) => {
  const cropperRef = useRef(null);
  const [initialized, setInitialized] = useState(false);
  const [cropOptions, setCropOptions] = useState<CropOptions>(null);

  useImperativeHandle(ref, () => ({
    setCropOptions(options: CropOptions) {
      setCropOptions(options);
    },
  }));

  function getCropperJs(): Cropper {
    return cropperRef?.current?.cropper;
  }

  const updateOptionsFromCropper = (cropOptions: CropOptions) => {
    const cropperjs = getCropperJs();
    const {
      width: canvasWidth,
      height: canvasHeight,
      top: canvasTop,
      left: canvasLeft,
    } = cropperjs.getCanvasData();
    const {
      width: cropBoxWidth,
      height: cropBoxHeight,
      top: cropBoxTop,
      left: cropBoxLeft,
    } = cropperjs.getCropBoxData();

    let updatedOptions = setOffsets(cropOptions, {
      offsetX: (cropBoxLeft - canvasLeft) / canvasWidth,
      offsetY: (cropBoxTop - canvasTop) / canvasHeight,
    });

    updatedOptions = setDimensions(updatedOptions, {
      width: cropBoxWidth / canvasWidth,
      height: cropBoxHeight / canvasHeight,
      type: DimensionType.Relative,
    });

    onCrop(updatedOptions);
  };

  function cropperJsReadyHandler() {
    setInitialized(true);
    const cropperjs = getCropperJs();

    getCropperJs().crop();
    const cropHandler = () => {
      updateOptionsFromCropper(cropOptions);
    };

    (cropperjs as any).element.addEventListener('crop', cropHandler);
  }

  // We are forced to use this hack to force the component to reinitialize because this
  // CropperJS wrapper doesn't update the underlying state whenever some of properties change.
  const generateReactCropperKey = () =>
    `${canResize(cropOptions).toString()}${
      isAspectRatioLocked(cropOptions) &&
      getAspectRatio(getCropDimensions(attachment.dimensions, cropOptions))
    }`;

  // TODO: ReactCropper's type inference isn't working properly. Need to figure out how to fix or ignore it.
  const TypeUnsafeReactCropper = ReactCropper as ReactComponentLike;
  return (
    cropOptions && (
      <TypeUnsafeReactCropper
        key={generateReactCropperKey()}
        ready={cropperJsReadyHandler}
        checkCrossOrigin={false}
        ref={cropperRef}
        src={getAttachmentUrl(attachment)}
        style={{ width: '100%', maxWidth: 500 }}
        responsive={true}
        cropBoxResizable={canResize(cropOptions)}
        aspectRatio={
          isAspectRatioLocked(cropOptions) &&
          getAspectRatio(getCropDimensions(attachment.dimensions, cropOptions))
        }
        autoCropArea={1}
        viewMode={1}
      />
    )
  );
});
