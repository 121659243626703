import classNames from 'classnames';
import React, { HTMLAttributes, ReactElement } from 'react';

export function withFormControlStyles<P extends HTMLAttributes<unknown>>(
  Component: (props: P) => ReactElement,
) {
  return React.forwardRef((props: P, ref) => {
    return (
      <Component
        ref={ref}
        {...props}
        className={classNames('border-bf-light-gray', 'rounded', props.className)}
      />
    );
  });
}
