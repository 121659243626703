import {AbsoluteDimensions} from "@integration-frontends/integration/core/model";

export enum Platform {
  Facebook = 'FACEBOOK',
  Hubspot = 'HUBSPOT',
  Instagram = 'INSTAGRAM',
  LinkedIn = 'LINKEDIN',
  Pinterest = 'PINTERERST',
  Twitter = 'TWITTER',
}

export type SocialMediaPlatform =
  | Platform.Facebook
  | Platform.Instagram
  | Platform.LinkedIn
  | Platform.Pinterest
  | Platform.Twitter;

export enum FacebookAssetType {
  Cover = 'FACEBOOK_COVER',
  EventCover = 'FACEBOOK_EVENT_COVER',
  Feed = 'FACEBOOK_FEED',
  Profile = 'FACEBOOK_PROFILE',
}

export enum InstagramAssetType {
  Feed = 'INSTAGRAM_FEED',
  Profile = 'INSTAGRAM_PROFILE',
  Story = 'INSTAGRAM_STORY',
  Thumbnail = 'INSTAGRAM_THUMBNAIL',
}

export enum LinkedInAssetType {
  Background = 'LINKEDIN_BACKGROUND',
  Banner = 'LINKEDIN_BANNER',
  Cover = 'LINKEDIN_COVER',
  Hero = 'LINKEDIN_HERO',
  Logo = 'LINKEDIN_LOGO',
  Profile = 'LINKEDIN_PROFILE',
  Shared = 'LINKEDIN_SHARED',
  SquareLogo = 'LINKEDIN_SQUARELOGO',
}

export enum PinterestAssetType {
  Profile = 'PINTEREST_PROFILE',
}

export enum TwitterAssetType {
  Header = 'TWITTER_HEADER',
  Profile = 'TWITTER_HEADER',
  TweetedImage = 'TWITTER_HEADER',
}

export type PlatformAssetType =
  | FacebookAssetType
  | InstagramAssetType
  | LinkedInAssetType
  | PinterestAssetType
  | TwitterAssetType;

export const DEFAULT_ASSET_TYPES = {
  [Platform.Facebook]: FacebookAssetType.Profile,
  [Platform.Instagram]: InstagramAssetType.Profile,
  [Platform.LinkedIn]: LinkedInAssetType.Profile,
  [Platform.Pinterest]: PinterestAssetType.Profile,
  [Platform.Twitter]: TwitterAssetType.Profile,
};

export const PLATFORM_ASSET_TYPE_DIMENSIONS = {
  [Platform.Facebook]: {
    [FacebookAssetType.Cover]: { width: 830, height: 312 },
    [FacebookAssetType.EventCover]: { width: 1920, height: 1005 },
    [FacebookAssetType.Feed]: { width: 1200, height: 630 },
    [FacebookAssetType.Profile]: { width: 180, height: 180 },
  } as Record<FacebookAssetType, AbsoluteDimensions>,
  [Platform.Instagram]: {
    [InstagramAssetType.Feed]: { width: 1080, height: 1080 },
    [InstagramAssetType.Profile]: { width: 110, height: 110 },
    [InstagramAssetType.Story]: { width: 1080, height: 1920 },
    [InstagramAssetType.Thumbnail]: { width: 161, height: 161 },
  } as Record<InstagramAssetType, AbsoluteDimensions>,
  [Platform.LinkedIn]: {
    [LinkedInAssetType.Background]: { width: 1584, height: 396 },
    [LinkedInAssetType.Banner]: { width: 646, height: 220 },
    [LinkedInAssetType.Cover]: { width: 1584, height: 768 },
    [LinkedInAssetType.Hero]: { width: 1128, height: 376 },
    [LinkedInAssetType.Logo]: { width: 300, height: 300 },
    [LinkedInAssetType.Profile]: { width: 400, height: 400 },
    [LinkedInAssetType.Shared]: { width: 1104, height: 736 },
    [LinkedInAssetType.SquareLogo]: { width: 60, height: 60 },
  } as Record<LinkedInAssetType, AbsoluteDimensions>,
  [Platform.Pinterest]: {
    [PinterestAssetType.Profile]: { width: 165, height: 165 },
  } as Record<PinterestAssetType, AbsoluteDimensions>,
  [Platform.Twitter]: {
    [TwitterAssetType.Header]: { width: 1500, height: 500 },
    [TwitterAssetType.Profile]: { width: 400, height: 400 },
    [TwitterAssetType.TweetedImage]: { width: 1200, height: 675 },
  } as Record<TwitterAssetType, AbsoluteDimensions>,
};
