import {
  AbsoluteDimensions,
  DimensionType,
  NULL_OFFSETS,
  Offsets,
  RelativeDimensions,
} from '@integration-frontends/integration/core/model';
import { merge } from 'ramda';
import { CropType } from './crop-type';
import { CustomCropOptions, getCustomImageOutputDimensions } from './custom-crop-options';
import { FreeformCropOptions } from './freeform-crop-options';
import {
  getSocialMediaImageOutputDimensions,
  SocialMediaCropOptions,
} from './social-media-crop-options';

export type CropOptions = CustomCropOptions | FreeformCropOptions | SocialMediaCropOptions;

export function getCropDimensions(
  imageDimensions: AbsoluteDimensions,
  options: CropOptions,
): AbsoluteDimensions {
  return options?.newDimensions
    ? {
        type: DimensionType.Absolute,
        width: Math.round(imageDimensions.width * options.newDimensions.width),
        height: Math.round(imageDimensions.height * options.newDimensions.height),
      }
    : imageDimensions;
}

export function getCropOffsets(imageDimensions: AbsoluteDimensions, options: CropOptions): Offsets {
  return options?.offsets
    ? {
        offsetX: imageDimensions.width * options.offsets.offsetX,
        offsetY: imageDimensions.height * options.offsets.offsetY,
      }
    : NULL_OFFSETS;
}

export function getOutputDimensions(
  imageDimensions: AbsoluteDimensions,
  options: CropOptions,
): AbsoluteDimensions {
  switch (options?.type) {
    case CropType.Custom:
      return getCustomImageOutputDimensions(options);
    case CropType.Freeform:
      return getCropDimensions(imageDimensions, options);
    case CropType.SocialMedia:
      return getSocialMediaImageOutputDimensions(options);
    default:
      return imageDimensions;
  }
}

export function setOffsets(options: CropOptions, offsets: Offsets): CropOptions {
  return merge(options, {
    offsets: { offsetX: Math.max(0, offsets.offsetX), offsetY: Math.max(0, offsets.offsetY) },
  });
}

export function getOffsets(options: CropOptions): Offsets {
  return options?.offsets || NULL_OFFSETS;
}

export function canResize(options: CropOptions): boolean {
  return !!options;
}

export function isAspectRatioLocked(options: CropOptions): boolean {
  return options.type !== CropType.Freeform || options.lockAspectRatio;
}

export function getRelativeCropDimensions(
  imageDimensions: AbsoluteDimensions,
  outputDimensions: AbsoluteDimensions,
): RelativeDimensions {
  const { width: imageWidth, height: imageHeight } = imageDimensions;
  const { width: outputWidth, height: outputHeight } = outputDimensions;
  const widthRatio = imageWidth / outputWidth;
  const heightRatio = imageHeight / outputHeight;

  if (widthRatio < heightRatio) {
    return {
      width: 1,
      height: (outputHeight * widthRatio) / imageHeight,
      type: DimensionType.Relative,
    };
  } else {
    return {
      width: (outputWidth * heightRatio) / imageWidth,
      height: 1,
      type: DimensionType.Relative,
    };
  }
}

export function centerCropper(options: CropOptions): CropOptions {
  const { newDimensions } = options;

  return setOffsets(options, {
    offsetX: (1 - newDimensions.width) / 2,
    offsetY: (1 - newDimensions.height) / 2,
  });
}

export function hasFixedOutputDimensions(options: CropOptions): boolean {
  return options.type !== CropType.Freeform;
}
