import { containerSelectors } from '@integration-frontends/integration/core/application';
import {
  removeCustomFieldFilter,
  SearchParameters,
  setCustomFieldFilter,
} from '@integration-frontends/integration/core/model';
import { createSelector } from '@reduxjs/toolkit';
import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CustomFieldsFiltersComponent,
  CustomFieldsFiltersComponentProps,
} from '@integration-fontends/integration/ui/attachment-selector/components/advanced-filters/components/custom-fields-filters/custom-fields-filters-component';

export interface CustomFieldsFiltersContainerProps {
  containerId: string;
  searchParams: SearchParameters;
  onChange: (searchParams: SearchParameters) => void;
  Component?: FunctionComponent<CustomFieldsFiltersComponentProps>;
}

export function CustomFieldFiltersContainer({
  containerId,
  searchParams,
  onChange,
  Component = CustomFieldsFiltersComponent,
}: CustomFieldsFiltersContainerProps) {
  const [selectedFieldId, setSelectedFieldId] = useState('');
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const customFieldEntities = useSelector(containerSelectors.customFields.selectEntities);
  const customFields = useSelector(
    createSelector(containerSelectors.customFields.byContainerId(selectedContainer?.id), (fields) =>
      fields?.filter((field) => !field.prioritized),
    ),
  );

  const customFieldFilters = Object.values(searchParams?.customFieldFilters || {}).filter(
    (filter) => {
      if (!filter) return false;
      return !customFieldEntities[filter.customField.id]?.prioritized;
    },
  );

  function keySelected(fieldId: string) {
    if (fieldId !== '') {
      setSelectedFieldId(fieldId);
    }
  }

  function valueSelected(value: string) {
    onChange(setCustomFieldFilter(searchParams, customFieldEntities[selectedFieldId], value));
    setSelectedFieldId('');
  }

  function removeFilter(id: string) {
    onChange(removeCustomFieldFilter(searchParams, id));
  }

  return (
    <Component
      customFieldFilters={customFieldFilters}
      customFields={customFields}
      onFilterRemove={removeFilter}
      onKeySelect={keySelected}
      onValueSelect={valueSelected}
      selectedFieldId={selectedFieldId}
    />
  );
}
