import { DimensionType } from '@integration-frontends/integration/core/model';
import { put, takeEvery } from 'redux-saga/effects';
import { getCropDimensions, getCropOffsets } from '../../model';
import { attachmentEdited, editAttachmentEnd } from '../actions';

const handler = function* (action: ReturnType<typeof attachmentEdited>) {
  const { attachment, options } = action.payload;
  const { cropOptions, labelOptions, linkOptions, resizingOptions, alignmentOptions } = options;
  const { width, height } = getCropDimensions(attachment.dimensions, cropOptions);
  const { offsetX, offsetY } = getCropOffsets(attachment.dimensions, cropOptions);

  yield put(
    editAttachmentEnd({
      attachment,
      labelOptions,
      linkOptions,
      transforms: {
        size: resizingOptions.newDimensions,
        crop: { width, height, offsetX, offsetY, type: DimensionType.Absolute },
      },
      alignment: alignmentOptions.alignment,
    }),
  );
};

export function* attachmentEditedEffects() {
  yield takeEvery(attachmentEdited, handler);
}
