import { Organization } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const organizationAdapter = createEntityAdapter<Organization>();

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: organizationAdapter.getInitialState(),
  reducers: {
    organizationsReceived: organizationAdapter.addMany,
  },
});
