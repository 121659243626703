import React, { useCallback, useEffect, useState } from 'react';
import {useSelector} from 'react-redux';
import { Attachment, AttachmentVariant } from '@integration-frontends/integration/core/model';
import {
  BFButton,
  BFDynamicButton,
  ButtonSize,
  ButtonType,
  IconCaretDownWhite,
  IconPaperclipWhite,
  IconSize,
} from '@integration-frontends/common/ui';
import { useMediaTypeSupport } from '@integration-frontends/integration/ui/common/use-media-type-support';
import { useAttachmentActions } from '@integration-fontends/integration/ui/attachment-selector/common/attachment-actions/use-attachment-action';
import { PLACE_KEY } from '@integration-fontends/integration/ui/attachment-selector/i18n';
import { useTranslation } from 'react-i18next';
import {
  INTEGRATION_COMMON_NAMESPACE,
  MULTI_SELECT_FILES_CURRENTLY_SELECTED_KEY,
} from '@integration-frontends/integration/ui/common/i18n';
import { usePopper } from 'react-popper';
import { Popover } from '@headlessui/react';
import { FlexSpacer } from '@integration-fontends/integration/ui/attachment-selector/common/layout/flex-spacer';
import {
  ATTACHMENT_ACTION_CONTAINER_RESET_MS,
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
} from '@integration-frontends/integration/ui';
import { IconSuccessCheckmark } from '@integration-frontends/common/ui/icons/icon-success-checkmark';
import './attachment-actions-container.scss';
import classNames from 'classnames';
import { PopoverMenu, PopoverMenuItem } from '@integration-frontends/common/ui/menu/popover-menu';
import { BFSpinner } from '@integration-frontends/common/ui/loader/spinner';
import { DI_CONTAINER } from '@integration-frontends/core';
import { selectNewNotifcations } from '@integration-frontends/common/notifications';

export const ATTACHMENT_ACTIONS_CONTAINER_COMPONENT_KEY = 'ATTACHMENT_ACTIONS_CONTAINER';

export interface AttachmentActionsContainerProps {
  attachments: (Attachment | AttachmentVariant)[];
  isMultiSelect?: boolean;
  display?: 'inline' | 'popover';
  size?: 'full' | 'dynamic';
  location: string,
}

/**
 * Reusable wrapper around attachment actions (such as placing, copying, etc).
 *
 * Options:
 * isMultiSelect: whether the actions should apply to a single or multiple attachments.
 * display:
 *  'inline': show the actions as inlined buttons
 *  'popover': show the actions as a popover
 * size:
 *  'full': take as much space as possible
 *  'dynamic': take just enough space to fit the content
 */
export function AttachmentActionsContainer(props: AttachmentActionsContainerProps) {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const Component =
    componentResolver.getComponent(ATTACHMENT_ACTIONS_CONTAINER_COMPONENT_KEY) ||
    DefaultAttachmentActionsContainer;

  return <Component {...props} />;
}

export function DefaultAttachmentActionsContainer({
  display = 'inline',
  ...restProps
}: AttachmentActionsContainerProps) {
  return (
    <div className="attachment-actions-container">
      {display === 'inline' ? <InlineActions {...restProps} /> : <PopoverActions {...restProps} />}
    </div>
  );
}

function InlineActions({ attachments, location }: Omit<AttachmentActionsContainerProps, 'display'>) {
  const { allSupported } = useMediaTypeSupport();
  const { attachmentActionContainers } = useAttachmentActions();

  return (
    <FlexSpacer size={'s'}>
      {attachmentActionContainers.map((Container, idx) => (
        <Container
          key={idx}
          selectedAttachments={attachments}
          location={location}
          render={({ id, enabled, success, label, onClick, ariaLabel, loading }) => {
            return (
              <BFButton
                className={classNames({
                  loading: loading,
                })}
                data-testid={`attachment-action-${id}`}
                disabled={
                  !enabled || !allSupported(attachments.map((attachment) => attachment?.mediaType))
                }
                onClick={onClick}
                size={ButtonSize.Small}
                iconBefore={success && <IconSuccessCheckmark />}
                buttonType={
                  success
                    ? ButtonType.Success
                    : idx === 0
                    ? ButtonType.Primary
                    : ButtonType.Secondary
                }
                ariaLabel={ariaLabel}
              >
                {label}
              </BFButton>
            );
          }}
        />
      ))}
    </FlexSpacer>
  );
}

function PopoverActions({
  attachments,
  isMultiSelect,
  size = 'dynamic',
  location,
}: Omit<AttachmentActionsContainerProps, 'display'>) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const { attachmentActionContainers } = useAttachmentActions();
  const { allSupported } = useMediaTypeSupport();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [buttonLabel, setButtonLabel] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: isMultiSelect ? 'top-start' : 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    strategy: 'absolute',
  });
  const [FirstActionContainer] = attachmentActionContainers;

  useEffect(() => {
    initialize();
  }, []);

  // reinitialize state some time after success
  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        initialize();
      }, ATTACHMENT_ACTION_CONTAINER_RESET_MS);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  useEffect(() => {
    updateDefaultButtonLabel();
  }, [attachments]);

  function initialize() {
    updateDefaultButtonLabel();
    setSuccess(false);
    setLoading(false);
  }

  function updateDefaultButtonLabel() {
    isMultiSelect ? setMultiSelectDefaultLabel() : setButtonLabel(t(PLACE_KEY));
  }

  function setMultiSelectDefaultLabel() {
    setButtonLabel(t(MULTI_SELECT_FILES_CURRENTLY_SELECTED_KEY, { count: attachments?.length }));
  }

  const onSuccess = useCallback((label: string) => {
    setButtonLabel(label);
    setSuccess(true);
    setLoading(false);
  }, []);

  const onLoading = useCallback((label: string) => {
    setButtonLabel(label);
    setLoading(true);
  }, []);

  const ButtonComponent = size === 'dynamic' ? BFDynamicButton : BFButton;

  return !isMultiSelect && attachmentActionContainers.length === 1 ? (
    <FirstActionContainer
      isMultiSelect={isMultiSelect}
      selectedAttachments={attachments}
      location={location}
      render={({ id, onClick, label }) => (
        <BFButton
          className={classNames({
            loading: loading,
          })}
          data-testid={`attachment-action-${id}`}
          onClick={onClick}
          style={{
            width: size === 'full' ? '100%' : 'auto',
          }}
          buttonType={success ? ButtonType.Success : ButtonType.Primary}
          iconBefore={success ? <IconSuccessCheckmark /> : loading && <BFSpinner />}
        >
          {label}
        </BFButton>
      )}
    />
  ) : (
    <Popover>
      {({ open }) => {
        return (
          <>
            <Popover.Button
              className="attachment-actions-popover-btn"
              as="div"
              ref={setReferenceElement}
            >
              <ButtonComponent
                data-testid="attachment-actions-popover-btn"
                size={ButtonSize.Small}
                className={classNames({
                  loading: loading,
                })}
                ariaLabel={buttonLabel}
                iconAfter={!success && !loading && <IconCaretDownWhite iconSize={IconSize.Small} />}
                iconBefore={
                  success ? (
                    <IconSuccessCheckmark />
                  ) : loading ? (
                    <BFSpinner />
                  ) : (
                    <IconPaperclipWhite />
                  )
                }
                buttonType={success ? ButtonType.Success : ButtonType.Primary}
                style={{
                  width: size === 'full' ? '100%' : 'auto',
                }}
              >
                <div className="label-container">{buttonLabel}</div>
              </ButtonComponent>
            </Popover.Button>

            <Popover.Panel
              static
              ref={setPopperElement}
              style={{
                ...styles.popper,
                minWidth: referenceElement?.getBoundingClientRect().width,
                zIndex: 100,
                visibility: open ? 'visible' : 'hidden',
              }}
              {...attributes.popper}
            >
              {({ close }) => {
                return (
                  <PopoverMenu>
                    {attachmentActionContainers.map((Container, idx) => (
                      <Container
                        key={idx}
                        isMultiSelect={isMultiSelect}
                        location={location}
                        selectedAttachments={attachments}
                        onLoading={onLoading}
                        onSuccess={onSuccess}
                        render={({ id, ariaLabel, enabled, Icon, label, onClick }) => {
                          return (
                            <PopoverMenuItem
                              onClick={(e) => {
                                close();
                                onClick(e);
                              }}
                              disabled={
                                !enabled ||
                                !allSupported(
                                  attachments.map((attachment) => attachment?.mediaType),
                                )
                              }
                              aria-label={ariaLabel}
                              data-testid={`attachment-action-${id}`}
                            >
                              <FlexSpacer size={'s'}>
                                {Icon && <Icon />}
                                {label}
                              </FlexSpacer>
                            </PopoverMenuItem>
                          );
                        }}
                      />
                    ))}
                  </PopoverMenu>
                );
              }}
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
}
