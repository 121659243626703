import { placeAttachment } from '@integration-frontends/integration/core/application';
import { push } from 'connected-react-router';
import { put, takeEvery } from 'redux-saga/effects';

const handler = function* (action: ReturnType<typeof placeAttachment>) {
  yield put(push(`/edit-attachment/${action.payload.attachment.id}`));
};

export function* selectAttachmentWorkflow() {
  yield takeEvery(placeAttachment, handler);
}
