import { put, take, takeEvery } from 'redux-saga/effects';
import {
  search,
  searchAssetsActions,
  SearchAssetsGroupBy,
  showPageSearch,
} from '@integration-frontends/integration/core/application';
import { select } from 'typed-redux-saga';
import { pageLoaded, selectRehydrated } from '@integration-frontends/common/app';

function* handler(action: ReturnType<typeof showPageSearch>) {
  const { pageSize = 16, groupBy = 'SECTION' } = action.payload.searchParams || {};
  yield put(searchAssetsActions.changePageSize({ pageSize }));
  yield put(searchAssetsActions.changeGroupBy({ groupBy: groupBy as SearchAssetsGroupBy }));
  yield put(search(action.payload));
}

export function* showPageSearchEffects() {
  const showPageSearchAction = yield take(showPageSearch);
  const isRehydrated = yield select(selectRehydrated);

  // ignore first search if using cached data
  if (!isRehydrated) {
    yield handler(showPageSearchAction);
  }

  yield put(pageLoaded());
  yield takeEvery(showPageSearch, handler);
}
