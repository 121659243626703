export const ACTIONS_MORE_OPTIONS_KEY = 'actionsMoreOptions';
export const ADVANCED_FILTERS_TITLE_KEY = 'advancedFiltersTitle';
export const ALL_KEY = 'all';
export const ALL_SECTIONS_KEY = 'all sections';
export const ALL_SELECTED_TAGS = 'allSelectedTags';
export const ALL_VISIBLE_KEY = 'allVisible';
export const ANY_KEY = 'any';
export const ANY_SELECTED_TAGS = 'anySelectedTags';
export const APPLY_KEY = 'apply';
export const ASSET_KEY = 'asset';
export const ASSETS_KEY = 'assets';
export const ASSETS_PER_KEY = 'Assets per page';
export const ATTACHMENT_KEY = 'attachment';
export const ATTACHMENT_CDN_LINK_KEY = 'attachmentCdnLink';
export const ATTACHMENTS_KEY = 'attachments';
export const BACK_TO_BRANDFOLDER_KEY = 'backToBrandfolder';
export const CANCEL_KEY = 'cancel';
export const CDN_EMBED_TITLE_KEY = 'cdnEmbedTitleKey';
export const CLEAR_FILTERS_KEY = 'clearFilters';
export const CLEAR_SEARCH_KEY = 'clearSearch';
export const CLEAR_KEY = 'clear';
export const CLEAR_LABELS_KEY = 'Clear labels';
export const COPIED_KEY = 'copied';
export const COPY_CDN_EMBED_LINK = 'copyCdnLink';
export const COPY_KEY = 'copy';
export const COPY_TO_CLIPBOARD_KEY = 'copy to clipboard';
export const CREATED_ON_KEY = 'createdOn';
export const CROP_IMAGE_KEY = 'cropImage';
export const DESELECT_KEY = 'Deselect';
export const DESCRIPTION_KEY = 'Description';
export const DETAILS_KEY = 'details';
export const DOWNLOAD_KEY = 'download';
export const NOT_SUPPORTED_KEY = 'notSupported';
export const COLLECTION_KEY = 'collection';
export const COLLECTIONS_KEY = 'collections';
export const EXPIRES_ON_KEY = 'expiresOn';
export const FILE_TYPE_LABEL_KEY = 'fileTypeLabel'
export const FILTER_KEY = 'filter';
export const FILTER_AND_SORT_KEY = 'filter&Sort';
export const FILTERS_KEY = 'filters';
export const FILTER_RESULTS_KEY = 'filterResults';
export const FILTERED_BY_KEY = 'filteredBy';
export const FILTERED_ITEMS_INCLUDE = 'filteredItemsInclude'
export const FROM_KEY = 'from';
export const HEIGHT_KEY = 'height';
export const HIDE_FILTERS_KEY = 'hideFilters';
export const HIDE_LABELS_KEY = 'Hide labels';
export const HORIZONTAL_KEY = 'horizontal';
export const KB_KEY = 'kb';
export const KEY_KEY = 'key';
export const LABEL_KEY = 'label';
export const LABELS_TITLE_KEY = 'labelsTitle';
export const LAST_UPDATED_ON_KEY = 'lastUpdateOn';
export const NEXT_KEY = 'next';
export const NONE_KEY = 'none';
export const OF_KEY = 'of';
export const OPEN_IN_BRANDFOLDER_KEY = 'openInBrandfolder';
export const ORGANIZE_BY_SECTION = 'organizeBySection'
export const ORIENTATION_KEY = 'orientation';
export const PANORAMIC_KEY = 'panoramic';
export const PINNED_SEARCH_KEY = 'pinnedSearch';
export const PINNED_SEARCHES_TITLE_KEY = 'pinnedSearchesTitle';
export const PLACE_KEY = 'place';
export const PLACEMENT_OPTIONS_KEY = 'placementOptions';
export const PREVIOUS_KEY = 'previous';
export const QUERY_KEY = 'query';
export const SEARCH_TITLE_KEY = 'searchTitle';
export const SEARCH_FILE_TYPES_KEY = 'searchFileTypes';
export const SEARCH_TAGS_KEY = 'searchTags';
export const SEARCH_NO_RESULTS_KEY = 'searchNoResults';
export const SEARCHING_KEY = 'searching';
export const SELECT_KEY = 'select';
export const SELECT_KEY_KEY = 'selectKey';
export const SELECT_VALUE_KEY = 'selectValue';
export const SHARE_KEY = 'share';
export const SHOW_ATTACHMENTS_KEY = 'showAttachments';
export const SHOW_ATTACHMENTS_EXPAND_KEY = 'showAttachmentsExpand';
export const SKU_KEY = 'sku';
export const SKUS_KEY = 'skus';
export const SQUARE_KEY = 'square';
export const STATUS_KEY = 'status';
export const TAG_KEY = 'tag';
export const TAGS_KEY = 'tags';
export const TERMS_KEY = 'terms';
export const TO_KEY = 'to';
export const TOP_TAGS_KEY = 'topTags';
export const TOP_FILE_TYPES_KEY = 'topFileTypes';
export const TYPE_KEY = 'type';
export const CUSTOM_FIELDS_KEY = 'customFields';
export const SAVE_KEY = 'save';
export const SKU_SEARCH_KEY = 'skuSearch';
export const SORT_BY_LABEL_KEY = 'sortBy';
export const UNSUPPORTED_ASSET_KEY = 'unsupportedAsset';
export const UNSUPPORTED_ATTACHMENT_KEY = 'unsupportedAttachment';
export const UPLOAD_DATE_KEY = 'uploadDate';
export const UPLOAD_DATE_LAST_30_MINS_KEY = 'uploadDateLast30Mins';
export const UPLOAD_DATE_PAST_24_HOURS_KEY = 'uploadDatePast24Hours';
export const UPLOAD_DATE_PAST_7_DAYS_KEY = 'uploadDatePast7Days';
export const UPLOAD_DATE_RANGE_KEY = 'uploadDateRange';
export const VALUE_KEY = 'value';
export const VERTICAL_KEY = 'vertical';
export const VIEW_ASSET_DETAILS_KEY = 'viewAssetDetails';
export const VIEW_DETAILS_KEY = 'viewDetails';
export const VIEW_OPTIONS_KEY = 'viewOptions';
export const WIDTH_KEY = 'width';
