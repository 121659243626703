import { assetAttachmentsSelector } from '@integration-frontends/integration/core/application';
import {
  Asset as AssetModel,
  positionComparator,
} from '@integration-frontends/integration/core/model';
import React, { HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { AttachmentThumbnail } from './attachment-thumbnail';
import { AssetThumbnail } from './asset-thumbnail';
import { useInView } from 'react-intersection-observer';
import { useScrollingContainer } from '@integration-frontends/common/ui';

export interface AssetThumbnailsProps {
  asset: AssetModel;
  containerId: string;
  responsive?: boolean;
  showAttachments?: boolean;
  onClick?: () => void;
}

function LazyLoadedThumbnail(props: HTMLAttributes<any>) {
  const { ref: scrollContainerRef } = useScrollingContainer();
  const { ref, inView } = useInView({
    triggerOnce: true,
    root: scrollContainerRef?.current,
    rootMargin: '500px 0px',
  });

  return (
    <div ref={ref}>{inView ? props.children : <div style={{ width: 150, height: 150 }} />}</div>
  );
}

export function AssetThumbnails({
  asset,
  containerId,
  responsive,
  showAttachments = false,
}: AssetThumbnailsProps) {
  const attachments = useSelector(assetAttachmentsSelector(asset)).sort(positionComparator);

  return (
    <>
      {showAttachments && attachments.length ? (
        attachments.map((attachment, index) => (
          <LazyLoadedThumbnail key={attachment.id}>
            <AttachmentThumbnail
              asset={asset}
              attachment={attachment}
              containerId={containerId}
              index={index}
              responsive={responsive}
            />
          </LazyLoadedThumbnail>
        ))
      ) : (
        <LazyLoadedThumbnail key={asset.id}>
          <AssetThumbnail
            asset={asset}
            attachments={attachments}
            containerId={containerId}
            responsive={responsive}
          />
        </LazyLoadedThumbnail>
      )}
    </>
  );
}
