import { createReducer } from '@reduxjs/toolkit';
import { editAttachmentInit, editAttachmentInitSuccess } from './actions';

export interface EditAttachmentState {
  loading: boolean;
}

const initialState: EditAttachmentState = {
  loading: false,
};

export const EDIT_ATTACHMENT_REDUCER = createReducer(initialState, (builder) => {
  builder
    .addCase(editAttachmentInit, (state) => {
      state.loading = true;
    })
    .addCase(editAttachmentInitSuccess, (state) => {
      state.loading = false;
    });
});
