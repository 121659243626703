import {
  Asset,
  AssetCustomFieldValue,
  AssetTag,
  Attachment,
  Brandfolder,
  Collection,
  ContainerCustomField,
  ContainerFileTypeAggregates,
  ContainerTag,
  DimensionType,
  ImageType,
  Label,
  Organization,
  ResourceType,
  SearchFilter,
  Section,
} from '@integration-frontends/integration/core/model';

export const brandfoldersMockData: Brandfolder[] = [
  {
    id: '0',
    name: 'Org1Bf1',
    type: ResourceType.BRANDFOLDER,
    organizationId: '0',
    assetCount: 1,
    cardImage: 'https://picsum.photos/25',
    hasAccess: true,
    position: 0,
  },
  {
    id: '1',
    name: 'Org1Bf2',
    type: ResourceType.BRANDFOLDER,
    organizationId: '0',
    assetCount: 5,
    cardImage: 'https://picsum.photos/65/22',
    hasAccess: true,
    position: 0,
  },
  {
    id: '2',
    name: 'Org1Bf3WithLongName',
    type: ResourceType.BRANDFOLDER,
    organizationId: '0',
    assetCount: 1000,
    cardImage: 'https://picsum.photos/60/40',
    hasAccess: true,
    position: 0,
  },
];

export const collectionsMockData: Collection[] = [
  {
    id: '3',
    name: 'Org1Col1',
    type: ResourceType.COLLECTION,
    brandfolderId: '0',
    assetCount: 5,
    cardImage: 'https://picsum.photos/25',
    hasAccess: true,
    position: 0,
  },
  {
    id: '4',
    name: 'Org1Col2',
    type: ResourceType.COLLECTION,
    brandfolderId: '0',
    assetCount: 5,
    cardImage: 'https://picsum.photos/65/22',
    hasAccess: true,
    position: 0,
  },
];

export const sectionsMockData: Section[] = [
  {
    id: '0',
    name: 'section1',
    type: ResourceType.SECTION,
    brandfolderId: '0',
    assetCount: 2,
    position: 1,
  },
  {
    id: '1',
    name: 'section2',
    type: ResourceType.SECTION,
    brandfolderId: '0',
    assetCount: 999,
    position: 2,
  },
];

export const organizationsMockData: Organization[] = [
  {
    id: '0',
    name: 'Org1',
    type: ResourceType.ORGANIZATION,
    position: 0,
  },
  {
    id: '1',
    name: 'Org2WithLongName',
    type: ResourceType.ORGANIZATION,
    position: 0,
  },
];

export const assetsMockData: Asset[] = [
  {
    id: '0',
    type: ResourceType.ASSET,
    name: 'Asset1',
    sectionId: '0',
    thumbnailUrl: 'https://picsum.photos/50/125',
    attachmentCount: 3,
    createdAt: new Date(),
    updatedAt: new Date(),
    availabilityEnd: new Date(),
    position: 0,
  },
  {
    id: '1',
    type: ResourceType.ASSET,
    name: 'Asset2',
    sectionId: '0',
    thumbnailUrl: 'https://picsum.photos/125/125',
    attachmentCount: 1,
    position: 0,
  },
];

export const attachmentsMockData: Attachment[] = [
  {
    id: '0',
    thumbnailUrl: 'https://picsum.photos/50/125#https://cdn.bfldr.com',
    name: 'Attachment1',
    type: ResourceType.ATTACHMENT,
    url: 'https://picsum.photos/1024/720',
    filename: 'attachment1.png',
    mimetype: 'png',
    extension: 'png',
    assetId: '0',
    dimensions: { type: DimensionType.Absolute, width: 1024, height: 720 },
    sizeInBytes: 123456,
    mediaType: ImageType.Png,
    supported: Promise.resolve(true),
    updatedAt: new Date('2000-01-01T00:00:00.000Z'),
    createdAt: new Date('2000-01-01T00:00:00.000Z'),
    position: 0,
  },
  {
    id: '1',
    thumbnailUrl: 'https://picsum.photos/125/50',
    name: 'brandfolder-landing-banner-longs',
    type: ResourceType.ATTACHMENT,
    url: 'https://picsum.photos/500/500',
    filename: 'brandfolder-landing-banner.png',
    mimetype: 'png',
    extension: 'png',
    assetId: '0',
    dimensions: { type: DimensionType.Absolute, width: 1600, height: 300 },
    sizeInBytes: 123456,
    mediaType: ImageType.Png,
    supported: Promise.resolve(true),
    updatedAt: new Date(),
    createdAt: new Date(),
    position: 0,
  },
  {
    id: '2',
    thumbnailUrl: 'https://picsum.photos/125/125',
    name: 'Attachment3',
    type: ResourceType.ATTACHMENT,
    url: 'https://picsum.photos/720/1024',
    filename: 'attachment3.png',
    mimetype: 'png',
    extension: 'png',
    assetId: '0',
    dimensions: { type: DimensionType.Absolute, width: 720, height: 1024 },
    sizeInBytes: 123456,
    mediaType: ImageType.Png,
    supported: Promise.resolve(true),
    updatedAt: new Date(),
    createdAt: new Date(),
    position: 0,
  },
  {
    id: '3',
    thumbnailUrl: 'https://picsum.photos/125/125',
    name: 'Attachment3',
    type: ResourceType.ATTACHMENT,
    url: 'https://picsum.photos/720/1024',
    filename: 'attachment3.png',
    mimetype: 'png',
    extension: 'png',
    assetId: '1',
    dimensions: { type: DimensionType.Absolute, width: 720, height: 1024 },
    sizeInBytes: 123456,
    mediaType: ImageType.Png,
    supported: Promise.resolve(true),
    updatedAt: new Date(),
    createdAt: new Date(),
    position: 0,
  },
];

export const containerTagsMockData: ContainerTag[] = [
  {
    id: '0',
    type: ResourceType.TAG,
    containerId: '0',
    name: 'brandfolder_tag1',
    count: 9,
    searchable: true,
    position: 0,
  },
  {
    id: '1',
    type: ResourceType.TAG,
    containerId: '0',
    name: 'brandfolder_tag2',
    count: 99,
    searchable: true,
    position: 0,
  },
  {
    id: '2',
    type: ResourceType.TAG,
    containerId: '0',
    name: 'brandfolder_tag3',
    count: 999,
    searchable: true,
    position: 0,
  },
];

export const containerFileTypeAggregatesMockData: ContainerFileTypeAggregates[] = [
  { containerId: '0', name: 'png', count: 9 },
  { containerId: '0', name: 'jpg', count: 99 },
  { containerId: '0', name: 'svg', count: 999 },
];

export const containerCustomFieldsMockData: ContainerCustomField[] = [
  {
    id: '0',
    type: ResourceType.CUSTOM_FIELD,
    containerId: '0',
    name: 'Custom Field 1',
    prioritized: true,
    searchable: true,
    allowedValues: ['value1', 'value2'],
    position: 0,
  },
  {
    id: '1',
    type: ResourceType.CUSTOM_FIELD,
    containerId: '0',
    name: 'Custom Field 2',
    prioritized: true,
    searchable: true,
    allowedValues: ['value1', 'value2'],
    position: 0,
  },
  {
    id: '2',
    type: ResourceType.CUSTOM_FIELD,
    containerId: '0',
    name: 'Custom Field 3',
    prioritized: false,
    searchable: true,
    allowedValues: ['value1', 'value2'],
    position: 0,
  },
  {
    id: '3',
    type: ResourceType.CUSTOM_FIELD,
    containerId: '0',
    name: 'Custom Field 4',
    prioritized: false,
    searchable: true,
    allowedValues: ['value1', 'value2'],
    position: 0,
  },
];

export const assetCustomFieldValuesMockData: AssetCustomFieldValue[] = [
  {
    id: '0',
    type: ResourceType.CUSTOM_FIELD_VALUE,
    assetId: '0',
    key: 'key1',
    value: 'value1',
    position: 0,
  },
  {
    id: '1',
    type: ResourceType.CUSTOM_FIELD_VALUE,
    assetId: '0',
    key: 'key2',
    value: 'value2',
    position: 0,
  },
  {
    id: '2',
    type: ResourceType.CUSTOM_FIELD_VALUE,
    assetId: '0',
    key: 'key3',
    value: 'value3',
    position: 0,
  },
];

export const assetTagsMockData: AssetTag[] = [
  {
    id: '0',
    type: ResourceType.TAG,
    assetId: '0',
    name: 'asset_tag1',
    autoGenerated: true,
    position: 0,
  },
  {
    id: '1',
    type: ResourceType.TAG,
    assetId: '0',
    name: 'asset_tag2',
    autoGenerated: true,
    position: 0,
  },
  {
    id: '2',
    type: ResourceType.TAG,
    assetId: '0',
    name: 'asset_tag3',
    autoGenerated: false,
    position: 0,
  },
  {
    id: '3',
    type: ResourceType.TAG,
    assetId: '0',
    name: 'asset_tag4',
    autoGenerated: false,
    position: 0,
  },
];

export const searchFiltersMockData: SearchFilter[] = [
  {
    id: '1',
    type: ResourceType.SEARCH_FILTER,
    label: 'SearchFilter1',
    query: 'SearchFilter1',
    featured: true,
    containerId: '1',
    position: 1,
  },
  {
    id: '2',
    type: ResourceType.SEARCH_FILTER,
    label: 'SearchFilter2',
    query: 'SearchFilter2',
    featured: false,
    containerId: '1',
    position: 2,
  },
];

export const labelsMockData: Label[] = [
  {
    id: '1',
    type: ResourceType.LABEL,
    containerId: '0',
    path: ['1'],
    depth: 1,
    position: 1,
  },
  {
    id: '2',
    type: ResourceType.LABEL,
    containerId: '0',
    path: ['1', '2'],
    depth: 2,
    position: 1,
  },
];
