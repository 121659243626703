import {
  ATTACHMENT_ACTION_CONTAINER_RESET_MS,
  AttachmentActionContainerBaseProps,
} from '@integration-frontends/integration/ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  INTEGRATION_COMMON_NAMESPACE,
  PLACE_SUCCESS_LABEL_KEY,
} from '@integration-frontends/integration/ui/common/i18n';
import { PLACE_KEY } from '@integration-fontends/integration/ui/attachment-selector/i18n';
import { useDispatch } from 'react-redux';
import {
  initiatePlaceAttachment,
  multiSelectAttachmentEnded,
} from '@integration-frontends/integration/core/application';
import { useMediaTypeSupport } from '@integration-frontends/integration/ui/common/use-media-type-support';
import { IconAdd, IconAddDisabled } from '@integration-frontends/common/ui';
import { noop } from 'lodash';

export function PlaceAttachmentActionContainer({
  selectedAttachments,
  render,
  onSuccess = noop,
  isMultiSelect = false,
}: AttachmentActionContainerBaseProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const [label, setLabel] = useState<string>(t(PLACE_KEY));
  const [success, setSuccess] = useState(false);
  const [attachment] = selectedAttachments;
  const { isSupported } = useMediaTypeSupport();

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        initialize();
        onSuccess();
      }, ATTACHMENT_ACTION_CONTAINER_RESET_MS);

      return () => clearTimeout(timeout);
    }
  }, [success]);

  useEffect(() => {
    initialize();
  }, [selectedAttachments]);

  function initialize() {
    setLabel(t(PLACE_KEY));
    setSuccess(false);
  }

  const clicked = useCallback(() => {
    dispatch(
      isMultiSelect
        ? multiSelectAttachmentEnded({ attachments: selectedAttachments })
        : initiatePlaceAttachment({ attachment }),
    );
    setSuccess(true);
    setLabel(t(PLACE_SUCCESS_LABEL_KEY));
    onSuccess(t(PLACE_SUCCESS_LABEL_KEY));
  }, [attachment]);

  const enabled = isSupported(attachment?.mediaType)

  return render({
    id: 'PLACE',
    label,
    onClick: clicked,
    success,
    enabled,
    Icon: enabled ?  IconAdd : IconAddDisabled,
    loading: false,
    ariaLabel: t(PLACE_KEY),
  });
}
