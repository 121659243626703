import {
  ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  AttachmentSelectorFeature,
  IAttachmentSelectorFeaturesService,
} from '@integration-frontends/integration/ui';
import { DI_CONTAINER } from '@integration-frontends/core';

export function useFeatures() {
  const featureService: IAttachmentSelectorFeaturesService = DI_CONTAINER.get(
    ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  );

  return {
    hasFeature: (feature: AttachmentSelectorFeature) => featureService.hasFeature(feature),
  };
}
