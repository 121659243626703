import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

export const initI18n = (locale = 'en') => {
  i18next.use(initReactI18next).init({ lng: locale, fallbackLng: 'en' });
};

export const getI18nInstance = () => {
  return i18next;
}
