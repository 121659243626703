import React from 'react';
import { AttachmentActionsContainer } from '../../../common/attachment-actions/attachment-actions-container';
import { FlexSpacer } from '@integration-fontends/integration/ui/attachment-selector/common/layout/flex-spacer';
import {
  AttachmentOptionsComponent,
  AttachmentOptionsComponentProps,
} from '@integration-fontends/integration/ui/attachment-selector/common/attachment-transforms/attachment-options-component';

export type PlacementOptionsProps = AttachmentOptionsComponentProps;

export function PlacementOptions(props: PlacementOptionsProps) {
  return (
    <FlexSpacer direction={'vertical'} data-testid="placement-options">
      <AttachmentOptionsComponent {...props} />

      <FlexSpacer>
        <div className="grow" />
        <div className="grow">
          <AttachmentActionsContainer
            attachments={[props.attachment]}
            display={'popover'}
            isMultiSelect={false}
            size={'full'}
            location="asset-details"
          />
        </div>
      </FlexSpacer>
    </FlexSpacer>
  );
}
