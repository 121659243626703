import { assetTagEntitySelectors } from '@integration-frontends/integration/core/application';
import { Attachment } from '@integration-frontends/integration/core/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SectionTitle } from '../../../common/layout';
import { Tags } from '../../common/tags';
import { TAGS_KEY } from '../../i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';

export interface TagsProps {
  attachment: Attachment;
}

export function AttachmentTags({ attachment }: TagsProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const tags = useSelector(assetTagEntitySelectors.byAssetId(attachment.assetId));

  return (
    <>
      {tags.length > 0 && (
        <div className="mb-md">
          <SectionTitle>{t(TAGS_KEY)}</SectionTitle>
          <Tags tags={tags} />
        </div>
      )}
    </>
  );
}
