import React, { ReactElement } from 'react';
import './thumbnail-list.scss';

export interface ThumbnailListProps {
  children: ReactElement[];
  responsive: boolean;
}

export function ThumbnailList({ children, responsive }: ThumbnailListProps) {
  return responsive ? (
    <div data-testid="thumbnail-list" className="responsive-thumbnail-list">
      {children}
    </div>
  ) : (
    <div data-testid="thumbnail-list" className="flex justify-between gap-6 flex-wrap">
      {children}
    </div>
  );
}
