export function enterToClick(e){
    if (e.keyCode === 13) {
      e.target.click();
    }
};

export function keyToClick(e){
    if (e.keyCode === 32 || e.keyCode === 13) {
      e.target.click();
    }
};

export function arrowKeyNavigate(e, idx, list, setter) {
  // up arrow
  if (e.keyCode === 38) {
    e.preventDefault();
    if (idx > 0 ) {
      setter(idx - 1);
      list[idx - 1].focus();
    }
  }

  // down arrow
  if (e.keyCode === 40) {
    e.preventDefault();
    if (idx < list.length ) {
      setter(idx + 1);
      list[idx + 1].focus();
    }
  }
  return false;
};

export const addToRefs = (ref, refList) => {
  if (ref) {
    refList.push(ref);
  }
};
