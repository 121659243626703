import { BFSearchInput, IconPin, IconSize } from '@integration-frontends/common/ui';
import {
  searchAssetsSelectors,
} from '@integration-frontends/integration/core/application';
import { setQueryFilter } from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { HTMLAttributes, InputHTMLAttributes, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAttachmentSelectorNavigation } from '../../../navigation';
import { useTranslation } from 'react-i18next';
import {
  INTEGRATION_COMMON_NAMESPACE,
  SEARCH_PLACEHOLDER_KEY,
} from '@integration-frontends/integration/ui/common/i18n';

export interface SearchInputProps {
  inputHeight?: number;
}

export function SearchInput({
  className,
  ...props
}: SearchInputProps & HTMLAttributes<HTMLInputElement> & InputHTMLAttributes<HTMLInputElement>) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const searchParams = useSelector(searchAssetsSelectors.searchParams);
  const container = useSelector(searchAssetsSelectors.container);
  const navigation = useAttachmentSelectorNavigation();

  const searchQueryChangedHandler = (query: string) => {
    navigation.goToShowPage(container.id, setQueryFilter(query || null, searchParams));
  };

  return (
    <div className={classNames(className)}>
      <div className="border border-bf-light-gray rounded relative transition ease-in-out hover:border-bf-dark-gray">
        <BFSearchInput
          data-testid="assets-search-input"
          className={classNames(
            'w-full search-ph-md transition ease-in-out duration-300 border-none h-5',
          )}
          placeholder={t(SEARCH_PLACEHOLDER_KEY)}
          onChange={(e) => searchQueryChangedHandler(e.target.value)}
          value={searchParams?.query || ''}
          style={{ height: props.inputHeight || 38 }}
          {...props}
        />
      </div>
    </div>
  );
}
