import { selectAttachmentSelectors } from '@integration-frontends/integration/core/application';
import React from 'react';
import { useSelector } from 'react-redux';
import './multi-select-actions-menu.scss';
import { AttachmentActionsContainer } from '@integration-fontends/integration/ui/attachment-selector/common/attachment-actions/attachment-actions-container';

export function MultiSelectActionsMenu() {
  const selectedAttachments = useSelector(selectAttachmentSelectors.selectedAttachments);
  return (
    <AttachmentActionsContainer
      attachments={selectedAttachments}
      isMultiSelect={true}
      display={'popover'}
      location="multi-select"
    />
  );
}
