import { FlexSpacer } from '@integration-fontends/integration/ui/attachment-selector/common/layout/flex-spacer';
import { FormControl } from '@integration-fontends/integration/ui/attachment-selector/common/layout/form';
import {
  FILE_TYPE_LABEL_KEY,
  HEIGHT_KEY,
  WIDTH_KEY,
} from '@integration-fontends/integration/ui/attachment-selector/i18n';
import { BFInput, BFSelect, BFSelectSize, InputSize } from '@integration-frontends/common/ui';
import {
  Attachment,
  AttachmentVariant,
  getImageMediaTypes,
  getMediaTypesExtensions,
  getVideoMediaTypes,
  isImageType,
  isVideoType,
  MediaType,
} from '@integration-frontends/integration/core/model';
import {
  ResizingOptions,
  setHeight,
  setWidth,
} from '@integration-frontends/integration/core/application';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import { useMediaTypeSupport } from '@integration-frontends/integration/ui/common/use-media-type-support';

export interface AttachmentOptionsComponentProps {
  attachment: Attachment | AttachmentVariant;
  selectedMediaType: MediaType;
  onMediaTypeChange: (mediaType: string) => void;
  userResizingOptions: ResizingOptions;
  onResize: (resizingOptions: ResizingOptions) => void;
}

export function AttachmentOptionsComponent({
  attachment,
  onMediaTypeChange,
  onResize,
  selectedMediaType,
  userResizingOptions,
}: AttachmentOptionsComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const { supportedMediaTypes } = useMediaTypeSupport();
  const exportMediaTypes: MediaType[] = isImageType(attachment.mediaType)
    ? getImageMediaTypes(supportedMediaTypes)
    : isVideoType(attachment.mediaType)
    ? getVideoMediaTypes(supportedMediaTypes)
    : [];

  return (
    <FlexSpacer direction={'vertical'}>
      <FlexSpacer>
        <div className="grow">
          <FormControl label={t(FILE_TYPE_LABEL_KEY)}>
            <BFSelect
              size={BFSelectSize.Medium}
              value={selectedMediaType}
              data-testid="file-type-select"
              onOptionChange={(opt) => opt && onMediaTypeChange(opt.value as MediaType)}
              style={{ width: '100%' }}
              options={exportMediaTypes.map((mediaType) => ({
                value: mediaType,
                label: getMediaTypesExtensions([mediaType])?.[0].toLocaleUpperCase(),
              }))}
            />
          </FormControl>
        </div>
        <div className="grow" />
      </FlexSpacer>

      <FlexSpacer>
        <FlexSpacer direction={'vertical'} size={'xs'} className="grow">
          <FormControl label={t(WIDTH_KEY)}>
            <BFInput
              size={InputSize.Medium}
              data-testid="width-input"
              className="w-full"
              type="number"
              value={Math.round(userResizingOptions?.newDimensions.width) || ''}
              onChange={(e) => onResize(setWidth(userResizingOptions, parseInt(e.target.value)))}
            />
          </FormControl>
        </FlexSpacer>

        <FlexSpacer direction={'vertical'} size={'xs'} className="grow">
          <FormControl label={t(HEIGHT_KEY)}>
            <BFInput
              size={InputSize.Medium}
              data-testid="height-input"
              className="w-full"
              type="number"
              value={Math.round(userResizingOptions?.newDimensions.height) || ''}
              onChange={(e) => onResize(setHeight(userResizingOptions, parseInt(e.target.value)))}
            />
          </FormControl>
        </FlexSpacer>
      </FlexSpacer>
    </FlexSpacer>
  );
}
