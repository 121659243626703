import React, { ButtonHTMLAttributes, HTMLAttributes } from 'react';
import { BFPanel } from '@integration-frontends/common/ui';
import classNames from 'classnames';
import './popover-menu.scss';

export function PopoverMenu(props: HTMLAttributes<any>) {
  return <BFPanel className="popover-menu" {...props} />;
}

export function PopoverMenuItem(props: ButtonHTMLAttributes<any>) {
  return (
    <button
      {...props}
      className={classNames(
        'popover-menu-item',
        'hover:bg-bf-marshmallow-light w-max whitespace-nowrap',
        props.className,
      )}
    />
  );
}
