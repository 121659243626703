import { createReducer } from '@reduxjs/toolkit';
import {
  addAssets,
  backToHome,
  removeAsset,
  selectContainer,
  selectOrganization,
  selectSection,
  upload,
  uploadAssetsInit,
  uploadAssetsInitSuccess,
  uploadMore,
  uploadSuccess,
} from './actions';

export type AssetData = { name: string; url: string };

export interface UploadAssetsState {
  assets: AssetData[];
  loading: boolean;
  selectedContainerId: string;
  selectedOrganizationId: string;
  selectedSectionId: string;
  success: boolean;
  uploading: boolean;
}

const initialState: UploadAssetsState = {
  assets: [],
  loading: false,
  selectedContainerId: null,
  selectedOrganizationId: null,
  selectedSectionId: null,
  success: false,
  uploading: false,
};

export const UPLOAD_ASSETS_REDUCER = createReducer(initialState, (builder) =>
  builder
    .addCase(uploadAssetsInit, (state) => {
      state.loading = true;
    })
    .addCase(uploadAssetsInitSuccess, (state) => {
      state.loading = false;
    })
    .addCase(selectOrganization, (state, action) => {
      state.selectedOrganizationId = action.payload.id;
      state.selectedContainerId = null;
      state.selectedSectionId = null;
    })
    .addCase(selectContainer, (state, action) => {
      state.selectedContainerId = action.payload.container.id;
      state.selectedSectionId = null;
    })
    .addCase(selectSection, (state, action) => {
      state.selectedSectionId = action.payload.id;
    })
    .addCase(addAssets, (state, action) => {
      state.assets = action.payload.assets.concat(state.assets);
    })
    .addCase(removeAsset, (state, action) => {
      state.assets = state.assets.filter((img) => img.name !== action.payload.asset.name);
    })
    .addCase(upload, (state) => {
      state.uploading = true;
    })
    .addCase(uploadSuccess, (state) => {
      state.uploading = false;
      state.success = true;
    })
    .addCase(uploadMore, () => {
      return initialState;
    })
    .addCase(backToHome, () => {
      return initialState;
    }),
);
