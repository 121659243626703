import { Attachment, AttachmentVariant } from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { AttachmentThumbnailImage } from '../../../common/attachment-thumbnail-image';
import { Thumbnail } from '../../common';

export interface PrimaryImageProps {
  attachment: Attachment | AttachmentVariant;
}

export function PrimaryImage({
  attachment,
  className,
  style,
  ...props
}: PrimaryImageProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <Thumbnail
      className={classNames('w-full', className)}
      style={{ maxHeight: 250, maxWidth: 350, ...style }}
      {...props}
    >
      <div className="p-lg w-full h-full flex justify-center items-center">
        <AttachmentThumbnailImage attachment={attachment} useFullSize={true} />
      </div>
    </Thumbnail>
  );
}
