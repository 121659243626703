import { BFLabel, BFSelect } from '@integration-frontends/common/ui';
import {
  createCustomCropOptions,
  createFreeformCropOptions,
  createSocialMediaCropOptions,
  CropOptions,
  CropType,
  setAssetType,
  setCustomDimension,
  setSocialMedia,
} from '@integration-frontends/integration/core/application';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CROP_TYPE_LABEL_KEY,
  IMAGE_SIZE_LABEL_KEY,
  SOCIAL_MEDIA_LABEL_KEY,
} from '../../common/i18n';
import {
  getCropTypeOptions,
  getCustomImageSizeOptions,
  getSocialMediaImageSizeOptions,
  getSocialMediaOptions,
  Option,
} from './model';
import {
  CustomDimension,
  PlatformAssetType,
  SocialMediaPlatform,
} from '@integration-frontends/integration/core/model';

export interface CropperOptionsProps {
  options?: CropOptions;
  onChange?: (options: CropOptions) => void;
}

export function CropperOptions({ options, onChange }: CropperOptionsProps) {
  const { t } = useTranslation('integration');

  function toOptionElement(option: Option) {
    return (
      <option key={option.value} value={option.value}>
        {t(option.i18nKey)}
      </option>
    );
  }

  function selectCropType(cropType: CropType) {
    switch (cropType) {
      case CropType.Custom:
        onChange(createCustomCropOptions());
        break;
      case CropType.Freeform:
        onChange(createFreeformCropOptions());
        break;
      case CropType.SocialMedia:
        onChange(createSocialMediaCropOptions());
    }
  }

  return (
    <>
      <div className="flex flex-col gap-md w-full">
        <div>
          <BFLabel>{t(CROP_TYPE_LABEL_KEY)}</BFLabel>
          <BFSelect
            options={getCropTypeOptions().map(({ i18nKey, value }) => ({
              label: t(i18nKey) as string,
              value,
            }))}
            data-cy="crop-type-select"
            value={options?.type}
            className="w-full"
            onOptionChange={(option) => selectCropType(option.value as CropType)}
          />
        </div>

        {options?.type === CropType.SocialMedia && (
          <>
            <div>
              <BFLabel>{t(SOCIAL_MEDIA_LABEL_KEY)}</BFLabel>
              <BFSelect
                options={getSocialMediaOptions().map(({ i18nKey, value }) => ({
                  label: t(i18nKey) as string,
                  value,
                }))}
                className="w-full"
                value={options.socialMedia}
                onOptionChange={(option) =>
                  onChange(setSocialMedia(options, option.value as SocialMediaPlatform))
                }
              >
                {getSocialMediaOptions().map(toOptionElement)}
              </BFSelect>
            </div>

            {options.socialMedia && (
              <div>
                <BFLabel>{t(IMAGE_SIZE_LABEL_KEY)}</BFLabel>
                <BFSelect
                  options={getSocialMediaImageSizeOptions(options.socialMedia).map(
                    ({ i18nKey, value }) => ({
                      label: t(i18nKey) as string,
                      value,
                    }),
                  )}
                  className="w-full"
                  value={options.assetType}
                  onOptionChange={(option) =>
                    onChange(setAssetType(options, option.value as PlatformAssetType))
                  }
                >
                  {}
                </BFSelect>
              </div>
            )}
          </>
        )}

        {options?.type === CropType.Custom && (
          <div>
            <BFLabel>{t(IMAGE_SIZE_LABEL_KEY)}</BFLabel>
            <BFSelect
              options={getCustomImageSizeOptions().map(({ i18nKey, value }) => ({
                label: t(i18nKey) as string,
                value,
              }))}
              className="w-full"
              onOptionChange={(option) =>
                onChange(setCustomDimension(options, option.value as CustomDimension))
              }
            />
          </div>
        )}
      </div>
    </>
  );
}
