import { DI_CONTAINER } from '@integration-frontends/core';
import {
  IMediaTypeSupportService,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  MediaType,
} from '@integration-frontends/integration/core/model';
import { useCallback, useEffect, useState } from 'react';
import { all } from 'ramda';

export const useMediaTypeSupport = () => {
  const mediaTypeSupportService: IMediaTypeSupportService = DI_CONTAINER.get(
    MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  );
  const [supportedMediaTypes, setSupportedMediaTypes] = useState([]);

  const isSupported = useCallback(
    (mediaType: MediaType) => {
      return supportedMediaTypes.includes(mediaType);
    },
    [supportedMediaTypes],
  );

  const allSupported = useCallback(
    (mediaTypes: MediaType[]) => {
      return all(isSupported, mediaTypes);
    },
    [isSupported],
  );

  useEffect(() => {
    async function getSupported() {
      const supportedFileTypes = await mediaTypeSupportService.getSupportedMediaTypes();
      setSupportedMediaTypes(supportedFileTypes);
    }
    getSupported();
  }, []);

  return { supportedMediaTypes, isSupported, allSupported };
};
