import { DI_CONTAINER, ILogger, LOGGER_TOKEN } from '@integration-frontends/core';
import { injectable } from 'inversify';
import 'isomorphic-fetch';
import jwtDecode from 'jwt-decode';
import {
  ApiFetchDataResponse,
  ApiListDataResponse,
  ApiSearchableThingsResponse,
  AssetDto,
  AssetOptions,
  AttachmentDto,
  AttachmentInputDto,
  AttachmentOptions,
  BrandfolderDto,
  BrandfolderOptions,
  CollectionDto,
  CollectionOptions,
  CustomFieldKeyDto,
  CustomFieldValueDto,
  GenericFileDto,
  LabelDto,
  Options,
  optionsToQueryString,
  OrganizationDto,
  OrganizationOptions,
  ResourceType,
  SearchFilterDto,
  SectionDto,
  SectionOptions,
  ShareManifestDto,
  TagDto,
  UserDto,
} from './model';
import * as promiseRetry from 'promise-retry';
import { isEmpty } from 'ramda';

export const BRANDFOLDER_API_TOKEN = 'BRANDFOLDER_API';
const RETRY_COUNT = 3;

const getRequestsInFlight = {};

@injectable()
export class BrandfolderApi {
  private logger: ILogger;
  constructor(private baseUrl: string, private refreshApiKey?: () => Promise<string>) {
    this.logger = DI_CONTAINER.get(LOGGER_TOKEN);
  }

  async listOrganizations(
    apiKey: string,
    options?: OrganizationOptions,
  ): Promise<ApiListDataResponse<OrganizationDto>> {
    return await this.get(apiKey, `/v4/organizations`, options);
  }

  async fetchAsset(
    apiKey: string,
    assetId: string,
    options?: AssetOptions,
  ): Promise<ApiFetchDataResponse<AssetDto>> {
    return await this.get(apiKey, `/v4/assets/${assetId}`, options);
  }

  private async requestUploadUrl(apiKey: string): Promise<{
    upload_url: string;
    object_url: string;
  }> {
    return await this.get(apiKey, '/v4/upload_requests');
  }

  async uploadFile(apiKey: string, file: File): Promise<{ objectUrl: string }> {
    const { object_url, upload_url } = await this.requestUploadUrl(apiKey);
    const buffer = await file.arrayBuffer();
    await fetch(upload_url, { method: 'PUT', body: buffer });
    return { objectUrl: object_url };
  }

  private generateCreateAssetBody(
    sectionId: string,
    name: string,
    attachments: AttachmentInputDto[],
  ) {
    return {
      data: {
        attributes: [
          {
            name,
            attachments,
          },
        ],
      },
      section_key: sectionId,
    };
  }

  async createBrandfolderAsset(
    apiKey: string,
    brandfolderId: string,
    sectionId: string,
    name: string,
    attachments: AttachmentInputDto[],
  ): Promise<ApiFetchDataResponse<GenericFileDto>> {
    return await this.post(
      apiKey,
      `/v4/brandfolders/${brandfolderId}/assets`,
      this.generateCreateAssetBody(sectionId, name, attachments),
    );
  }

  async createCollectionAsset(
    apiKey: string,
    collectionId: string,
    sectionId: string,
    name: string,
    attachments: AttachmentInputDto[],
  ) {
    return await this.post(
      apiKey,
      `/v4/collections/${collectionId}/assets`,
      this.generateCreateAssetBody(sectionId, name, attachments),
    );
  }

  async listBrandfolders(
    apiKey: string,
    options?: BrandfolderOptions,
  ): Promise<ApiListDataResponse<BrandfolderDto>> {
    return await this.get(apiKey, `/v4/brandfolders`, options);
  }

  async fetchBrandfolder(
    apiKey: string,
    brandfolderId: string,
    options?: BrandfolderOptions,
  ): Promise<ApiFetchDataResponse<BrandfolderDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}`, options);
  }

  async listBrandfolderAssets(
    apiKey: string,
    brandfolderId: string,
    options?: AssetOptions,
  ): Promise<ApiListDataResponse<AssetDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/assets`, options);
  }

  async listBrandfolderAttachments(
    apiKey: string,
    brandfolderId: string,
    options?: AttachmentOptions,
  ): Promise<ApiListDataResponse<AttachmentDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/attachments`, options);
  }

  async listBrandfolderSections(
    apiKey: string,
    brandfolderId: string,
    options?: SectionOptions,
  ): Promise<ApiListDataResponse<SectionDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/sections`, options);
  }

  async getBrandfolderSearchableThings(
    apiKey: string,
    brandfolderId: string,
  ): Promise<ApiSearchableThingsResponse> {
    const data = await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/searchable_things`);

    // map to valid payload if data is empty object
    return !data || isEmpty(data) ? { custom_fields: [], filetypes: [], tags: [] } : data;
  }

  async getBrandfolderTags(
    apiKey: string,
    brandfolderId: string,
  ): Promise<ApiListDataResponse<TagDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/tags`);
  }

  async addAssetTags(
    apiKey: string,
    assetIds: string[],
    tags: string[],
  ): Promise<void> {
    const data = {
      attributes: tags.map((tag) => { return {name: tag} }),
    }

    assetIds.forEach((id) => {
      this.post(apiKey, `/v4/assets/${id}/tags`, { data } );
    });
  }

  async getBrandfolderCustomFieldsKeys(
    apiKey: string,
    brandfolderId: string,
  ): Promise<ApiListDataResponse<CustomFieldKeyDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/custom_field_keys`);
  }

  async getBrandfolderCustomFieldValues(
    apiKey: string,
    brandfolderId: string,
    customFieldKeyId: string,
  ): Promise<ApiListDataResponse<CustomFieldValueDto>> {
    return await this.get(
      apiKey,
      `/v4/brandfolders/${brandfolderId}/custom_field_keys/${customFieldKeyId}/custom_field_values`,
    );
  }

  async getBrandfolderAssetShareUrl(
    apiKey: string,
    brandfolderId: string,
    assetId: string,
  ): Promise<ApiFetchDataResponse<ShareManifestDto>> {
    return await this.post(apiKey, `/v4/brandfolders/${brandfolderId}/share_manifests`, {
      data: {
        attributes: {
          asset_keys: [assetId],
        },
      },
    });
  }

  async getBrandfolderLabels(
    apiKey: string,
    brandfolderId: string,
  ): Promise<ApiListDataResponse<LabelDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/labels`);
  }

  async listCollections(
    apiKey: string,
    options?: CollectionOptions,
  ): Promise<ApiListDataResponse<CollectionDto>> {
    return await this.get(apiKey, `/v4/collections`, options);
  }

  async fetchCollection(
    apiKey: string,
    collectionId: string,
    options?: CollectionOptions,
  ): Promise<ApiFetchDataResponse<CollectionDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}`, options);
  }

  async listCollectionAssets(
    apiKey: string,
    collectionId: string,
    options?: AssetOptions,
  ): Promise<ApiListDataResponse<AssetDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/assets`, options);
  }

  async listCollectionSectionAssets(
    apiKey: string,
    collectionId: string,
    sectionId: string,
    options?: AssetOptions,
  ): Promise<ApiListDataResponse<AssetDto>> {
    return await this.get(
      apiKey,
      `/v4/collections/${collectionId}/sections/${sectionId}/assets`,
      options,
    );
  }

  async listCollectionAttachments(
    apiKey: string,
    collectionId: string,
    options?: AttachmentOptions,
  ): Promise<ApiListDataResponse<AttachmentDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/attachments`, options);
  }

  async listCollectionSections(
    apiKey: string,
    collectionId: string,
    options: SectionOptions,
  ): Promise<ApiListDataResponse<SectionDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/sections`, options);
  }

  async getCollectionSearchableThings(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiSearchableThingsResponse> {
    const data = await this.get(apiKey, `/v4/collections/${collectionId}/searchable_things`);

    // map to valid payload if data is empty object
    return !data || isEmpty(data) ? { custom_fields: [], filetypes: [], tags: [] } : data;
  }

  async getCollectionTags(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiListDataResponse<TagDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/tags`);
  }

  async getCollectionCustomFieldKeys(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiListDataResponse<CustomFieldKeyDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/custom_field_keys`);
  }

  async getCollectionCustomFieldValues(
    apiKey: string,
    collectionId: string,
    customFieldKeyId: string,
  ): Promise<ApiListDataResponse<CustomFieldValueDto>> {
    return await this.get(
      apiKey,
      `/v4/collections/${collectionId}/custom_field_keys/${customFieldKeyId}/custom_field_values`,
    );
  }

  async getCollectionAssetShareUrl(
    apiKey: string,
    collectionId: string,
    assetId: string,
  ): Promise<ApiFetchDataResponse<ShareManifestDto>> {
    return await this.post(apiKey, `/v4/collections/${collectionId}/share_manifests`, {
      data: {
        attributes: {
          asset_keys: [assetId],
        },
      },
    });
  }

  async getCollectionLabels(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiListDataResponse<LabelDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/labels`);
  }

  async listSectionAssets(
    apiKey: string,
    sectionId: string,
    options?: AssetOptions,
  ): Promise<ApiListDataResponse<AssetDto>> {
    return await this.get(apiKey, `/v4/sections/${sectionId}/assets`, options);
  }

  async fetchAttachment(
    apiKey: string,
    attachmentId: string,
    options?: AttachmentOptions,
  ): Promise<ApiFetchDataResponse<AttachmentDto>> {
    return await this.get(apiKey, `/v4/attachments/${attachmentId}`, options);
  }

  async listAssetAttachments(
    apiKey: string,
    assetId: string,
    options?: AttachmentOptions,
  ): Promise<ApiListDataResponse<AttachmentDto>> {
    return await this.get(apiKey, `/v4/assets/${assetId}/attachments`, options);
  }

  async listBrandfolderSearchFilters(
    apiKey: string,
    brandfolderId: string,
  ): Promise<ApiListDataResponse<SearchFilterDto>> {
    return await this.get(apiKey, `/v3/brandfolders/${brandfolderId}/search_filters`);
  }

  async listCollectionSearchFilters(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiListDataResponse<SearchFilterDto>> {
    return await this.get(apiKey, `/v3/collections/${collectionId}/search_filters`);
  }

  async whoAmI(apiKey: string): Promise<ApiFetchDataResponse<UserDto>> {
    // TEMP - the internal "get" method checks and refreshes the API key/token if needed, which we don't
    // want here because it will break some of the assumptions made by consumers of the lib when calling this
    // particular endpoint.
    // Eventually we'll want to extract the API key/token refresh logic and put it outside of this context
    // so that any persisted tokens can be updated with the new one.
    // ~PP
    const response = await fetch(`${this.baseUrl}/v4/users/whoami`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
    });

    return response.json();
  }

  async downloadAssetAttachments(
    assetId: string,
    resourceName: string,
    resourceId: string,
    resourceType: ResourceType.BRANDFOLDER | ResourceType.COLLECTION,
  ) {
    return await fetch(
      `https://www.brandfolder.com/${resourceName.toLowerCase()}/zip/asset/${assetId}?resource_key=${resourceId}&resource_type=${
        resourceType === ResourceType.BRANDFOLDER ? 'Brandfolder' : 'Collection'
      }`,
      {
        mode: 'no-cors',
        credentials: 'same-origin',
      },
    );
  }

  async saveUploadPreferences(
    apiKey: string,
    organizationId: string,
    brandfolderId: string,
    sectionId: string,
  ) {
    return await this.post(apiKey, '/v1/contentsync/panelui/upload_preferences', {
      data: {
        attributes: {
          organization_key: organizationId,
          brandfolder_key: brandfolderId,
          section_key: sectionId,
        }
      },
    });
  }

  async getUploadPreferences(
    apiKey: string,
  ) {
    return await this.get(apiKey, '/v1/contentsync/panelui/upload_preferences');
  }

  private async get(apiKey: string, path: string, options?: Options, init: RequestInit = {}) {
    const callString = `${apiKey}${path}${optionsToQueryString(options)}`;
    if (!getRequestsInFlight[callString]) {
      getRequestsInFlight[callString] = promiseRetry(async (retry, counter) => {
        try {
          const data = await this.fetchFromApi(apiKey, `${path}${optionsToQueryString(options)}`, {
            ...init,
            method: 'GET',
          });

          // At the moment we don't expect an empty object to be a valid response, so we retry.
          if (!data || isEmpty(data)) {
            throw 'Response is empty';
          } else {
            getRequestsInFlight[callString] = false;
            return data;
          }
        } catch (e) {
          if (counter <= RETRY_COUNT) {
            retry();
          } else {
            getRequestsInFlight[callString] = false;
            return null;
          }
        }
      });
    }
    return await getRequestsInFlight[callString];
  }

  private async post(apiKey: string, path: string, body: any, init: RequestInit = {}) {
    return await this.fetchFromApi(apiKey, `${path}`, {
      ...init,
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  private async fetchFromApi(apiKey: string, path: string, init: RequestInit = {}) {
    try {
      if (isExpired(apiKey)) {
        apiKey = await this.refreshApiKey();
      }

      const response = await fetch(`${this.baseUrl}${path}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
        ...init,
      });

      if (isError401(response)) {
        apiKey = await this.refreshApiKey();
        return await this.fetchFromApi(apiKey, path, init);
      } else {
        return response.json();
      }
    } catch (e) {
      this.logger.error(e);
      throw e;
    }
  }
}

function isError401(response): boolean {
  return response.status === 401;
}

interface decodedApiKeyProperties {
  exp?: number;
}

function getDecodedApiKey(key: string): decodedApiKeyProperties {
  return jwtDecode(key);
}

function isExpired(key: string): boolean {
  try {
    const { exp } = getDecodedApiKey(key);
    if (exp && exp - new Date().getTime() / 1000 < 60) {
      return true;
    }
    return false;
  } catch (e) {
    // if decoding fails then we're dealing with an API key, not an oauth token
    return false;
  }
}
