import { DEFAULT_DATE_FORMAT } from '@integration-frontends/core';
import { Attachment } from '@integration-frontends/integration/core/model';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KB_KEY } from '../../i18n';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';

export interface InformationProps {
  attachment: Attachment;
}

export function AttachmentInformation({ attachment }: InformationProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  return (
    <div className="w-full flex flex-col">
      <span>{attachment?.name}</span>
      <div className="flex gap-sm justify-between overflow-auto">
        <span className="whitespace-nowrap">{attachment?.extension.toLocaleUpperCase()}</span>
        <span className="whitespace-nowrap">
          {Math.round(attachment?.sizeInBytes / 1000)} {t(KB_KEY)}
        </span>
        <span className="whitespace-nowrap">
          {attachment?.dimensions.width} x {attachment?.dimensions.height} px
        </span>
        {/*<span>RGB</span>*/}
        <span>{format(attachment.updatedAt, DEFAULT_DATE_FORMAT)}</span>
      </div>
    </div>
  );
}
