import { StateSelector } from '@integration-frontends/common/utils/redux';
import {
  createAssetsListBySectionSelectors,
  IntegrationEntitiesSelectors,
} from '@integration-frontends/integration/core/application/common';
import { createAssetsListSelectors } from '@integration-frontends/integration/core/application/common/assets-list/assets-list-state';
import { createSelector } from '@reduxjs/toolkit';
import { SearchAssetsState, SearchAssetsGroupBy } from './reducer';

const selectContainerId = (state: SearchAssetsState) => state.containerId;
const selectLoading = (state: SearchAssetsState) => state.loading;
const selectSearchParams = (state: SearchAssetsState) => state.searchParams;
const selectSortOptions = (state: SearchAssetsState) => state.sortOptions;
const selectIsSearching = (state: SearchAssetsState) => state.searchParams !== null;
const selectInitSuccess = (state: SearchAssetsState) => state.initSuccess;
const selectAssetsListState = (state: SearchAssetsState) => state.listByContainer;
const selectAssetsListBySectionState = (state: SearchAssetsState) => state.listBySection;
const selectPageSize = (state: SearchAssetsState) => state.pageSize;
const selectShowAttachments = (state: SearchAssetsState) => state.showAttachments;
const selectGroupBy = (state: SearchAssetsState) => state.groupBy;

export function createSearchAssetsSelectors(
  stateSelector: StateSelector<SearchAssetsState>,
  entitiesSelectors: IntegrationEntitiesSelectors,
) {
  const listSelectors = createAssetsListSelectors(
    createSelector(stateSelector, selectAssetsListState),
  );

  const listBySectionSelectors = createAssetsListBySectionSelectors(
    createSelector(stateSelector, selectAssetsListBySectionState),
  );

  const baseSelectors = {
    containerId: createSelector(stateSelector, selectContainerId),
    hasResults: listSelectors.hasAssets,
    loading: createSelector(stateSelector, selectLoading),
    searchParams: createSelector(stateSelector, selectSearchParams),
    sortOptions: createSelector(stateSelector, selectSortOptions),
    isSearching: createSelector(stateSelector, selectIsSearching),
    initSuccess: createSelector(stateSelector, selectInitSuccess),
    pageSize: createSelector(stateSelector, selectPageSize),
    showAttachments: createSelector(stateSelector, selectShowAttachments),
    groupBy: createSelector(stateSelector, selectGroupBy),
    listSelectors,
    listBySectionSelectors,
  };

  return {
    ...baseSelectors,
    pagedSectionSearchResults: (sectionId: string) =>
      createSelector(
        baseSelectors.listBySectionSelectors.sectionPagination(sectionId),
        baseSelectors.sortOptions,
        entitiesSelectors.asset.selectEntities,
        (sectionPagination, sortOptions, assetEntities) => {
          return (
            sectionPagination?.currentPageAssetIds.map((id) => assetEntities[id]).filter(Boolean) ||
            []
          );
        },
      ),
    pagedSearchResults: () =>
      createSelector(
        baseSelectors.listSelectors.pagination,
        baseSelectors.sortOptions,
        entitiesSelectors.asset.selectEntities,
        (pagination, sortOptions, assetEntities) => {
          return (
            pagination?.currentPageAssetIds.map((id) => assetEntities[id]).filter(Boolean) ||
            []
          );
        },
      ),
    container: createSelector(
      baseSelectors.containerId,
      entitiesSelectors.container.selectAll,
      (containerId, containers) => containers.find((c) => c.id === containerId),
    ),
    assetCount: createSelector(
      baseSelectors.groupBy,
      listSelectors.assetCount,
      listBySectionSelectors.assetCount,
      (groupBy, listAssetCount, listBySectionAssetCount) => groupBy === SearchAssetsGroupBy.Container ? listAssetCount : listBySectionAssetCount,
    ),
    pagination: createSelector(
      baseSelectors.groupBy,
      listSelectors.pagination,
      listBySectionSelectors.pagination,
      (groupBy, listPagination, listBySectionPagination) => groupBy === SearchAssetsGroupBy.Container ? listPagination : listBySectionPagination,
    ),
    hasResults: createSelector(
      baseSelectors.groupBy,
      listSelectors.hasAssets,
      listBySectionSelectors.hasAssets,
      (groupBy, listSelectorsHasAssets, listBySectionSelectorsHasAssets) => groupBy === SearchAssetsGroupBy.Container ? listSelectorsHasAssets : listBySectionSelectorsHasAssets,
    ),
  };
}

export type SearchAssetsSelectors = ReturnType<typeof createSearchAssetsSelectors>;
