import {
  assetEntitySelectors,
  AssetsListBySectionState,
  attachmentEntitySelectors,
  containerSelectors,
  IntegrationRootState,
  searchAssetsSelectors,
  selectTotalAssetsCount,
} from '@integration-frontends/integration/core/application';
import React from 'react';
import { useSelector } from 'react-redux';

export interface ResultsDisplayProps {
  containerId: string;
}

export function ResultsDisplay(containerId) {
  const assets = useSelector(assetEntitySelectors.selectAll);
  const resultsTotal = useSelector(searchAssetsSelectors.assetCount);

  return <p className="font-medium">{`Showing ${resultsTotal} search results of ${assets.length} assets`}</p>;
}
