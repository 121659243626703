import { StateSelector } from '@integration-frontends/common/utils/redux';
import { createSelector } from '@reduxjs/toolkit';
import { pipe } from 'fp-ts/function';
import { flatten, length, map, prop, sum } from 'ramda';
import { AssetsListState } from './reducer';

export const selectShowAttachments = (state: AssetsListState) => state?.showAttachments;
export const selectPagination = (state: AssetsListState) => state?.pagination;

export const selectHasAssets = (state: AssetsListState) => state.pagination?.currentPageAssetIds.length > 0;

export const selectTotalAssetsCount = (state: AssetsListState) => state.pagination?.totalCount || 0;

export type AssetsListStateSelector = StateSelector<AssetsListState>;

export function createAssetsListSelectors(stateSelector: AssetsListStateSelector) {
  return {
    showAttachments: createSelector(stateSelector, selectShowAttachments),
    pagination: createSelector(stateSelector, selectPagination),
    hasAssets: createSelector(stateSelector, selectHasAssets),
    assetCount: createSelector(stateSelector, selectTotalAssetsCount),
  };
}
